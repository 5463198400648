import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import ValidatedFormInput from '../../components/ValidatedFormInput';
import { useForm } from 'react-hook-form';
import { StickyButton } from '../../components/Button';
import { postApi } from '../../utils/api';
import { Select } from '../../components/Select';

const Container = styled.section`
    display: flex;
    flex-direction: row;
    width: 1000px;
    > div + div {
        margin-left: 30px;
    }
`;

const ScreenAudioAdjustments = ({ room }: { room: any }) => {
    const defaults = {
        movie: {
            distanceModel: 'exponential',
            refDistance: 135,
            rolloffFactor: 22.5,
            maxDistance: 1000,
        },
        nonagon: {
            distanceModel: 'exponential',
            refDistance: 1,
            rolloffFactor: 1,
            maxDistance: 1000,
        },
    };
    const [screenshareSettings, setScreenshareSettings] = useState(room?.audioSettings || defaults);

    const submitScreenAudioAdjustments = () => {
        postApi(`/room/${room.id}/updateAudio`, screenshareSettings)
            .then(async (r) => {
                const json = await r.json();
                const parsedErrors = json?.error?.details?.map((err: any) => err?.message).join(' ');
                if (!r.ok) {
                    console.error(parsedErrors);
                } else {
                    window.location.reload(true);
                }
            })
            .catch((err) => console.log('error: ', err));
    };

    const handleUpdateMovieDistanceModel = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.persist();
        setScreenshareSettings((prevState: any) => ({
            ...prevState,
            movie: {
                ...prevState.movie,
                distanceModel: e.target.value,
            },
        }));
    };
    const handleUpdateNonagonDistanceModel = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.persist();
        setScreenshareSettings((prevState: any) => ({
            ...prevState,
            nonagon: {
                ...prevState.nonagon,
                distanceModel: e.target.value,
            },
        }));
    };
    const handleUpdateMovieRefDistance = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setScreenshareSettings((prevState: any) => ({
            ...prevState,
            movie: {
                ...prevState.movie,
                refDistance: parseFloat(e.target.value),
            },
        }));
    };
    const handleUpdateMovieRolloffFactor = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setScreenshareSettings((prevState: any) => ({
            ...prevState,
            movie: {
                ...prevState.movie,
                rolloffFactor: parseFloat(e.target.value),
            },
        }));
    };
    const handleUpdateMovieMaxDistance = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setScreenshareSettings((prevState: any) => ({
            ...prevState,
            movie: {
                ...prevState.movie,
                maxDistance: parseFloat(e.target.value),
            },
        }));
    };
    const handleUpdateNonagonRefDistance = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setScreenshareSettings((prevState: any) => ({
            ...prevState,
            nonagon: {
                ...prevState.nonagon,
                refDistance: parseFloat(e.target.value),
            },
        }));
    };
    const handleUpdateNonagonRolloffFactor = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setScreenshareSettings((prevState: any) => ({
            ...prevState,
            nonagon: {
                ...prevState.nonagon,
                rolloffFactor: parseFloat(e.target.value),
            },
        }));
    };
    const handleUpdateNonagonMaxDistance = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setScreenshareSettings((prevState: any) => ({
            ...prevState,
            nonagon: {
                ...prevState.nonagon,
                maxDistance: parseFloat(e.target.value),
            },
        }));
    };

    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });

    return (
        <>
            <h2>Advanced Audio Level Adjustment</h2>
            <br />
            <Container>
                <div>
                    <h3>Movie Screen</h3>
                    <br />
                    <Select onChange={handleUpdateMovieDistanceModel} value={screenshareSettings.movie.distanceModel}>
                        <option value="exponential">exponential</option>
                        <option value="linear">linear</option>
                    </Select>
                    <ValidatedFormInput
                        labelText="Ref Distance"
                        subtitleText="how far away from the source you have to be before the sound starts falling off (i.e. it’s a constant volume anywhere within that radius)."
                        errors={errors}
                        name="movieRefDistance"
                        onChange={handleUpdateMovieRefDistance}
                        type="number"
                        validation={register({ required: 'Ref Distance is required' })}
                        value={screenshareSettings.movie.refDistance}
                        style={{ width: '120px' }}
                    />
                    <ValidatedFormInput
                        labelText="Rolloff Factor"
                        subtitleText="how far away from the source you have to be before the sound starts falling off (i.e. it’s a constant volume anywhere within that radius)."
                        errors={errors}
                        name="movieRolloffFactor"
                        onChange={handleUpdateMovieRolloffFactor}
                        type="number"
                        validation={register({ required: 'Rolloff Factor is required' })}
                        value={screenshareSettings.movie.rolloffFactor}
                        style={{ width: '120px' }}
                    />
                    <ValidatedFormInput
                        labelText="Max Distance"
                        subtitleText="distance after which it stops falling off (i.e. further away than this the audio stays constant). (important if you’re using the linear model)"
                        errors={errors}
                        name="movieMaxDistance"
                        onChange={handleUpdateMovieMaxDistance}
                        type="number"
                        validation={register({ required: 'Max Distance is required' })}
                        value={screenshareSettings.movie.maxDistance}
                        style={{ width: '120px' }}
                    />
                </div>
                <div>
                    <h3>Nonagon</h3>
                    <br />
                    <Select
                        onChange={handleUpdateNonagonDistanceModel}
                        value={screenshareSettings.nonagon.distanceModel}
                    >
                        <option value="exponential">exponential</option>
                        <option value="linear">linear</option>
                    </Select>
                    <ValidatedFormInput
                        labelText="Ref Distance"
                        subtitleText="how far away from the source you have to be before the sound starts falling off (i.e. it’s a constant volume anywhere within that radius)."
                        errors={errors}
                        name="nonagonRefDistance"
                        onChange={handleUpdateNonagonRefDistance}
                        type="number"
                        validation={register({ required: 'Ref Distance is required' })}
                        value={screenshareSettings.nonagon.refDistance}
                        style={{ width: '120px' }}
                    />
                    <ValidatedFormInput
                        labelText="Rolloff Factor"
                        subtitleText="how far away from the source you have to be before the sound starts falling off (i.e. it’s a constant volume anywhere within that radius)."
                        errors={errors}
                        name="nonagonRolloffFactor"
                        onChange={handleUpdateNonagonRolloffFactor}
                        type="number"
                        validation={register({ required: 'Rolloff Factor is required' })}
                        value={screenshareSettings.nonagon.rolloffFactor}
                        style={{ width: '120px' }}
                    />
                    <ValidatedFormInput
                        labelText="Max Distance"
                        subtitleText="distance after which it stops falling off (i.e. further away than this the audio stays constant). (important if you’re using the linear model)"
                        errors={errors}
                        name="nonagonMaxDistance"
                        onChange={handleUpdateNonagonMaxDistance}
                        type="number"
                        validation={register({ required: 'Max Distance is required' })}
                        value={screenshareSettings.nonagon.maxDistance}
                        style={{ width: '120px' }}
                    />
                </div>
                <StickyButton onClick={handleSubmit(submitScreenAudioAdjustments)} disabled={false}>
                    Submit
                </StickyButton>
            </Container>
        </>
    );
};

export default ScreenAudioAdjustments;
