import * as React from 'react';
import styled from 'styled-components';
import LinkIcon from '../../images/room-link-icon.svg';
import HostLinkIcon from '../../images/host-link-icon.svg';
import InviteByLinkForm from '../../components/InviteByLinkForm';

interface Props {
    inviteCode: string;
    inviteHostCode: string;
    roomId: string;
}

const InviteToRoom: React.FC<Props> = ({ inviteCode, inviteHostCode, roomId }) => {
    const inviteUserLink = `${window.location.protocol}//${window.location.host}/invite/${inviteCode}?roomId=${roomId}`;
    const inviteHostLink = `${window.location.protocol}//${window.location.host}/hostInvite/${inviteHostCode}`;

    return (
        <Container>
            <InviteByLinkForm
                icon={LinkIcon}
                link={inviteUserLink}
                header="Invite Link"
                tooltip="Anyone with this link can go directly into this world. They will also view the venue page and join any other world."
            />
            <InviteByLinkForm
                link={inviteHostLink}
                icon={HostLinkIcon}
                header="Invite Host"
                tooltip="Hosts can control this world. Only share this link with people you want to have admin privileges for this world."
            />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    margin-right: 30px;
    max-width: 700px;
`;

export default InviteToRoom;
