import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ApiOrgDetails } from '../ApiTypes';
import Message from '../components/Message';
import { getApi } from '../utils/api';
import LoadingScreen from '../components/LoadingScreen';
import { VenuePageComponent } from './venue/VenuePage';
import { LogInModal } from './LogIn';

const LoggedOutAcceptInvite = () => {
    const { inviteCode } = useParams() as any;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [venueDetails, setVenueDetails] = useState(({} as unknown) as ApiOrgDetails);

    useEffect(() => {
        getApi(`/org/byInviteCode?inviteCode=${inviteCode}`)
            .then(async (response) => {
                if (response.ok) {
                    const venueDetails = await response.json();
                    console.log({ venueDetails });
                    setVenueDetails(venueDetails);
                } else {
                    setError(true);
                }
                setLoading(false);
            })
            .catch(() => setError(true));
    }, [inviteCode]);

    const { pathname } = useLocation() as any;
    const urlParams = new URLSearchParams(window.location.search);
    const roomId = urlParams.get('roomId');

    if (loading) return <LoadingScreen />;
    if (error) return <Message title="Sorry">Something went wrong with your invite.</Message>;

    return (
        <>
            {error ? (
                <Message title="Sorry">Something went wrong with your invite.</Message>
            ) : (
                <LogInModal redirect={roomId ? `/room/${roomId}/lobby` : pathname}>
                    <VenuePageComponent loading={loading} venueDetails={venueDetails} />
                </LogInModal>
            )}
        </>
    );
};
export default LoggedOutAcceptInvite;
