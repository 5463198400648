import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { postApi } from '../utils/api';
import RoomForm from './room/RoomForm';
import CenteredSingleColumnLayout from '../components/CenteredSingleColumnLayout';
import { ApiRoomEditableMetadata } from '../ApiTypes';

const CreateRoom: React.FC = () => {
    const history = useHistory();
    const { orgId } = useParams() as any;
    const [error, setError] = React.useState('');

    const handleCreateRoom = (roomData: ApiRoomEditableMetadata) => {
        postApi(`/org/${orgId}/createRoom`, roomData)
            .then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    setError(json.message || 'Something went wrong');
                }

                if (json.id) {
                    history.push(`/room/${json.id}`);
                }
            })
            .catch((err) => console.log('error: ', err));
    };
    // if (!currentUserCanCreate) return <h2>Unauthorized :(</h2>;

    return (
        <>
            <CenteredSingleColumnLayout title="Create New World" subtitle="">
                <RoomForm
                    title="Create a Room"
                    onSubmit={(roomData: ApiRoomEditableMetadata) => handleCreateRoom(roomData)}
                />
                {error && <h2>{error}</h2>}
            </CenteredSingleColumnLayout>
        </>
    );
};

export default CreateRoom;
