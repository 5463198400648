import * as React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
    display: inline-block;
    position: relative;
    margin-right: 15px;
`;

// User Table Icons
export const HostIcon = () => (
    <IconWrapper className="svgIcon stroke">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g>
                    <path
                        strokeWidth="2"
                        d="M0 8.667L14.667 8.667M14.667 1.333L11.333 4l-4-4-4 4L0 1.333V10c0 .736.597 1.333 1.333 1.333h12c.737 0 1.334-.597 1.334-1.333V1.333z"
                        transform="translate(.667 2.667)"
                    />
                </g>
            </g>
        </svg>
    </IconWrapper>
);

export const BlockedIcon = () => (
    <IconWrapper className="svgIcon fill">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                d="M11.314 0H4.686L0 4.686v6.628L4.686 16h6.628L16 11.314V4.686L11.314 0zM8 12c-.27.016-.534-.084-.725-.275-.191-.191-.291-.455-.275-.725-.016-.27.084-.534.275-.725.191-.191.455-.291.725-.275.27-.016.534.084.725.275.191.191.291.455.275.725.016.27-.084.534-.275.725-.191.191-.455.291-.725.275zm0-3c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1s1 .448 1 1v3c0 .552-.448 1-1 1z"
                opacity=".9"
            />
        </svg>
    </IconWrapper>
);

export const CheckIcon = () => (
    <IconWrapper className="svgIcon fill">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512">
            <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
        </svg>
    </IconWrapper>
);

export const DeleteIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon stroke">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <path
                    strokeWidth="2"
                    d="M13.818 5.818v8.727c0 .804-.651 1.455-1.454 1.455H3.636c-.803 0-1.454-.651-1.454-1.455V5.818M0 2.909L16 2.909M5.091 2.909L5.091 0 10.909 0 10.909 2.909M10.182 7.989L5.818 12.353M10.182 12.353L5.818 7.989"
                />
            </g>
        </svg>
    </IconWrapper>
);

// Social Card Icons
export const TwitterIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon fill">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21">
            <path d="M24.734 2.6c-.9.4-1.8.7-2.8.8 1-.6 1.8-1.6 2.2-2.7-1 .6-2 1-3.1 1.2-.9-1-2.2-1.6-3.6-1.6-2.7 0-4.9 2.2-4.9 4.9 0 .4 0 .8.1 1.1-4.2-.2-7.8-2.2-10.2-5.2-.5.8-.7 1.6-.7 2.5 0 1.7.9 3.2 2.2 4.1-.8 0-1.6-.2-2.2-.6v.1c0 2.4 1.7 4.4 3.9 4.8-.4.1-.8.2-1.3.2-.3 0-.6 0-.9-.1.6 2 2.4 3.4 4.6 3.4-1.7 1.3-3.8 2.1-6.1 2.1-.4 0-.8 0-1.2-.1 2.2 1.4 4.8 2.2 7.5 2.2 9.1 0 14-7.5 14-14v-.6c1-.7 1.8-1.6 2.5-2.5z" />
        </svg>
    </IconWrapper>
);

export const PhoneIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon fill">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 23 23">
            <path d="M15.348 13.407l-2.355 2.321c-1.228-.74-2.368-1.617-3.4-2.613-.994-1.033-1.87-2.173-2.612-3.4L9.3 7.36c.285-.291.367-.725.208-1.1L7.09.614C6.894.156 6.39-.09 5.909.036L1.465 1.209c-.458.117-.772.537-.756 1.009.282 5.16 2.428 10.043 6.039 13.741 3.698 3.612 8.583 5.759 13.745 6.041.472.015.89-.3 1.01-.756l1.172-4.44c.128-.484-.117-.988-.577-1.186L16.457 13.2c-.378-.163-.816-.082-1.11.207z" />
        </svg>
    </IconWrapper>
);

export const LinkIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon stroke">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" transform="translate(.377)">
                <g>
                    <path
                        strokeWidth="2"
                        d="M13.576 0h2.909c2.715 0 4.848 2.133 4.848 4.848h0c0 2.716-2.133 4.849-4.848 4.849h-2.91M7.758 9.697h-2.91C2.133 9.697 0 7.564 0 4.848h0C0 2.133 2.133 0 4.848 0h2.91M6.788 4.848L14.545 4.848"
                        transform="rotate(-45 19.38 7.057)"
                    />
                </g>
            </g>
        </svg>
    </IconWrapper>
);

export const LinkedInIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon fill">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <path d="M23.556 0h-22c-.6 0-1 .4-1 1v22c0 .6.4 1 1 1h22c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-15.9 20.5h-3.5V9h3.6v11.5h-.1zm-1.8-13.1c-1.1 0-2.1-.9-2.1-2.1 0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1 0 1.2-.9 2.1-2.1 2.1zm15.2 13.1h-3.6v-5.6c0-1.3 0-3-1.8-3-1.9 0-2.1 1.4-2.1 2.9v5.7h-3.6V9h3.4v1.6c.5-.9 1.6-1.8 3.4-1.8 3.6 0 4.3 2.4 4.3 5.5v6.2z" />
        </svg>
    </IconWrapper>
);

export const InstagramIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon fill">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <g transform="translate(.913)">
                <path d="M12 2.162c3.204 0 3.584.012 4.849.07 1.366.062 2.633.336 3.608 1.311.975.975 1.249 2.242 1.311 3.608.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.062 1.366-.336 2.633-1.311 3.608-.975.975-2.242 1.249-3.608 1.311-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07c-1.366-.062-2.633-.336-3.608-1.311-.975-.975-1.249-2.242-1.311-3.608-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849c.062-1.366.336-2.633 1.311-3.608.975-.975 2.242-1.249 3.608-1.311 1.265-.058 1.645-.07 4.849-.07M12 0C8.741 0 8.332.014 7.052.072c-1.95.089-3.663.567-5.038 1.942C.639 3.389.161 5.102.072 7.052.014 8.332 0 8.741 0 12c0 3.259.014 3.668.072 4.948.089 1.95.567 3.663 1.942 5.038 1.375 1.375 3.088 1.853 5.038 1.942C8.332 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 1.95-.089 3.663-.567 5.038-1.942 1.375-1.375 1.853-3.088 1.942-5.038.058-1.28.072-1.689.072-4.948 0-3.259-.014-3.668-.072-4.948-.089-1.95-.567-3.663-1.942-5.038C20.611.639 18.898.161 16.948.072 15.668.014 15.259 0 12 0z" />
                <path d="M12 5.838c-3.403 0-6.162 2.759-6.162 6.162S8.597 18.162 12 18.162s6.162-2.759 6.162-6.162S15.403 5.838 12 5.838zM12 16c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
                <circle cx="18.406" cy="5.594" r="1.44" />
            </g>
        </svg>
    </IconWrapper>
);

export const EmailIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon stroke">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 21">
            <g
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                transform="translate(.87, 1)"
            >
                <path d="M0.614 0.558L11 10 21.385 0.559" />
                <rect width="22" height="18" rx="2" />
            </g>
        </svg>
    </IconWrapper>
);

// Other
export const EditIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon stroke">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                filter=""
                transform="translate(-9 -9)"
            >
                <path strokeWidth="2" d="M4 15.2L0 16 0.8 12 12.8 0 16 3.2z" transform="translate(10 10)" />
            </g>
        </svg>
    </IconWrapper>
);

export const StreamIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon stroke">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23">
            <defs>
                <filter id="svs4e20y4a">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd" filter="url(#svs4e20y4a)" transform="translate(0 -5)">
                <g>
                    <path
                        stroke="#F6F6F9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 3H18V13H4zM4 3L0 0 0 16 4 13M18 3L22 0 22 16 18 13"
                        transform="translate(0 5.5)"
                    />
                    <path fill="#F6F6F9" fillRule="nonzero" d="M10 10L13 8 10 6z" transform="translate(0 5.5)" />
                    <path
                        stroke="#F6F6F9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 17L17 17M0 21L22 21"
                        transform="translate(0 5.5)"
                    />
                </g>
            </g>
        </svg>
    </IconWrapper>
);

export const UploadIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon stroke">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd" transform="translate(-82)">
                <g fill="#000" fillRule="nonzero">
                    <path
                        d="M6 14H10V16H6zM6 0H10V2H6zM14 6H16V10H14zM0 6H2V10H0zM7 12L9 12 9 8 12 8 8 4 4 8 7 8zM2 2h2V0H1C.4 0 0 .4 0 1v3h2V2zM15 0h-3v2h2v2h2V1c0-.6-.4-1-1-1zM14 14h-2v2h3c.6 0 1-.4 1-1v-3h-2v2zM2 12H0v3c0 .6.4 1 1 1h3v-2H2v-2z"
                        transform="translate(82)"
                    />
                </g>
            </g>
        </svg>
    </IconWrapper>
);

export const GoToIcon: React.FC<any> = () => (
    <IconWrapper className="svgIcon stroke">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g>
                    <g>
                        <g>
                            <g
                                strokeWidth="2"
                                transform="translate(-1076 -987) translate(1019 965) translate(43 15) translate(14 7)"
                            >
                                <path d="M13.867 6.133L10.667 9.562 7.467 6.133" transform="rotate(-90 10.667 7.848)" />
                                <path d="M6.667 13.562L6.667 2.133" transform="rotate(-90 6.667 7.848)" />
                                <path
                                    d="M13.867 2.705L17.067 2.705 18.133 12.99 3.2 12.99 4.267 2.705 7.467 2.705"
                                    transform="rotate(-90 10.667 7.848)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </IconWrapper>
);
