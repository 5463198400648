import * as React from 'react';
import ConfidentialityModal from '../components/ConfidentialityModal';
import nonaMedium from '../images/nona-medium.svg';
import nonaSmall from '../images/nona-small.svg';
import nonaLarge_1 from '../images/nona-large.svg';
import circle from '../images/circle.svg';
import headphoneIcon from '../images/headphones-icon.svg';
import browserIcon from '../images/browser-icon.svg';
import icArrows from '../images/ic-arrows.svg';
import ic360 from '../images/ic_360.svg';
import icFullscreen from '../images/ic_fullscreen.svg';

const Base = () => {
    const [modalActive, setModalActive] = React.useState(true);

    return (
        <>
            {modalActive ? (
                <ConfidentialityModal setModalActive={setModalActive} />
            ) : (
                <>
                    <div className="sticky-patreon">
                        <a
                            href="https://www.patreon.com/urnowhere"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="button w-button"
                        >
                            Become a patron
                        </a>
                    </div>
                    <div className="hero-section">
                        <a href="#quoteSection" id="w-node-193128ee0a06-38cd00bf" className="club-title w-inline-block">
                            <h1 className="h1 center big">BASE</h1>
                        </a>
                        <div
                            data-poster-url="videos/DVDE_loop_1-poster-00001.jpg"
                            data-video-urls="videos/DVDE_loop_1-transcode.mp4,videos/DVDE_loop_1-transcode.webm"
                            data-autoplay="true"
                            data-loop="true"
                            data-wf-ignore="true"
                            id="w-node-193128ee0a0b-38cd00bf"
                            className="background-video w-background-video w-background-video-atom"
                        >
                            {/*<video autoPlay="" loop=""*/}
                            {/*       style="background-image:url(&quot;videos/DVDE_loop_1-poster-00001.jpg&quot;)" muted=""*/}
                            {/*       playsInline="" data-wf-ignore="true" data-object-fit="cover">*/}
                            {/*    <source src="videos/DVDE_loop_1-transcode.mp4" data-wf-ignore="true">*/}
                            {/*        <source src="videos/DVDE_loop_1-transcode.webm" data-wf-ignore="true">*/}
                            {/*</video>*/}
                        </div>
                    </div>
                    <div id="quoteSection" data-w-id="44030b76-be9e-b300-0631-c6ca9e4fbd3c" className="dark-section">
                        <div className="w-container">
                            <div className="more-space-div">
                                <div className="animate-block">
                                    <div className="h2 center">
                                        <strong>LIFE IS THE ART OF THE ENCOUNTER.</strong>
                                    </div>
                                    <div className="paragraph center">- Vinicius de Moraes -</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-w-id="3ea9063c-cc2d-a51a-b3bf-6a36be2a22e9" className="big-text-section">
                        <div id="w-node-16270ebe971e-38cd00bf" className="div-block-13">
                            <div id="w-node-51e8d8ea57e8-38cd00bf" className="nona-animate medium">
                                <img src={nonaMedium} alt="" className="rotate-img" />
                            </div>
                            <div id="w-node-cdddc7dd36f4-38cd00bf" className="nona-animate small">
                                <img src={nonaSmall} alt="" className="rotate-img" />
                            </div>
                            <div id="w-node-bc236c704d1f-38cd00bf" className="nona-animate large">
                                <img src={nonaLarge_1} alt="" className="rotate-img" />
                            </div>
                            <div id="w-node-1f78e9b7037e-38cd00bf" className="nona-animate small">
                                <img src={nonaSmall} alt="" className="rotate-img" />
                            </div>
                            <div id="w-node-df18ee62414c-38cd00bf" className="nona-animate large">
                                <img src={nonaLarge_1} alt="" className="rotate-img" />
                            </div>
                            <div id="w-node-4f9a548a724d-38cd00bf" className="nona-animate small">
                                <img src={nonaSmall} alt="" className="rotate-img" />
                            </div>
                            <div id="w-node-1a310c368ea1-38cd00bf" className="nona-animate medium">
                                <img src={nonaMedium} alt="" className="rotate-img" />
                            </div>
                            <div
                                id="w-node-fc1695b3b7eb-38cd00bf"
                                data-w-id="7b5cc30f-e941-bdd2-5d34-fc1695b3b7eb"
                                className="nona-animate medium"
                            >
                                <img src={nonaMedium} alt="" className="rotate-img" />
                            </div>
                        </div>
                        <div id="w-node-e6b95b00fde9-38cd00bf" className="container-2 w-container">
                            <div className="animate-block">
                                <div className="h2 dark">
                                    <strong>The Base is the premier social club in NOWHERE</strong>
                                </div>
                            </div>
                            <div className="animate-block">
                                <div className="stroked-h2">
                                    <strong>
                                        designed by
                                        <br />‍
                                    </strong>
                                    <a
                                        href="http://www.thewindmillfactory.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="stroked-h2 link"
                                    >
                                        <strong>The Windmill Factory</strong>
                                    </a>
                                    <strong>
                                        <br />
                                        in response to COVID19
                                    </strong>
                                </div>
                            </div>
                            <div className="animate-block">
                                <div className="h2 dark">
                                    <strong>
                                        AFTER YEARS OF CRAFTING EXPERIENCES IRL, current SOCIAL CONNECTIOn options FELT
                                        SOOO... ...FLATLANDS
                                    </strong>
                                </div>
                            </div>
                            <div className="animate-block">
                                <div className="stroked-h2">
                                    Artists and engineers are working tirelessly to deepen and excite virtual gathering
                                </div>
                            </div>
                            <div className="animate-block">
                                <div className="h2 dark">
                                    <strong>
                                        MAKE NEW FRIENDS. DROP DEEP. CRAFT A HUMANE METAVERSE.
                                        <br />
                                    </strong>
                                </div>
                            </div>
                            <div className="animate-block">
                                <div className="stroked-h2">
                                    <strong>
                                        OPEN THURS-SAT, 8:07-10:59PM EDT.
                                        <br />
                                    </strong>
                                </div>
                            </div>
                            <div className="animate-block hide">
                                <a
                                    href="contact/host-request.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="h2 dark link w-inline-block"
                                >
                                    <strong>request to be a Host</strong>
                                </a>
                            </div>
                            <div className="animate-block hide">
                                <div className="stroked-h2">
                                    <a href="contact/invite-request.html" className="stroked-h2 link w-inline-block">
                                        <strong>invite 3 friends</strong>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section id="introSection" className="white-section hide">
                        <div className="container w-container">
                            <div className="animate-block">
                                <div className="h3 center">
                                    The Base is the premier environment of NOWHERE, a platform designed by The Windmill
                                    Factory in response to COVID19.
                                </div>
                                <p className="paragraph center">
                                    After years of crafting experiences IRL we saw the dire global need for human
                                    connection online. Artists and engineers are working tirelessly to create this
                                    platform where spatial video chat technology is in service of interaction, social
                                    agency, and play.{' '}
                                </p>
                            </div>
                        </div>
                    </section>
                    <div data-w-id="5b21e0f4-9e2d-c088-1da7-a43667bbd86b" className="dark-section invert">
                        <div className="container w-container">
                            <img
                                src={circle}
                                alt="the circle"
                                data-w-id="5fefb58d-26e6-eae3-5ce5-84c75444fb8c"
                                // style="opacity:0"
                                className="rotate-img"
                            />
                            <div className="more-space-div">
                                <div className="animate-block">
                                    <div className="h3 center">
                                        <span className="text-span-2">
                                            <strong>
                                                LEARN ABOUT HAPPENINGS AND PARTICIPATE IN <br />
                                                THE CREATION OF THE BASE.
                                            </strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="animate-block">
                                <a
                                    href="https://t.me/joinchat/Tbbs4Bm34mqKL-4oGaKchA"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="button w-button"
                                >
                                    <strong>Join the Community</strong>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div data-w-id="8d0655fd-78d8-4b95-0a7f-eb4ad49d4c2f" className="dark-section moreo-space">
                        <div className="container w-container">
                            <div
                                data-w-id="3047e8b2-957b-b05f-5132-4bb03f810c73"
                                // style="opacity:0"
                                className="icon-row-div"
                            >
                                <div className="icon-text-div">
                                    <img src={headphoneIcon} alt="Wear Headphones Icon" className="rotate-img" />
                                    <div className="more-space-div top-only">
                                        <div className="h3 center">
                                            <span className="text-span-2">
                                                <strong>Wear Headphones</strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="icon-text-div">
                                    <img src={browserIcon} alt="Browser Icon" className="rotate-img" />
                                    <div className="more-space-div top-only">
                                        <div className="h3 center">
                                            <strong>Use Chrome</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                data-w-id="2c1f3961-8b0d-05b8-1476-fc3098018218"
                                // style="opacity:0"
                                className="controls-div"
                            >
                                <div className="controls-text-div right">
                                    <div className="h3 center">
                                        <span className="text-span-2">
                                            <strong>Controls</strong>
                                        </span>
                                    </div>
                                </div>
                                <div className="controls-text-div">
                                    <ul className="w-list-unstyled">
                                        <li className="flex-list">
                                            <img src={icArrows} alt="arrow keys to move" />
                                            <div className="paragraph">Arrow keys = Move</div>
                                        </li>
                                        <li className="flex-list">
                                            <img src={ic360} alt="click and drag to change view" />
                                            <div className="paragraph">Click + drag = Turn</div>
                                        </li>
                                        <li className="flex-list">
                                            <img src={icFullscreen} alt="F for fullscreen" />
                                            <div className="paragraph">“F” = Go Full-screen</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dark-section linkout">
                        <div className="club-title">
                            <div className="circle-button-text">Opens Thursday at 8:07pm edt</div>
                        </div>
                        <div className="club-title hide">
                            <a
                                data-w-id="36ff6083-5b17-0e72-2a97-7ed82276845e"
                                href="18du87881loffs7777a990.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="launch-button w-inline-block"
                            >
                                <div data-w-id="ba1e9417-01b5-0956-fdc0-fbc73635a721" className="circle-button"></div>
                                <div className="circle-button-text">take me to the base</div>
                            </a>
                        </div>
                        <div id="w-node-359477c8353f-38cd00bf" className="animate-block moost">
                            <img src="./images/nwr_Moose.png" alt="" className="moose-img" />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Base;
