import * as React from 'react';

import styled from 'styled-components';

import loadingGif from '../images/loading-40px_1loading-40px.gif';

const LoadingScreenDiv = styled.div`
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #010027;
`;

export function LoadingImage() {
    return <img src={loadingGif} alt="" className="loading-img" />;
}

export default function LoadingComponent() {
    return (
        <LoadingScreenDiv>
            <LoadingImage />
        </LoadingScreenDiv>
    );
}
