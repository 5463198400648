import * as React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { ApiRoom } from '../ApiTypes';

const TwoColumnPage: React.FunctionComponent<TwoColumnPageProps> = (props) => {
    const { title, leftColumn, rightColumn } = props;
    return (
        <>
            <Helmet>
                <title>{`NOWHERE | ${title}`}</title>
            </Helmet>
            <Content>
                <Columns>
                    <LeftColumn>{leftColumn}</LeftColumn>
                    <RightColumn>{rightColumn}</RightColumn>
                </Columns>
            </Content>
        </>
    );
};

export const Content = styled.div`
    background-color: var(--navy-blue);
`;

export const SubHeading = styled.div`
    opacity: 0.5;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #c5b9c5;
`;

export const Text = styled.div`
    font-size: 16px;
    letter-spacing: 0.3px;
    color: white;
`;

type TwoColumnPageProps = {
    title: string;
    leftColumn: React.ReactNode;
    rightColumn: React.ReactNode;
};

export const Columns = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0;
    margin: 0 0 0 30px;
    @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

export const LeftColumn = styled.div`
    width: 420px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (min-width: 1024px) {
        position: sticky;
        top: 75px;
    }
    @media (min-width: 1200px) {
        margin-right: 60px;
    }
`;

export const RightColumn = styled.div`
    width: 100%;
    padding: 0;
    margin: 0 auto;
`;

const RoomCountContainer = styled.div`
    text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.35);
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    color: white;
`;

export const RoomCount = ({ rooms = [] }: { rooms: ApiRoom[] }) => (
    <RoomCountContainer>{`My Worlds (${rooms?.length || 0})`}</RoomCountContainer>
);

export default TwoColumnPage;
