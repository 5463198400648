import * as React from 'react';
import Message from './Message';
import { Modal } from './Modal';
import { ApiOrganization, ApiUser } from '../ApiTypes';
import { Select } from './Select';
import FormErrorMessage from './FormErrorMessage';
import { postApi } from '../utils/api';

type InviteModalProps = {
    userToInvite: ApiUser;
    ownedOrgs: ApiOrganization[];
    isOpen: boolean;
    handleClose: () => void;
};

const InviteConnectionModal = ({ userToInvite, ownedOrgs, isOpen, handleClose }: InviteModalProps) => {
    const [selectedVenue, setSelectedVenue] = React.useState({} as ApiOrganization);
    const [sending, setSending] = React.useState(false);
    const [apiError, setApiError] = React.useState('');
    const [success, setSuccess] = React.useState('');

    const name = `${userToInvite.firstName} ${userToInvite.lastName}`;

    const sendInvite = () => {
        setApiError('');
        setSuccess('');
        setSending(true);

        if (!selectedVenue) {
            setApiError('You must select a venue to invite this connection to');
        } else {
            postApi(`/org/${selectedVenue.id}/invite`, { userEmail: userToInvite.email }).then((res) => {
                setSending(false);
                res.ok
                    ? setSuccess(`${name} has been invited to ${selectedVenue.title}!`)
                    : res.json().then((res) => setApiError(res.message));
            });
        }
    };

    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const org = JSON.parse(e.target.value);
        setSelectedVenue(org);
    };

    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <Message
                title={`Invite ${name} to the party!`}
                onConfirm={sendInvite}
                confirmButtonText="Send Invite"
                confirmButtonDisabled={sending}
                onCancel={handleClose}
            >
                <Select onChange={handleSelect} value={JSON.stringify(selectedVenue)}>
                    <option value="">Select Venue</option>
                    {ownedOrgs.map((org) => (
                        <option value={JSON.stringify(org)} key={org.id}>
                            {org.title}
                        </option>
                    ))}
                </Select>
                {apiError && <FormErrorMessage error={apiError} />}
                {success && <h4>{success}</h4>}
            </Message>
        </Modal>
    );
};

export default InviteConnectionModal;
