import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Message from '../components/Message';
import { Modal } from '../components/Modal';
import { getApi } from '../utils/api';

const RoomKicked: React.FC = () => {
    const { roomId } = useParams() as { roomId: string };
    const params = new URLSearchParams(window.location.search);
    const reason = params.get('reason');
    const [orgId, setOrgId] = React.useState('');

    React.useEffect(() => {
        getApi(`/room/${roomId}/orgId`).then(async (r) => {
            const json = await r.json();

            if (r.ok) {
                setOrgId(json.orgId);
            }
        });
    }, [roomId]);

    const history = useHistory();
    const returnToVenue = () => history.push(`/org/${orgId}`);

    return (
        <Modal isOpen={true} handleClose={returnToVenue}>
            <Message title="You've been kicked out" onConfirm={returnToVenue} confirmButtonText="Return to Venue">
                {reason && (
                    <>
                        Admin Said: &ldquo;{reason}&ldquo;
                        <br />
                    </>
                )}
                {params.get('banned') ? (
                    <>You may need to ask a Host if you can return to the room.</>
                ) : (
                    <>Please take some time off and then you&#39;re welcome to come back</>
                )}
            </Message>
        </Modal>
    );
};

export default RoomKicked;
