import * as React from 'react';
import ValidatedFormInput from '../../components/ValidatedFormInput';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { AuthButton } from '../../components/Button';
import styled from 'styled-components';
import { ApiOrganization, ApiOrganizationEditableMetadata } from '../../ApiTypes';
import UploadImage from '../../components/UploadImage';
import { GreyishBodyText, WhiteLinkText } from '../../components/Typography';
import { useHistory } from 'react-router-dom';

interface OrgFormProps {
    onSubmit: (orgData: ApiOrganizationEditableMetadata) => void;
    initialOrgData?: ApiOrganization;
    edit?: boolean;
}
const VenueForm: React.FC<OrgFormProps> = ({ onSubmit, initialOrgData, edit }) => {
    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });
    const [uploading, setUploading] = useState(false);
    const [org, setOrg] = useState({
        introTextBody: initialOrgData?.introTextBody,
        name: initialOrgData?.name,
        title: initialOrgData?.title,
        imageUrl: initialOrgData?.imageUrl,
    } as ApiOrganizationEditableMetadata);
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);

    const submitFormData = () => {
        setSubmitting(true);
        onSubmit(org);
    };

    return (
        <>
            <form className="d--f jc--sa" onSubmit={handleSubmit(submitFormData)}>
                <FormColumns>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <UploadImage
                            onUpload={(u: string) =>
                                setOrg({
                                    ...org,
                                    imageUrl: u,
                                })
                            }
                            existingImage={org.imageUrl}
                            uploading={uploading}
                            setUploading={setUploading}
                        />
                        <SuggestedDimensionText>
                            Suggested dimensions 1200px x 800px
                            <br />
                            with a centered safe area of 700 x 330
                        </SuggestedDimensionText>
                    </div>
                    <div style={{ width: '480px' }}>
                        <label htmlFor="Title">Venue Title* (24 characters)</label>
                        <ValidatedFormInput
                            errors={errors}
                            maxLength={24}
                            name="title"
                            onChange={(e: any) => setOrg({ ...org, title: e.target.value })}
                            type="textarea"
                            style={{ height: '85px' }}
                            validation={register({ required: 'Venue title is required' })}
                            value={org.title}
                        />
                        <label htmlFor="Organization Name">Hosted By (or Subtitle)*</label>
                        <ValidatedFormInput
                            errors={errors}
                            maxLength={85}
                            name="name"
                            onChange={(e: any) => setOrg({ ...org, name: e.target.value })}
                            type="text"
                            validation={register({ required: 'Venue name / subtitle is required' })}
                            value={org.name}
                        />
                        <label htmlFor="Full Description">Description</label>
                        <ValidatedFormInput
                            errors={errors}
                            name="Full Description"
                            onChange={(e: any) => setOrg({ ...org, introTextBody: e.target.value })}
                            type="textarea"
                            style={{ height: '275px' }}
                            validation={register({})}
                            value={org.introTextBody}
                        />
                        <ButtonContainer>
                            <WhiteLinkText onClick={() => history.goBack()}>Cancel</WhiteLinkText>
                            <AuthButton id="signupButton" disabled={uploading || submitting}>
                                {uploading ? 'Uploading image....' : edit ? 'Update Venue' : 'Create Venue'}
                            </AuthButton>
                        </ButtonContainer>
                    </div>
                </FormColumns>
            </form>
        </>
    );
};

const FormColumns = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 60px;
    > * {
        margin-bottom: 80px;
    }
    @media (min-width: 1024px) {
        flex-direction: row;
        > * {
            &:not(:last-child) {
                margin-right: 60px;
            }
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-top: 15px;
    > * {
        margin-right: 60px;
    }
`;

const SuggestedDimensionText = styled(GreyishBodyText)`
    display: flex;
    align-items: flex-end;
    text-align: right;
    margin-top: 60px;
    font-size: 16px;
`;

export default VenueForm;
