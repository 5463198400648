import * as React from 'react';

import { Modal } from '../components/Modal';
import { MediumButton } from '../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { agreeToTerms } from '../store/system/actions';
import { getAgreedToTerms } from '../store/selectors';
import styled from 'styled-components';

const AgreeToTermsModal: React.FC = () => {
    const dispatch = useDispatch();
    const agreedToTerms = useSelector(getAgreedToTerms);
    const agreementCookie = Cookies.get('T&C');

    if (agreementCookie || agreedToTerms) {
        return <></>;
    }

    function acceptTerms() {
        Cookies.set('T&C', 'agreed', { expires: 7 });
        dispatch(agreeToTerms());
    }

    return (
        <Modal isOpen={true}>
            <InnerContainer>
                <h1> WELCOME TO OUR CLOSED ALPHA</h1>
                <div>
                    By clicking I ACCEPT below you agree to keep this experience and its contents confidential and will
                    not share this with your friends until a TBD date in which this experience is made public. What
                    happens in NWR stays in NWR.
                    <br />
                    <br />I have read, understand, and agree to be bound by the terms and conditions of this
                    Confidentiality Agreement.
                </div>
                <MediumButton onClick={acceptTerms}>I AGREE</MediumButton>
            </InnerContainer>
        </Modal>
    );
};

const InnerContainer = styled.div`
    > * {
        margin: 20px 0;
    }
`;
export default AgreeToTermsModal;
