import styled from 'styled-components';

const VerticalDots = styled.div`
    position: relative;
    width: 10px;
    height: 28px;

    &:after {
        content: '•••';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-45%, -45%) rotate(90deg);
        font-size: 15px;
        letter-spacing: 0px;
    }
`;

export default VerticalDots;
