import * as React from 'react';
import styled from 'styled-components';
import EditIcon from '../images/edit-icon.svg';
import { DeleteIcon } from '../images/icons';

export const BaseButtonStyles = `
    font-family: all-round-gothic, sans-serif;
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    &:focus {
        outline:0
    }
`;

export const BaseButton = styled.button`
    ${BaseButtonStyles}
`;

export const OutlineButtonStyles = `
    ${BaseButtonStyles}
    height: 30px;
    font-size: 14px;
    display: flex;
    padding: 5px 11px 0px 16px;
    border-radius: 20px;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.35);
    background-color: transparent;
    border: 2px solid white;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    &:hover {
        background: var(--off-white);
        color: var(--darkest-purple);
        path {
            fill: var(--darkest-purple);
            stroke: var(--darkest-purple);
        }
    }
    path {
        fill: var(--off-white);
        stroke: none;
    }
`;

export const NarrowButtonStyles = `
    height: 30px;
    font-weight: bold;
    letter-spacing: 1.3px;
    line-height: normal;
    padding: 8px 10px 0;
    font-weight: bold;
    line-height: normal;
    font-size: 12px;
`;

export const OutlineButtonNarrow = styled(BaseButton)`
    ${OutlineButtonStyles}
    ${NarrowButtonStyles}
`;

export const AuthButton = styled(BaseButton)<{ disabled?: boolean }>`
    height: 41px;
    border: 2px solid white;
    border-radius: 30px;
    box-shadow: 0 0 10px 0 rgba(3, 2, 23, 0.35);
    background-color: white;
    font-size: 20px;
    letter-spacing: 1.43px;
    color: #1b1a4d;
    font-weight: bold;
    ${({ disabled }) => disabled && disabledButtonStyles};
`;

export const StyledSmallButton = styled(BaseButton)`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    padding: 5px 15px;
    border-radius: 18px;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.35);
    background-color: white;
    color: var(--darkest-purple);
    &:hover {
        background-color: var(--purple-blue);
        color: white;
    }
    > * {
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
    ${({ disabled }) => disabled && disabledButtonStyles};
`;

const SmallButtonCopy = styled.div`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.71px;
    text-transform: uppercase;
    width: 100%;
`;

interface StyledLargeButtonProps {
    disabled?: boolean;
    green?: boolean;
}

export const StyledLargeButton = styled.button<StyledLargeButtonProps>`
    ${BaseButtonStyles};
    border-radius: 30px;
    box-shadow: 0 0 10px 0 ${(props) => props.theme.ShadowBlack};
    background-color: white;
    padding: 10px 30px;
    border: 2px solid white;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.4px;
    text-align: center;
    color: var(--steel-blue);
    cursor: pointer;
    &:hover {
        background-color: ${({ green }) => (green ? 'var(--tennis-green)' : 'var(--purple-blue)')};
        border: 2px solid ${({ green }) => (green ? 'var(--tennis-green)' : 'var(--purple-blue)')};
        color: ${({ green }) => (green ? 'var(--darkest-purple)' : 'var(--white)')};
    }
    &:active {
        background-color: var(--indigo);
        border: 2px solid var(--indigo);
        color: white;
    }
    ${({ disabled }) => disabled && disabledButtonStyles}
`;

export const StyledMediumButton = styled.button`
    ${BaseButtonStyles};
    margin-bottom: 15px;
    border-radius: 15px;
    border: 2px solid white;
    color: var(--darkest-purple);
    background-color: white;
    height: 30px;
    padding: 5px 15px 0;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1.4px;
    &:hover {
        background-color: transparent;
        color: white;
    }
`;

interface ButtonProps {
    children: any;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    icon?: any;
    style?: any;
    green?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
}

export const SmallButton: React.FC<ButtonProps> = ({ onClick, children, className, disabled, icon, type }) => (
    <StyledSmallButton onClick={onClick} className={className} disabled={disabled} type={type}>
        {icon || <></>}
        <SmallButtonCopy>{children}</SmallButtonCopy>
    </StyledSmallButton>
);

export const OutlineButton = styled(SmallButton)`
    ${OutlineButtonStyles};
    color: var(--off-white);
    padding: 5px 15px;
    ${({ disabled }) => disabled && disabledButtonStyles}
`;

export const LargeButton: React.FC<ButtonProps> = ({ green, onClick, children, disabled, type }) => (
    <StyledLargeButton disabled={disabled} onClick={disabled ? () => null : onClick} green={green} type={type}>
        {children}
    </StyledLargeButton>
);

// export const TransparentMediumButton = styled(MediumButton)``;
export const MediumButton: React.FC<ButtonProps> = ({ onClick, children, disabled, type }) => (
    <StyledMediumButton onClick={disabled ? () => null : onClick} type={type}>
        {children}
    </StyledMediumButton>
);

export const EditButton = ({ onClick }: { onClick: () => void }) => (
    <EditContainer onClick={onClick} className="d--f ai--c jc--c">
        <img alt="edit" src={EditIcon} style={{ height: '18px', width: '18px' }} />
    </EditContainer>
);

export const TinyButton = styled(SmallButton)<{ type?: string }>`
    font-size: 10px;
    padding: 4px 18px;
`;

const EditContainer = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: white;
`;

export const CircleButton = styled(BaseButton)`
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: ${(props) => props.theme.OffWhite};
    color: #010027;
    margin: 5px;

    &:first-child {
        margin-left: 0;
    }

    &:hover {
        background: #e3e3e9;
    }

    &:disabled {
        background: #6c6c89;
    }
`;

type SvgIconButtonProps = {
    baseColor: string;
    activeColor: string;
    textColor?: string;
    iconType: 'stroke' | 'fill';
};

export const SvgIconButton = styled(StyledSmallButton)<SvgIconButtonProps>`
    width: auto;
    padding: 0 15px;
    margin: 0 8px;
    white-space: nowrap;
    font-weight: bold;
    letter-spacing: 0.71px;
    text-transform: uppercase;
    height: 30px;
    color: ${(props) => props.textColor || 'var(--darkest-purple)'};
    border-color: ${(props) => props.baseColor};
    background-color: ${(props) => props.baseColor};

    path {
        stroke: ${(props) => (props.iconType === 'stroke' ? props.activeColor : 'none')};
        fill: ${(props) => (props.iconType === 'fill' ? props.activeColor : 'none')};
        transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    }

    &:hover {
        color: ${(props) => props.baseColor};
        background-color: ${(props) => props.activeColor};

        path {
            stroke: ${(props) => (props.iconType === 'stroke' ? props.baseColor : 'none')};
            fill: ${(props) => (props.iconType === 'fill' ? props.baseColor : 'none')};
        }
    }

    .svgIcon {
        top: 2px;
        margin-right: 0.5rem;
    }

    &:disabled {
        path {
            stroke: ${(props) => (props.iconType === 'stroke' ? '#6c6c89' : 'none')};
            fill: ${(props) => (props.iconType === 'fill' ? '#6c6c89' : 'none')};
        }
    }
`;

export const GreenHoverButtonStyles = `    
    &:hover {
        background-color: var(--tennis-green);
        color: var(--darkest-purple);
    }
`;

const StickyButtonWrapper = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 40;
    background: rgba(1, 0, 39, 0.8);
    padding: 30px 60px 60px 30px;
    border-top-left-radius: 30px;
`;

const StickyButtonElement = styled(StyledSmallButton)`
    height: 45px;
    border-radius: 30px;
    box-shadow: 0 0 10px 0 rgba(3, 2, 23, 0.35);
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.43px;
    padding: 0 30px;
    &:active {
        background-color: var(--indigo);
        border: 2px solid var(--indigo);
        color: white;
    }
`;

type StickyButtonProps = {
    disabled?: boolean;
    onClick?: () => void;
};

export const StickyButton: React.FC<StickyButtonProps> = ({ disabled, onClick, children }) => (
    <StickyButtonWrapper>
        <StickyButtonElement disabled={disabled} onClick={onClick}>
            {children}
        </StickyButtonElement>
    </StickyButtonWrapper>
);

export const StickyFormStatusContainer = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 40;
    padding: 30px 60px 15px 30px;
    border-top-left-radius: 30px;
`;

export const RedButton: React.FC<{ onClick: () => void; disabled?: boolean }> = ({ children, onClick, disabled }) => {
    return (
        <DeleteRoomButton
            onClick={onClick}
            activeColor="var(--off-white)"
            baseColor="var(--burnt-red)"
            iconType="stroke"
            disabled={disabled}
        >
            <DeleteIcon />
            {children}
        </DeleteRoomButton>
    );
};

const DeleteRoomButton = styled(SvgIconButton)`
    color: white;
    height: 30px;
    padding: 1px 13px 0;
    > img {
        margin-right: 15px;
    }
`;

export const disabledButtonStyles = `
    border-color: var(--battleship-grey); 
    background-color: var(--battleship-grey);
    color: var(--charcoal-grey);
    path {
        fill: var(--charcoal-grey);
        stroke: none;
    }
    &:hover {
        background-color: var(--battleship-grey);
        color: var(--charcoal-grey);
        path {
            fill: var(--charcoal-grey);
            stroke: none;
        }
    }
`;

export const SmallGreenButton = styled(SmallButton)`
    ${GreenHoverButtonStyles}
`;
