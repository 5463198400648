import * as React from 'react';
import { useSelector } from 'react-redux';
import LoadingComponent from '../components/LoadingComponent';
import { getAuthState, getCurrentUser } from '../store/selectors';
import LoggedInAcceptInvite from './LoggedInAcceptinvite';
import LoggedOutAcceptInvite from './LoggedOutAcceptInvite';
import { isEmpty } from 'react-redux-firebase';

const AcceptInvite: React.FC = () => {
    const currentUser = useSelector(getCurrentUser);
    const auth = useSelector(getAuthState);

    if (!currentUser.isLoaded) {
        return <LoadingComponent />;
    }

    if (!isEmpty(auth)) {
        return <LoggedInAcceptInvite />;
    } else {
        return <LoggedOutAcceptInvite />;
    }
};

export default AcceptInvite;
