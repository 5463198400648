import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { getAuthState, getCurrentUser, getCurrentUserHasModifiedProfile } from '../store/selectors';
import Cookies from 'js-cookie';
import CredentialsModal from '../components/CredentialsModal';
import LoadingScreen from '../components/LoadingScreen';

interface Props {
    children: React.ComponentElement<any, any>;
    path: string;
    exact?: boolean;
}

// This is a normal route, except it bounces you to the home path if you're not logged in
// It is open to extend to more involved authorization level checks in the future

const AuthRoute: React.FC<Props> = ({ children, ...rest }) => {
    const auth = useSelector(getAuthState);
    const profileLoaded = useSelector(getCurrentUser).isLoaded;
    const profileModified = useSelector(getCurrentUserHasModifiedProfile);
    const profileModalSeenRecently = !!Cookies.get('CredentialModalOptOut');

    if (!profileLoaded) return <LoadingScreen />;
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isEmpty(auth) ? (
                    <Redirect
                        to={{
                            pathname: '/log-in',
                            state: { from: location, error: 'You need to login first...' },
                        }}
                    />
                ) : !auth.emailVerified ? (
                    <Redirect
                        to={{
                            pathname: '/verify-email',
                        }}
                    />
                ) : !profileModified && !profileModalSeenRecently ? (
                    <CredentialsModal />
                ) : (
                    children
                )
            }
        />
    );
};

export default AuthRoute;
