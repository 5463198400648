import * as React from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../components/FormErrorMessage';
import { getAuthError, getAuthState, getCurrentUserAccess } from '../store/selectors';
import ValidatedFormInput from '../components/ValidatedFormInput';
import OneColumnBackgroundPage from '../components/OneColumnBackgroundPage';
import InputLabel from '../components/InputLabel';
import { isEmpty } from 'react-redux-firebase';
import GoogleAuthButton from '../components/GoogleAuthButton';
import { DividingLine } from './SignUp';
import { LargeButton } from '../components/Button';
import { RedLinkText } from '../components/Typography';
import styled from 'styled-components';
import DefaultBackground from '../images/login-bg.jpg';
import Welcome from '../images/welcome-to.png';
import Logo from '../images/nowhere-logo-2.svg';

const LogIn = () => {
    return (
        <>
            <LoginBackground />
            <OneColumnBackgroundPage title="Welcome!" subtitle="Log-in">
                <LogInComponent />
            </OneColumnBackgroundPage>
        </>
    );
};

export const LoginBackground = () => {
    return (
        <Background>
            <Link to={'/'}>
                <WelcomeText src={Welcome} alt="Welcome to Nowhere" />
            </Link>
            <Link to={'/'}>
                <NowhereLogo src={Logo} alt="nowhere logo" />
            </Link>
            <WelcomeSubtext>The future of online gathering</WelcomeSubtext>
        </Background>
    );
};

const WelcomeText = styled.img`
    margin: 0 250px 0 0;
    width: 303px;
`;
const NowhereLogo = styled.img`
    margin: -90px 0 0 0;
    width: 425px;
`;
const WelcomeSubtext = styled.h3`
    margin-left: -50px;
`;

const Background = styled.div`
    height: 100vh;
    min-height: 700px;
    background-color: #010027;
    background-image: url(${DefaultBackground});
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: all-round-gothic, sans-serif;
    color: #fff;
    font-size: 16px;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 33vh;
    width: 50%;
    @media (max-width: 1024px) {
        display: none;
    }
`;

export const LogInModal: React.FC<{ redirect: string }> = ({ children, redirect }) => {
    return (
        <>
            <BackgroundScrimContainer>
                {children}
                <BackgroundScrim />
            </BackgroundScrimContainer>
            <OneColumnBackgroundPage title="Welcome!" subtitle="Log-in">
                <LogInComponent redirect={redirect} />
            </OneColumnBackgroundPage>
        </>
    );
};

const BackgroundScrimContainer = styled.div`
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    @media (max-width: 1024px) {
        display: none;
    }
`;
const BackgroundScrim = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const LogInComponent: React.FC<{ redirect?: string }> = ({ redirect }) => {
    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const auth = useSelector(getAuthState);
    const firebase = useFirebase();
    const authError = useSelector(getAuthError);

    const userAccess = useSelector(getCurrentUserAccess);
    const canHost = ['Admin', 'Host'].includes(userAccess);

    const logInWithEmailAndPassword = () => {
        firebase.login({ email, password }).catch(console.error);
    };

    // referrer is only populated from the AuthRoutes redirect and, hopefully,
    // from invite link redirect in the LoggedOutAcceptInvite component.
    // so if the user came from a deep link behind auth gate, they are redirected there
    // while users who log in normally are redirected to either host or venue

    const location = useLocation() as any;
    const referrer = location?.state?.from?.pathname;
    const redirectLink = redirect || referrer || (canHost ? '/owned' : '/venues');

    if (!isEmpty(auth)) {
        return <Redirect to={{ pathname: redirectLink }} />;
    }

    return (
        <>
            <Container>
                <GoogleAuthButton>Log in with Google</GoogleAuthButton>
                <DividingLine />
                <form
                    id="signup-Form"
                    name="wf-form-signup-Form"
                    data-name="signup Form"
                    onSubmit={handleSubmit(logInWithEmailAndPassword)}
                    className="form-2"
                    style={{ width: '360px' }}
                >
                    <InputLabel htmlFor="loginEmail-2">Email</InputLabel>
                    <ValidatedFormInput
                        type="email"
                        errors={errors}
                        validation={register({
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        })}
                        maxLength={256}
                        name="email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                    />
                    <InputLabel htmlFor="loginPassword-2">Password</InputLabel>
                    <ValidatedFormInput
                        type="password"
                        errors={errors}
                        validation={register({ required: 'Password is required' })}
                        maxLength={256}
                        name="password"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        placeholder="Enter Password"
                        autoComplete="current-password"
                    />
                    <SubmitContainer>
                        <LargeButton type="submit">Log-in</LargeButton>
                        <Link to="/reset-password" className="link">
                            <RedLinkText>Reset Password</RedLinkText>
                        </Link>
                    </SubmitContainer>
                    {authError ? <FormErrorMessage>{authError.message}</FormErrorMessage> : <></>}
                </form>
                {location.pathname.startsWith('/invite') ||
                location.pathname.startsWith('/hostInvite') ||
                location.pathname.startsWith('/ownerInvite') ? (
                    <SignUpLink to={{ pathname: '/sign-up', state: { from: { pathname: redirectLink } } }}>
                        <h3>
                            New to Nowhere? <u>Sign-up</u>
                        </h3>
                    </SignUpLink>
                ) : (
                    <RequestAccessLink
                        href="https://www.urnowhere.com/become-a-host"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <h3>Request Access</h3>
                    </RequestAccessLink>
                )}
            </Container>
        </>
    );
};

const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const SubmitContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 0 60px;
    > div {
        margin-top: 40px;
    }
`;

const LinkStyles = ` 
    margin: 0;
    display: inline-block;
    position: absolute;
    bottom: -40px;
    left: 0;
    cursor: pointer;
    color: var(--off-white);
    &:hover {
        text-decoration: underline;
        color: white;
    }
`;

const SignUpLink = styled(Link)`
    ${LinkStyles}
`;

const RequestAccessLink = styled.a`
    ${LinkStyles}
`;

export default LogIn;
