import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { getCurrentUser } from '../store/selectors';
import { useSelector } from 'react-redux';
import LoadingComponent from '../components/LoadingComponent';
import '../css/nowhere-stag.css';
import '../css/components.css';

const Landing = () => {
    const { access } = useSelector(getCurrentUser);

    if (access === 'Free' || access === 'Basic') {
        return <Redirect to={{ pathname: '/venues' }} />;
    }

    if (access === 'Host' || access === 'Admin') {
        return <Redirect to={{ pathname: '/owned' }} />;
    }

    console.log(`User access type "${access}" is not valid`);
    return <LoadingComponent />;
};

export default Landing;
