import * as React from 'react';
import Toggle from './Toggle';
import Message from './Message';
import { Modal } from './Modal';
import { ApiRoom } from '../ApiTypes';
import { postApi } from '../utils/api';

type AllRoomToggleProps = {
    rooms: ApiRoom[];
    orgId?: string;
};

const AllRoomToggle: React.FC<AllRoomToggleProps> = ({ rooms, orgId }) => {
    const openRoomCount = rooms.filter(({ isActive }) => isActive).length;
    const [openAllWarningModal, setOpenAllWarningModal] = React.useState(false);
    const [closeAllWarningModal, setCloseAllWarningModal] = React.useState(false);
    const handleClick = (value: boolean) => {
        value ? setOpenAllWarningModal(true) : setCloseAllWarningModal(true);
    };

    return (
        <>
            <Toggle value={openRoomCount > 0} onChange={handleClick} />
            <OpenAllWarningModal
                isOpen={openAllWarningModal}
                handleClose={() => setOpenAllWarningModal(false)}
                orgId={orgId}
            />
            <CloseAllWarningModal
                isOpen={closeAllWarningModal}
                handleClose={() => setCloseAllWarningModal(false)}
                orgId={orgId}
            />
        </>
    );
};

type ModalProps = {
    isOpen: boolean;
    handleClose: () => void;
    orgId?: string;
};

const OpenAllWarningModal: React.FC<ModalProps> = ({ isOpen, handleClose, orgId }) => {
    const [updating, setUpdating] = React.useState(false);

    const closeAllRooms = () => {
        setUpdating(true);
        postApi(`/org/${orgId}/openAllRooms`)
            .then(async (r) => {
                const json = await r.json();

                if (!r.ok) {
                    setUpdating(false);
                    console.log(json.error || 'could not open rooms');
                    handleClose();
                } else {
                    window.location.reload();
                }
            })
            .catch((err) => console.log('error: ', err));
    };

    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <Message
                title="Are you sure you want to open all worlds in this venue?"
                onCancel={handleClose}
                onConfirm={closeAllRooms}
                confirmButtonText={updating ? 'Opening worlds...' : 'Open All Worlds'}
                confirmButtonDisabled={updating}
            />
        </Modal>
    );
};

const CloseAllWarningModal: React.FC<ModalProps> = ({ isOpen, handleClose, orgId }) => {
    const [updating, setUpdating] = React.useState(false);

    const closeAllRooms = (kickUsers: boolean) => {
        setUpdating(true);
        postApi(`/org/${orgId}/closeAllRooms`, { kickUsers })
            .then(async (r) => {
                const json = await r.json();

                if (!r.ok) {
                    setUpdating(false);
                    console.log(json.error || 'could not close worlds');
                    handleClose();
                } else {
                    window.location.reload();
                }
            })
            .catch((err) => console.log('error: ', err));
    };

    return (
        <Modal isOpen={isOpen} handleClose={handleClose}>
            <Message
                title="Do you want to close all worlds in this venue?"
                body="Any attendees currently in one of the worlds will be booted."
                onCancel={handleClose}
                onConfirm={() => closeAllRooms(true)}
                onSecondConfirm={() => closeAllRooms(false)}
                confirmButtonText="Close Worlds and Kick Guests Out"
                confirmButtonDisabled={updating}
                secondConfirmButtonText="Close Worlds but Guests Can Stay"
                secondConfirmButtonDisabled={updating}
            />
        </Modal>
    );
};
export default AllRoomToggle;
