import styled from 'styled-components';

interface EmptyListCardProps {
    background?: string;
}

const EmptyListCard = styled.div<EmptyListCardProps>`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    padding: 55px 22% 60px 60px;
    height:300px;
    width: 100%;
    @media (max-width:768px) {
        height: 450px;
    }
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay, normal;
    background-image: linear-gradient(to right, var(--fuchsia), var(--robin-egg-blue) 100%), url('${({ background }) =>
        background}');
    background-position: center center,center center, center center;
    background-size:cover, cover, cover;
    background-repeat: no-repeat,no-repeat,no-repeat;
    border-radius:15px;
    margin: 45px auto 60px;
    font-size:30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: var(--white);
`;

export default EmptyListCard;
