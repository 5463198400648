import * as React from 'react';
import { ApiOrganization } from '../../../../functions/src/ApiTypes';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUserId } from '../../store/selectors';
import { SmallButton } from '../../components/Button';
import goto from '../../images/goto.svg';
import gear from '../../images/gear.svg';
import styled from 'styled-components';
import { VenueUtil } from '../../utils/VenueUtil';
import { useState } from 'react';

const VenueCard = ({ venue, hostedRoomIds }: { venue: ApiOrganization; hostedRoomIds: string[] }) => {
    const link = `org/${venue?.id}`;
    const currentUserId = useSelector(getCurrentUserId);
    const isAdmin = venue.ownerIds.includes(currentUserId);
    const isHost = hostedRoomIds?.length > 0;
    const [hovered, setHovered] = useState(false);

    return (
        <Container
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            background={VenueUtil.getImage(venue)}
        >
            <Link to={link} style={{ textDecoration: 'none' }}>
                <VenueOverlay hovered={hovered}>
                    <CardText>
                        <div>
                            <HostName>Host: {venue.name}</HostName>
                            <CardTitle>{venue.title}</CardTitle>
                        </div>
                    </CardText>
                </VenueOverlay>
            </Link>
            <Bottom>
                <ButtonContainer>
                    <Link to={link} style={{ textDecoration: 'none' }}>
                        <SmallButton>
                            <img src={goto} style={{ margin: '1px 10px 3px -4px' }} alt="go to venue" />
                            Explore
                        </SmallButton>
                    </Link>
                    {(isAdmin || isHost) && (
                        <Link
                            to={isAdmin ? `${link}/admin` : `/room/${hostedRoomIds[0]}/admin`}
                            style={{ textDecoration: 'none' }}
                        >
                            <SmallButton>
                                <img src={gear} style={{ margin: '1px 10px 3px -4px', width: '15px' }} alt="admin" />
                                Admin
                            </SmallButton>
                        </Link>
                    )}
                </ButtonContainer>
                <RoomCount>{`${venue.numActiveRooms || 0} of ${venue.numRooms || 0} Worlds Open`}</RoomCount>
            </Bottom>
        </Container>
    );
};

const CardText = styled.div`
    color: #f6f6f9;
    padding: 30px 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 728px) {
        padding: 30px;
    }
`;

const CardTitle = styled.div`
    font-size: 50px;
    text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.35);
    display: inline-block;
    font-weight: normal;
    text-transform: capitalize;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    margin-top: 20px;
`;

const VenueOverlay = styled.article<{ hovered: boolean }>`
    cursor: pointer;
    width: 100%;
    height: 285px;
    border-radius: 15px;
    display: inline-block;
    background-color: ${({ hovered }) => (hovered ? 'rgba(255, 255, 255, 0.3)' : 'rgba(10, 8, 63, 0.8)')};
    background-size: cover;
    position: absolute;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    &:hover {
        background-color: rgba(255, 255, 255, 0.3);
    }
`;

const Container = styled.div<StyledContainerProps>`
    width: 100%;
    border-radius: 15px;
    height: 285px;
    position: relative;
    background-color: rgba(10, 8, 63, 0.8);
    background-image: url('${({ background }) => background}');
    background-size: cover;
}
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: absolute;
    bottom: 30px;
    left: 0;
    @media (min-width: 768px) {
        left: 30px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 30px -15px 30px;
    > * {
        margin: 0 0 15px 0;
    }
`;

const RoomCount = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    color: var(--white-two);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
`;

const HostName = styled.div`
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    color: var(--off-white);
`;

interface StyledContainerProps {
    background?: string;
}

export default VenueCard;
