import * as React from 'react';
import styled from 'styled-components';
import { LargeButton } from './Button';

interface Props {
    image: string;
    link: string;
    text: string;
    buttonText: string;
}

const CallToActionFullWidth: React.FC<Props> = ({ image, link, text, buttonText }) => {
    return (
        <Container background={image}>
            <Text>{text}</Text>
            <a href={link} target="_blank" rel="noopener noreferrer">
                <LargeButton onClick={() => null}>{buttonText}</LargeButton>
            </a>
        </Container>
    );
};

type ContainerProps = { background: string };

const Container = styled.article<ContainerProps>`
    width: 100%;
    height: 600px;
    background-image: url('${({ background }) => background}');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    > * {
        margin: 30px
    }
`;

const Text = styled.h2`
    text-shadow: 3px 3px 18px rgba(0, 0, 0, 0.9);
    font-family: all-round-gothic, sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.5px;
    text-align: center;
`;

export default CallToActionFullWidth;
