import * as React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import Message from '../components/Message';
import LoadingScreen from '../components/LoadingScreen';
import FullScreenContainer from '../components/FullScreenContainer';

const VerifyEmailConfirm = ({ code }: { code: string }) => {
    const firebase = useFirebase();
    const history = useHistory();

    const [loading, setLoading] = React.useState(true);
    const [verified, setVerified] = React.useState(false);

    React.useEffect(() => {
        firebase
            .auth()
            .applyActionCode(code)
            .then(async () => {
                setVerified(true);
                setLoading(false);
                // force logout, otherwise the email update status won't update properly
                // without a refresh
                await firebase.logout();
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }, [code, firebase]);

    if (loading) {
        return <LoadingScreen />;
    }

    if (verified) {
        return (
            <FullScreenContainer>
                <Message
                    title="Email Verification Successful!"
                    onConfirm={() => history.push('/log-in')}
                    confirmButtonText="Log In"
                />
            </FullScreenContainer>
        );
    }

    return (
        <FullScreenContainer>
            <Message
                title="Email Verification Failed"
                body="Something went wrong. Do you want to try sending the verification email again?"
                onConfirm={() => history.push('/verify-email')}
                confirmButtonText="Try Again"
            />
        </FullScreenContainer>
    );
};

export default VerifyEmailConfirm;
