import * as React from 'react';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import styled from 'styled-components';
import RoomCard from './RoomCard';
import VenuePageHeader from './VenuePageHeader';
import { GreenDivider } from '../../components/Divider';
import { Title } from '../../components/Typography';
import { ApiOrgDetails, ApiRoom } from '../../ApiTypes';
import { getApi } from '../../utils/api';
import CountWidget from '../../components/CountWidget';
import FeaturedRoomCard from './FeaturedRoomCard';
import { useSelector } from 'react-redux';
import { getCurrentUserId, getCurrentUserIsAdmin } from '../../store/selectors';
import { LargeButton, StickyButton } from '../../components/Button';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';
import FooterHero from '../../components/FooterHero';
import EmptyListCard from '../../components/EmptyListCard';
import cloudsDefault from '../../images/clouds_default_mirrored.jpeg';

const VenuePage = () => {
    const [loading, setLoading] = useState(true);
    const [venueDetails, setVenueDetails] = useState(({} as unknown) as ApiOrgDetails);

    const { orgId } = useParams() as any;

    useEffect(() => {
        getApi(`/org/${orgId}`).then(async (venueDetailsResponse) => {
            if (venueDetailsResponse.ok) {
                const r1 = await venueDetailsResponse.json();
                setVenueDetails(r1);
            }
            setLoading(false);
        });
    }, [orgId]);

    return <VenuePageComponent loading={loading} venueDetails={venueDetails} />;
};

export const VenuePageComponent: React.FC<{
    loading: boolean;
    venueDetails: ApiOrgDetails;
}> = ({ loading, venueDetails }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const roomId = urlParams.get('roomId');

    const { org } = venueDetails;
    const rooms = _.sortBy(venueDetails.rooms, [({ isActive }) => (isActive ? -1 : 1)]);
    const description = LineBreaksToBrTags(org?.introTextBody);

    const initialRoomIndex = _.findIndex(rooms, (r) => r.id === roomId);

    const [featuredRoomIdx, setFeaturedRoomIdx] = useState<number | null>(
        initialRoomIndex === -1 ? null : initialRoomIndex,
    );

    const history = useHistory();
    const currentUserId = useSelector(getCurrentUserId);
    const currentUserIsAdmin = useSelector(getCurrentUserIsAdmin);
    const isVenueAdmin = currentUserIsAdmin || venueDetails?.org?.ownerIds.includes(currentUserId);
    const isRoomHost = (room: ApiRoom) => venueDetails?.hostedRoomIds?.includes(room.id);

    if (loading) return <LoadingComponent />;
    if (!venueDetails) return <Redirect to="/404" />;

    if (!org || !rooms) {
        return <LoadingComponent />;
    }
    const handleEditVenue = () => history.push(`/org/${org.id}/admin`);

    const activeRooms = rooms.filter(({ isActive }) => isActive);
    return (
        <>
            {isVenueAdmin && (
                <div>
                    <StickyButton onClick={handleEditVenue} disabled={false}>
                        Edit Venue
                    </StickyButton>
                </div>
            )}
            <VenuePageHeader organization={org} />
            <Description>{description}</Description>
            <RoomGridContainer>
                <div>
                    <Subhead>{rooms.length !== 1 && 'World List'}</Subhead>
                    <CountWidget
                        items={activeRooms || []}
                        label={`Active World${activeRooms.length === 1 ? 's' : ''} (of ${rooms.length})`}
                    />
                </div>
                {!activeRooms || activeRooms?.length === 0 ? (
                    <EmptyListCard background={cloudsDefault}>
                        <div style={{ maxWidth: '600px' }}>
                            You&apos;ve got a squeaky clean venue.
                            <br />
                            Time to add your first world.
                        </div>
                        <div>
                            <LargeButton onClick={() => history.push(`/org/${org.id}/createRoom`)}>
                                Create a World
                            </LargeButton>
                        </div>
                    </EmptyListCard>
                ) : activeRooms?.length === 1 ? (
                    <FeaturedRoomCard
                        room={activeRooms[0]}
                        handleClose={() => console.log('refraining from closing room')}
                        isVenueAdmin={isVenueAdmin || isRoomHost(activeRooms[0])}
                        unclosable={true}
                    />
                ) : (
                    <>
                        {/*So sorry about the awful nested boolean mess below.*/}
                        {/*It shuffles elements of a two-wide grid around*/}
                        {/*in such a way that the next room card is displayed before */}
                        {/*the double-wide feature card in the name of not leaving an awkward*/}
                        {/*hole in the grid in a 2x2 grid. */}
                        <RoomGrid>
                            {activeRooms.map((room, idx) => {
                                if (featuredRoomIdx !== null && featuredRoomIdx % 2 === 0) {
                                    if (featuredRoomIdx === idx)
                                        return (
                                            <FeaturedRoomCard
                                                room={room}
                                                handleClose={() => setFeaturedRoomIdx(null)}
                                                isVenueAdmin={isVenueAdmin || isRoomHost(room)}
                                            />
                                        );
                                    return (
                                        <RoomCard
                                            room={room}
                                            venueTitle={org.title}
                                            isVenueAdmin={isVenueAdmin || isRoomHost(room)}
                                            handleSetFeatured={() => setFeaturedRoomIdx(idx)}
                                            key={room.id}
                                        />
                                    );
                                } else {
                                    if (featuredRoomIdx === idx + 1) {
                                        return (
                                            <>
                                                <FeaturedRoomCard
                                                    room={rooms[idx + 1]}
                                                    handleClose={() => setFeaturedRoomIdx(null)}
                                                    isVenueAdmin={isVenueAdmin || isRoomHost(rooms[idx + 1])}
                                                />
                                                <RoomCard
                                                    isVenueAdmin={isVenueAdmin || isRoomHost(rooms[idx + 1])}
                                                    room={room}
                                                    venueTitle={org.title}
                                                    handleSetFeatured={() => setFeaturedRoomIdx(idx)}
                                                    key={room.id}
                                                />
                                            </>
                                        );
                                    } else if (featuredRoomIdx === idx) {
                                        return <></>;
                                    } else {
                                        return (
                                            <RoomCard
                                                isVenueAdmin={isVenueAdmin || isRoomHost(room)}
                                                venueTitle={org.title}
                                                room={room}
                                                handleSetFeatured={() => setFeaturedRoomIdx(idx)}
                                                key={room.id}
                                            />
                                        );
                                    }
                                }
                            })}
                        </RoomGrid>
                    </>
                )}
            </RoomGridContainer>
            <GreenDivider style={{ width: '289px', margin: '60px 0' }} />
            <FooterHero />
        </>
    );
};

const Subhead = styled(Title)`
    padding: 0 0 75px 60px;
`;

const Description = styled.div`
    width: 80%;
    max-width: 1023px;
    font-family: all-round-gothic, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.6px;
    text-align: center;
    color: white;
    margin: 60px auto 45px;
    @media (min-width: 728px) {
        font-size: 24px;
    }
`;

const RoomGridContainer = styled.section`
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 50px 50px;
`;

const RoomGrid = styled.div`
    margin: 0 0 30px 0;
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
`;

export default VenuePage;
