import * as React from 'react';
import { OutlineButton, StickyButton, StickyFormStatusContainer } from '../components/Button';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GreyishBodyText, RedLinkText, Title } from './Typography';
import SocialCardFormContents from './SocialCardFormContents';
import { useForm } from 'react-hook-form';
import { ApiUser } from '../../../functions/src/ApiTypes';
import { useSelector } from 'react-redux';
import { getCurrentUser, getCurrentUserHasModifiedProfile } from '../store/selectors';
import { postApi } from '../utils/api';
import FormErrorMessage from './FormErrorMessage';
import LoadingScreen from './LoadingScreen';
import _ from 'lodash';
import Cookies from 'js-cookie';

const CredentialsModal: React.FC = () => {
    const currentUser = useSelector(getCurrentUser);
    const [userProfile, setUserProfile] = useState<ApiUser>(({} as unknown) as ApiUser);
    const [loading, setLoading] = useState(true);
    const [canSubmit, setCanSubmit] = useState(false);
    const updateUserProfile = (profile: ApiUser) => {
        setUserProfile(profile);
        setCanSubmit(true);
    };

    useEffect(() => {
        setUserProfile(currentUser);
        setLoading(false);
    }, [currentUser]);

    const useFormObject = useForm();
    const { handleSubmit } = useFormObject;
    const [updating, setUpdating] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [apiError, setApiError] = useState('');

    const profileModified = useSelector(getCurrentUserHasModifiedProfile);
    const modalSeenRecently = !!Cookies.get('ChromeOnlyBypass');
    if (profileModified || modalSeenRecently) window.location.reload(false);
    if (loading || _.isEmpty(currentUser)) return <LoadingScreen />;

    const handleSkip = () => {
        Cookies.set('CredentialModalOptOut', 'temp', { expires: 1 });
        window.location.reload(false);
    };
    const doNotPester = () => {
        Cookies.remove('CredentialModalOptOut', 'temp', { expires: 1 });
        Cookies.set('CredentialModalOptOut', 'perm');
        window.location.reload(false);
    };

    const updateProfile = async () => {
        setUpdating(true);
        setUpdated(false);
        setApiError('');

        postApi('/user/me/update', {
            access: userProfile.access,
            avatarUrl: userProfile.avatarUrl,
            badgeSubtitle: userProfile.badgeSubtitle,
            company: userProfile.company,
            displayName: userProfile.displayName,
            social: userProfile.social,
            title: userProfile.title,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            url: userProfile.url,
            phone: userProfile.phone,
            displayEmail: userProfile.displayEmail,
        })
            .then(async (r) => {
                if (!r.ok) {
                    const response = await r.json();
                    if (response.apiError?.isJoi) {
                        console.log(response.apiError.details.map((d: any) => d.message).join(', '));
                        setApiError(response.apiError.details.map((d: any) => d.message).join(', '));
                    } else {
                        setApiError('Something went wrong');
                    }
                } else {
                    setUpdated(true);
                    Cookies.set('CredentialModalOptOut', 'perm');
                    setTimeout(() => {
                        setUpdated(false);
                    }, 30000);
                }
                setUpdating(false);
            })
            .catch((err) => {
                setApiError(err.message);
            });
    };

    return (
        <>
            <Container>
                <Title>Credentials</Title>
                <GreyishBodyText>Before you go, complete your card and build your community.</GreyishBodyText>
                <GreyishBodyText>
                    Edit this info any time on your <Link to="/profile-edit">Account Page</Link>
                </GreyishBodyText>
                <br />
                <div style={{ display: 'flex', alignItems: 'baseline', width: '100%' }}>
                    <OutlineButton onClick={handleSkip} style={{ marginRight: '30px' }}>
                        Skip for now
                    </OutlineButton>
                    <RedLinkText style={{ padding: '20px' }} onClick={doNotPester}>
                        Never ask again
                    </RedLinkText>
                </div>
                <br />
                <form onSubmit={handleSubmit(updateProfile)} action="">
                    <StickyButton disabled={!canSubmit}>
                        {updating ? 'Saving...' : updated ? 'Saved!' : 'Save'}
                    </StickyButton>
                    <input type="submit" style={{ display: 'none' }} />
                    <StickyFormStatusContainer>
                        {apiError && <FormErrorMessage>Error: {apiError}</FormErrorMessage>}
                        {updated && <h3>Update Successful!</h3>}
                    </StickyFormStatusContainer>
                </form>

                <SocialCardFormContents
                    useForm={useFormObject}
                    userProfile={userProfile}
                    updateUserProfile={updateUserProfile}
                />
                <br />
                <br />
            </Container>
        </>
    );
};

const Container = styled.div`
    margin: 60px auto 120px;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default CredentialsModal;
