import * as firebase from 'firebase';
import querystring from 'querystring';

type ApiParams = {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    path: string;
    params?: Record<string, string | boolean | number | undefined>;
    data?: Record<string, unknown>;
};

type FetchParams = {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    headers: Record<string, string>;
    body?: string;
};

async function makeApiCall({ method, path, params, data }: ApiParams) {
    const idToken = await firebase.auth().currentUser?.getIdToken();

    let baseUrl = 'https://beta.urnowhere.com';
    if (process.env.REACT_APP_BACKEND === 'localhost') {
        baseUrl = 'http://localhost:5001/nowhere-bb566/us-central1';
    }

    const url = `${baseUrl}/api${path}${params ? '?' + querystring.stringify(params || {}) : ''}`;

    const headers: Record<string, string> = { 'Content-Type': 'application/json' };
    if (idToken) {
        headers.Authorization = `Bearer ${idToken}`;
    }

    const fetchParams: FetchParams = {
        method,
        headers,
    };

    if (data) {
        fetchParams.body = JSON.stringify(data || {});
    }

    return fetch(url, fetchParams);
}

export async function getApi(path: string, params?: Record<string, string>) {
    return makeApiCall({ method: 'GET', path, params });
}

export async function postApi(path: string, data?: Record<string, unknown>) {
    return makeApiCall({ method: 'POST', path, data });
}

export async function deleteApi(path: string, params?: Record<string, string | boolean | number | undefined>) {
    return makeApiCall({ method: 'DELETE', path, params });
}
