import * as React from 'react';
import Routes from './routes/Routes';
import './css/components.css';
import './css/normalize.css';
import './css/nowhere-stag.css';
import './css/flexbox.css';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createStore } from 'redux';
import reducers from './store/reducers';
import { firebaseApp } from './firebase/Connection';
import { createFirestoreInstance } from 'redux-firestore';
import { ThemeProvider } from 'styled-components';
import AuthIsLoaded from './firebase/AuthIsLoaded';
import GlobalStyles from './css/GlobalStyles';

const theme = {
    DarkPurple: 'rgba(28, 2, 33, 0.75)',
    ShadowBlack: 'rgba(0, 0, 0, 0.35)',
    OffWhite: '#f6f6f9',
};

const App = () => {
    const initialState = {};
    const store = createStore(reducers, initialState);
    const reactReduxFirebaseConfig = {
        userProfile: 'users',
        useFirestoreForProfile: true,
    };
    const reactReduxFirebaseProps = {
        firebase: firebaseApp,
        dispatch: store.dispatch,
        config: reactReduxFirebaseConfig,
        createFirestoreInstance,
    };

    return (
        <>
            <GlobalStyles />
            <React.StrictMode>
                <Provider store={store}>
                    <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
                        <ThemeProvider theme={theme}>
                            <AuthIsLoaded>
                                <Routes />
                            </AuthIsLoaded>
                        </ThemeProvider>
                    </ReactReduxFirebaseProvider>
                </Provider>
            </React.StrictMode>
        </>
    );
};

export default App;
