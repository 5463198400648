import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AcceptApiInvite } from '../ApiTypes';
import { LoadingImage } from '../components/LoadingComponent';
import Message from '../components/Message';
import { postApi } from '../utils/api';

const LoggedInAcceptInvite = () => {
    const { inviteCode } = useParams() as any;
    const urlParams = new URLSearchParams(window.location.search);
    const roomId = urlParams.get('roomId');

    const [error, setError] = React.useState('');
    const [waiting, setWaiting] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const history = useHistory();

    useEffect(() => {
        console.log('in logged in accept invite');
        postApi('/org/acceptInvite', {
            inviteCode,
        })
            .then(async (response) => {
                console.log('response from accept', response);
                console.log(roomId);
                setSuccess(response.ok);
                setWaiting(false);
                if (response.ok) {
                    const json = await response.json();
                    const { org } = json as AcceptApiInvite;
                    const url = roomId ? `/room/${roomId}/lobby` : `/org/${org!.id}`;
                    history.push(url);
                } else {
                    setError((await response.json()).message);
                }
            })
            .catch((e) => {
                console.error(e);
                setWaiting(false);
                setSuccess(false);
            });
    }, [inviteCode, history, roomId]);

    return (
        <Message title="Opening a portal ...">
            {waiting ? (
                <LoadingImage />
            ) : success ? (
                <>
                    You&apos;re in! <br />
                    <br />
                </>
            ) : error ? (
                error
            ) : (
                'something went wrong.'
            )}
        </Message>
    );
};

export default LoggedInAcceptInvite;
