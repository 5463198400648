import * as React from 'react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import RoomAdminHeader from './RoomAdminHeader';
import { Modal } from '../../components/Modal';
import EditSchedule from '../../components/EditSchedule';
import { ApiOrganization, ApiRoomDetails } from '../../ApiTypes';
import styled from 'styled-components';
import { LargeButton, OutlineButton, RedButton, SmallButton } from '../../components/Button';
import { RoomUtil } from '../../utils/RoomUtil';
import ScreenshareButton from './ScreenshareButton';
import AdminPanel from '../../components/AdminPanel';
import { getApi, postApi } from '../../utils/api';
import FormErrorMessage from '../../components/FormErrorMessage';
import Message from '../../components/Message';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';
import { StreamIcon } from '../../images/icons';
import { GreyishBodyText } from '../../components/Typography';
import ScreenAudioAdjustments from './ScreenAudioAdjustments';
import InviteToRoom from './InviteToRoom';

type RoomAdminProps = {
    roomDetails: ApiRoomDetails;
    org: ApiOrganization;
    setRoomIsActive: (isActive: boolean) => void;
    regenerateInviteCode: (onSuccess: () => void) => void;
    canDeleteRoom: boolean;
};

const RoomAdminBody: React.FunctionComponent<RoomAdminProps> = (props) => {
    const history = useHistory();

    const [isEditingSchedule, setIsEditingSchedule] = useState(false);
    const [showRoomDeleteModal, setShowRoomDeleteModal] = useState(false);
    const [error, setError] = useState('');
    const [showScreenAdjustmentModal, setShowScreenAdjustmentModal] = useState(false);

    const { org, roomDetails, setRoomIsActive, regenerateInviteCode, canDeleteRoom } = props;
    const { room } = roomDetails;
    const [showRegenerateModal, setShowRegenerateModal] = React.useState(false);

    const goToStats = async () => {
        const response = await getApi(`/room/${room.id}/statsRedirect`);
        const json = await response.json();
        window.location.href = json.url;
    };

    const deleteRoom = () => {
        postApi(`/room/${room.id}/delete`)
            .then(console.log)
            .then(() => history.push(`/org/${room.orgId}/admin`))
            .catch(setError);
    };

    const roomName = room?.roomName;
    const longDescription = LineBreaksToBrTags(room?.longDescription);

    return (
        <>
            <Modal handleClose={() => setShowRoomDeleteModal(false)} isOpen={showRoomDeleteModal}>
                <Message
                    title={`Are you sure you want to delete the world ${roomName}?`}
                    body="This cannot be undone."
                    onCancel={() => setShowRoomDeleteModal(false)}
                    onConfirm={deleteRoom}
                    confirmButtonText={'Delete World'}
                />
            </Modal>
            <Modal handleClose={() => setIsEditingSchedule(false)} isOpen={isEditingSchedule}>
                <EditSchedule title={`Schedule for ${roomName}`} />
            </Modal>
            <Modal handleClose={() => setShowScreenAdjustmentModal(false)} isOpen={showScreenAdjustmentModal}>
                <ScreenAudioAdjustments room={room} />
            </Modal>

            <Modal handleClose={() => setShowRegenerateModal(false)} isOpen={showRegenerateModal}>
                <Message
                    title="Are you sure you want to generate a new link?"
                    body="All previous invite links will no longer work. ONLY people with the new link will be able to join"
                    onCancel={() => setShowRegenerateModal(false)}
                    onConfirm={() => regenerateInviteCode(() => setShowRegenerateModal(false))}
                    confirmButtonText="I Understand, Generate New Link"
                >
                    <br />
                    ::Note:: that this only applies to new attendees. This will not affect anyone who joined previously,
                    their access will remain unchanged.
                </Message>
            </Modal>
            <RoomAdminHeader
                roomDetails={roomDetails}
                setRoomIsActive={setRoomIsActive}
                org={org}
                setIsEditingSchedule={setIsEditingSchedule}
            />

            <BottomContainer>
                <div>
                    <h3 style={{ marginBottom: '30px' }}>Preview Image</h3>
                    <Link to={`/room/${room.id}/lobby`} target="_blank" style={{ textDecoration: 'none' }}>
                        <Image src={RoomUtil.getImage(room)}>
                            <LargeButton>Launch World</LargeButton>
                        </Image>
                    </Link>
                    {longDescription && (
                        <>
                            <h3 style={{ marginBottom: '15px', maxWidth: '600px' }}>World Description</h3>
                            <p style={{ marginBottom: '15px', maxWidth: '600px' }}>{longDescription}</p>
                        </>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StreamIcon style={{ marginRight: '30px' }} /> <h3> Screen Share Controls</h3>
                    </div>
                    <br />
                    <GreyishBodyText>Click below to share your screen to this world!</GreyishBodyText>
                    <br />
                    <ScreenshareButton streamName="nonagon" roomId={room.id} size="small">
                        Screenshare To Nonagon
                    </ScreenshareButton>
                    <br />
                    <ScreenshareButton streamName="movie" roomId={room.id} size="large">
                        Screenshare To Movie Screen
                    </ScreenshareButton>
                    <br />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <InviteToRoom
                            inviteCode={org.inviteCode!}
                            inviteHostCode={room.inviteHostCode!}
                            roomId={room.id}
                        />
                    </div>
                    <SmallButton onClick={() => setShowRegenerateModal(true)}>Regenerate Host Invite Code</SmallButton>
                    <AdminPanel>
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://console.firebase.google.com/u/0/project/nowhere-bb566/firestore/data~2Frooms~2F${room.id}`}
                                >
                                    Link to Firebase Record
                                </a>
                            </li>
                            {room.hostname ? (
                                <>
                                    <li>
                                        Current host: {`https://${room.hostname}`}{' '}
                                        {room.hostname?.startsWith('tyf') ? 'SPECIAL DIGITIAL OCEAN TYF BOX' : ''}
                                    </li>
                                    <li>
                                        <a
                                            href={`https://console.cloud.google.com/compute/instancesDetail/zones/us-east1-b/instances/${
                                                room.hostname.split('.')[0]
                                            }`}
                                        >
                                            View instance on GCP
                                        </a>
                                    </li>
                                    <li>
                                        <SmallButton onClick={goToStats}>Go to Stats</SmallButton>
                                    </li>
                                </>
                            ) : (
                                <li>
                                    <b>No current instance</b>
                                </li>
                            )}
                        </ul>
                        <OutlineButton onClick={() => setShowScreenAdjustmentModal(true)}>
                            ADVANCED :: Adjust screen audio levels
                        </OutlineButton>
                    </AdminPanel>
                </div>
                <div>
                    {canDeleteRoom && (
                        <RedButton onClick={() => setShowRoomDeleteModal(true)} disabled={false}>
                            Delete World
                        </RedButton>
                    )}
                    <br />
                    {error && <FormErrorMessage>Error: {error}</FormErrorMessage>}
                </div>
            </BottomContainer>
        </>
    );
};

const BottomContainer = styled.div`
    padding: 30px 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Image = styled.div<{ src: string }>`
    height: 240px;
    width: 540px;
    background-image: url('${({ src }) => src}');
    box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.35);
    border-radius: 30px;
    object-fit: contain;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 15px;
    margin-bottom: 60px;
`;
export default RoomAdminBody;
