import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import Base from './Base';
import CreateRoom from './CreateRoom';
import CreateOrg from './CreateOrg';
import EditRoom from './EditRoom';
import FourOhFour from './404';
import Header from '../components/header/Header';
import Owned from './Owned';
import Landing from './Landing';
import LogIn from './LogIn';
import NonAuthorizedRoute from './NonAuthorizedRoute';
import VenueAdmin from './venue/VenueAdmin';
import VenuePage from './venue/VenuePage';
import ProfileEdit from './ProfileEdit';
import ResetPassword from './ResetPassword';
import RoomLobby from './RoomLobby';
// import RoomLobbyTwilio from './RoomLobbyTwilio';
import RoomAdmin from './room/RoomAdmin';
import SignUp from './SignUp';
import TokenDebug from './TokenDebug';
import Venues from './Venues';
import VerifyEmail from './VerifyEmail';
import AcceptInvite from './AcceptInvite';
import EditOrg from './EditOrg';
import AcceptOwnerInvite from './AcceptOwnerInvite';
import AgreeToTermsModal from './AgreeToTermsModal';
import Community from './Community';
import AdminAuthRoute from './AdminAuthRoute';
import AdminInstances from './AdminInstances';
import RoomKicked from './RoomKicked';
import ChromeOnlyModal from '../components/ChromeOnlyModal';
import AcceptCookiesModal from '../components/AcceptCookiesModal';
import CredentialsModal from '../components/CredentialsModal';
import FirebaseEmailHandler from './FirebaseEmailHandler';
import AcceptHostInvite from './AcceptHostInvite';

const Routes = () => {
    return (
        <Router>
            <div>
                <Header />
                <AcceptCookiesModal />
                <AgreeToTermsModal />
                <ChromeOnlyModal />
                <Switch>
                    <AuthRoute exact path="/">
                        <Landing />
                    </AuthRoute>
                    <Route path="/log-in">
                        <LogIn />
                    </Route>
                    <Route path="/invite/:inviteCode">
                        <AcceptInvite />
                    </Route>
                    <Route path="/ownerInvite/:inviteOwnerCode">
                        <AcceptOwnerInvite />
                    </Route>
                    <Route path="/hostInvite/:inviteHostCode">
                        <AcceptHostInvite />
                    </Route>
                    <AuthRoute path="/owned">
                        <Owned />
                    </AuthRoute>
                    <AuthRoute path="/creds-temp">
                        <CredentialsModal />
                    </AuthRoute>
                    <NonAuthorizedRoute path="/verify-email">
                        <VerifyEmail />
                    </NonAuthorizedRoute>
                    <Route path="/sign-up">
                        <SignUp />
                    </Route>
                    <Route path="/firebase-email-handler">
                        <FirebaseEmailHandler />
                    </Route>
                    <NonAuthorizedRoute path="/reset-password">
                        <ResetPassword />
                    </NonAuthorizedRoute>
                    <AuthRoute path="/profile-edit">
                        <ProfileEdit />
                    </AuthRoute>
                    <AuthRoute path="/venues">
                        <Venues />
                    </AuthRoute>
                    <AuthRoute path="/org/create">
                        <CreateOrg />
                    </AuthRoute>
                    <AuthRoute path="/org/:orgId/admin">
                        <VenueAdmin />
                    </AuthRoute>
                    <AuthRoute path="/org/:orgId/createRoom">
                        <CreateRoom />
                    </AuthRoute>
                    <AuthRoute path="/org/:orgId/edit">
                        <EditOrg />
                    </AuthRoute>
                    <AuthRoute path="/org/:orgId">
                        <VenuePage />
                    </AuthRoute>
                    <AuthRoute path="/base">
                        <Base />
                    </AuthRoute>
                    <AuthRoute path="/room/:roomId/edit">
                        <EditRoom />
                    </AuthRoute>
                    <AuthRoute path="/room/:roomId/lobby/:subRoom">
                        <RoomLobby />
                    </AuthRoute>
                    <AuthRoute path="/room/:roomId/lobby">
                        <RoomLobby />
                    </AuthRoute>
                    <AuthRoute path="/room/:roomId/kicked">
                        <RoomKicked />
                    </AuthRoute>
                    <AuthRoute path="/room/:roomId/lobbySoup">
                        <RoomLobby />
                    </AuthRoute>
                    <AuthRoute path="/room/:roomId">
                        <RoomAdmin />
                    </AuthRoute>

                    <AuthRoute path="/community">
                        <Community />
                    </AuthRoute>

                    <AdminAuthRoute path="/admin/instances">
                        <AdminInstances />
                    </AdminAuthRoute>

                    {/* NOTE(blackmad): these are for me and I can never remember what I named them */}
                    <AuthRoute path="/tokenDebug">
                        <TokenDebug />
                    </AuthRoute>
                    <AuthRoute path="/debugToken">
                        <TokenDebug />
                    </AuthRoute>
                    <Route>
                        <FourOhFour />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
};

export default Routes;
