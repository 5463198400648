import * as React from 'react';
import { useEffect, useState } from 'react';
import { ApiMyOrgs, ApiOrganization } from '../../../functions/src/ApiTypes';
import { getApi } from '../utils/api';
import { uniqBy } from 'lodash';
import LoadingScreen from '../components/LoadingScreen';
import VenueTiles from './venue/VenueTiles';

const Owned = () => {
    const [venues, setVenues] = useState(([] as unknown) as ApiOrganization[]);
    const [hostedRoomIds, setHostedRoomIds] = useState(([] as unknown) as Record<string, string[]>);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getApi(`/user/me/orgs`).then(async (response) => {
            const res = (await response.json()) as ApiMyOrgs;

            const myHostedOrgs = res.myInvitedOrgs.filter(
                (org) => org.id && Object.keys(res.myHostedRooms).includes(org.id),
            );
            const adminAccessVenues = [...res.myOwnedOrgs, ...myHostedOrgs];
            const dedupedVenues = uniqBy(adminAccessVenues, 'id');

            setVenues(dedupedVenues);
            setHostedRoomIds(res.myHostedRooms);
            setLoading(false);
        });
    }, []);

    if (loading) return <LoadingScreen />;
    return (
        <VenueTiles
            venues={venues}
            title="My Venues"
            hostedRoomIds={hostedRoomIds}
            subtitle="You are a owner of these venues.
Access controls, screen share to worlds, and invite guests."
            counterText="Venues I Own"
        />
    );
};

export default Owned;
