import * as React from 'react';
import { ApiUserConnection, ApiOrganization } from '../../../functions/src/ApiTypes';
import { ProfileNonagon } from './ProfileCircle';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SocialLinks } from 'social-links';
import {
    DeleteIcon,
    EmailIcon,
    InstagramIcon,
    LinkedInIcon,
    LinkIcon,
    PhoneIcon,
    TwitterIcon,
    GoToIcon,
} from '../images/icons';
import VerticalDotMenu from '../components/VerticalDotMenu';
import { SvgIconButton } from '../components/Button';
import LoadingComponent from './LoadingComponent';
import InviteConnectionModal from './InviteConnectionModal';
import { HalfDivider } from './Divider';

const sanitizeLink = (url?: string) => (url && url.match(/^[a-zA-Z]+:\/\//) ? url : `https://${url}`);

const ConnectionCard: React.FC<{
    connection: ApiUserConnection;
    removeConnection?: (connection: ApiUserConnection) => void;
    ownedOrgs?: ApiOrganization[];
}> = ({ connection, removeConnection, ownedOrgs }) => {
    const socialLinks = new SocialLinks();

    const [showInviteModal, setShowInviteModal] = React.useState(false);

    const user = connection.toUser;
    if (!user) return <LoadingComponent />;

    const sanitizedUrl = sanitizeLink(user?.url);
    const sanitizedLinkedin = sanitizeLink(user?.social?.linkedIn);
    const hasOwnedOrgs = ownedOrgs && ownedOrgs.length > 0;

    return (
        <Container>
            <UserDataSection>
                <ProfileNonagon avatarUrl={user.avatarUrl} size={126} />
                <ContactInfoSection>
                    <TextName>
                        {user.displayName
                            ? user.displayName
                            : user.firstName && user.lastName
                            ? `${user.firstName} ${user.lastName}`
                            : 'Anonymous'}
                    </TextName>
                    <DisplaySubtitle>{user.badgeSubtitle || user.title || user.company || ''}</DisplaySubtitle>
                    <TextCompany>
                        {/*This shows either badgeSubtitle or Title in that prominent subtitle spot*/}
                        {/*and shows the rest of the info below if all is present*/}

                        {user.badgeSubtitle && user.title}
                        {(user.title && user.company && user.badgeSubtitle && ' @ ') || ''}
                        {user.company}
                    </TextCompany>
                    <SocialLinkSection>
                        {user.displayEmail && (
                            <SocialIconLink target="_blank" href={`mailto:${user.displayEmail}`}>
                                <EmailIcon />
                            </SocialIconLink>
                        )}
                        {user.phone && (
                            <SocialIconLink target="_blank" href={`tel:${user.phone}`}>
                                <PhoneIcon />
                            </SocialIconLink>
                        )}
                        {user.url && (
                            <SocialIconLink target="_blank" href={sanitizedUrl}>
                                <LinkIcon />
                            </SocialIconLink>
                        )}
                        {user.social?.linkedIn && (
                            <SocialIconLink target="_blank" href={sanitizedLinkedin}>
                                <LinkedInIcon />
                            </SocialIconLink>
                        )}
                        {user.social?.twitter && (
                            <SocialIconLink target="_blank" href={socialLinks.getLink('twitter', user.social.twitter)}>
                                <TwitterIcon />
                            </SocialIconLink>
                        )}
                        {user.social?.instagram && (
                            <SocialIconLink
                                target="_blank"
                                href={socialLinks.getLink('instagram', user.social.instagram)}
                            >
                                <InstagramIcon />
                            </SocialIconLink>
                        )}
                    </SocialLinkSection>
                </ContactInfoSection>
            </UserDataSection>
            <WorldInfoSection>
                Met {moment(connection.ts).format('MMM Do YYYY')}&nbsp;in&nbsp;
                <VenueLink to={`/org/${connection?.room?.orgId || ''}`} disabled={!connection.room}>
                    {connection.room?.roomName || 'An Unknown Nowhere'}
                </VenueLink>{' '}
                World
            </WorldInfoSection>
            {removeConnection && (
                <MenuWrapper>
                    <VerticalDotMenu verticalButtons={true} fullHeight={hasOwnedOrgs}>
                        {hasOwnedOrgs && (
                            <>
                                <SvgIconButton
                                    onClick={() => setShowInviteModal(true)}
                                    baseColor="var(--purple-blue)"
                                    activeColor="var(--off-white)"
                                    textColor="var(--off-white)"
                                    iconType="stroke"
                                >
                                    <GoToIcon /> Invite to my Venue
                                </SvgIconButton>
                                <HalfDivider />
                            </>
                        )}
                        <SvgIconButton
                            onClick={() => removeConnection(connection)}
                            baseColor="var(--burnt-red)"
                            activeColor="var(--off-white)"
                            textColor="var(--off-white)"
                            iconType="stroke"
                        >
                            <DeleteIcon /> Remove Connection
                        </SvgIconButton>
                    </VerticalDotMenu>
                </MenuWrapper>
            )}
            {hasOwnedOrgs && (
                <InviteConnectionModal
                    isOpen={showInviteModal}
                    handleClose={() => setShowInviteModal(false)}
                    userToInvite={user}
                    ownedOrgs={ownedOrgs!}
                />
            )}
        </Container>
    );
};

const Container = styled.div`
    width: 545px;
    max-width: 70vw;
    background-color: var(--off-white);
    color: black;
    border-radius: 15px;
    padding: 15px;
    position: relative;
`;

const TextName = styled.h2`
  font-size: 24px;
  height: 24px
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--midnight);
`;

const DisplaySubtitle = styled.h3`
    font-size: 14px;
    height: 28px;
    font-weight: bold;
    letter-spacing: 1.17px;
    color: var(--midnight);
    margin-top: -2px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TextCompany = styled.h4`
    font-size: 14px;
    height: 14px;
    line-height: 1.2;
    letter-spacing: 0.35px;
    color: var(--midnight);
    font-weight: normal;
    margin-bottom: 20px;
`;

const UserDataSection = styled.section`
    display: flex;
    flex-direction: row;
`;

const ContactInfoSection = styled.section`
    padding: 12px 0 12px 20px;
    width: 365px;
`;

const SocialLinkSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
`;

const WorldInfoSection = styled.section`
    font-size: 14px;
    letter-spacing: 0.23px;
    color: var(--battleship-grey);
    padding-left: 15px;

    a {
        color: var(--battleship-grey);
        text-decoration: underline;

        &:hover,
        &:focus {
            color: var(--purple-blue);
        }
    }
`;

const SocialIconLink = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    &:last-child {
        margin-right: 0;
    }

    .fill {
        path {
            fill: var(--midnight);
        }

        &:hover {
            path {
                fill: var(--purple-blue);
            }
        }
    }

    .stroke {
        path,
        rect {
            stroke: var(--midnight);
        }

        &:hover {
            path,
            rect {
                stroke: var(--purple-blue);
            }
        }
    }
`;

const MenuWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
`;

const VenueLink = styled(Link)<{ disabled: boolean }>`
    ${({ disabled }) => disabled && 'pointer-events: none;'};
`;
export default ConnectionCard;
