import * as React from 'react';
import { useFirebase, isEmpty } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { getAuthState } from '../store/selectors';
import Message from '../components/Message';
import LoadingScreen from '../components/LoadingScreen';
import ValidatedFormInput from '../components/ValidatedFormInput';
import { AuthButton } from '../components/Button';
import FullScreenContainer from '../components/FullScreenContainer';

const PasswordResetConfirm = ({ code }: { code: string }) => {
    const { register, handleSubmit, errors } = useForm({
        reValidateMode: 'onSubmit',
        shouldFocusError: true,
    });
    const firebase = useFirebase();
    const history = useHistory();
    const auth = useSelector(getAuthState);

    const [resetError, setResetError] = React.useState('');
    const [display, setDisplay] = React.useState('LOADING');
    const [email, setEmail] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');

    const retryEmailSend = () => (isEmpty(auth) ? history.push('/reset-password') : history.push('/profile-edit'));

    const submitPassword = () => {
        firebase
            .auth()
            .confirmPasswordReset(code, newPassword)
            .then(() => {
                setDisplay('SUCCESS');
            })
            .catch((e) => {
                console.log(e);
                setResetError('Unable to submit new password to server.');
                setDisplay('ERROR');
            });
    };

    React.useEffect(() => {
        firebase
            .auth()
            .verifyPasswordResetCode(code)
            .then((emailFromFirebase) => {
                setDisplay('PASSWORD_FORM');
                setEmail(emailFromFirebase);
            })
            .catch(() => {
                setResetError('The password reset code received is either invalid or expired.');
                setDisplay('ERROR');
            });
    }, [code, firebase]);

    switch (display) {
        case 'SUCCESS':
            return (
                <FullScreenContainer>
                    <Message
                        title="Password Change Successful!"
                        onConfirm={() => (isEmpty(auth) ? history.push('/log-in') : history.push('/profile-edit'))}
                        confirmButtonText={isEmpty(auth) ? 'Log In' : 'Back to profile'}
                    />
                </FullScreenContainer>
            );
        case 'ERROR':
            return (
                <FullScreenContainer>
                    <Message
                        title="Cannot Reset Password"
                        body={resetError}
                        onConfirm={retryEmailSend}
                        confirmButtonText="Go Back"
                    />
                </FullScreenContainer>
            );
        case 'PASSWORD_FORM':
            return (
                <FullScreenContainer>
                    <Form onSubmit={handleSubmit(submitPassword)}>
                        <FormHeading>Reset Your Password</FormHeading>
                        <FormSubHeading>for {email}</FormSubHeading>
                        <ValidatedFormInput
                            labelText="New Password"
                            errors={errors}
                            minLength={8}
                            name="newPassword"
                            onChange={(e: any) => setNewPassword(e.target.value)}
                            type="password"
                            validation={register({ required: 'New password is required' })}
                            value={newPassword}
                        />
                        <AuthButton>Save</AuthButton>
                    </Form>
                </FullScreenContainer>
            );
        case 'LOADING':
        default:
            return <LoadingScreen />;
    }
};

const Form = styled.form`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`;

const FormHeading = styled.h2`
    font-size: 24px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    color: var(--white);
    margin-bottom: 5px;
`;

const FormSubHeading = styled.h3`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.27px;
    color: var(--battleship-grey);
    margin-bottom: 20px;
`;

export default PasswordResetConfirm;
