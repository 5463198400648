import * as React from 'react';
import { RoomCardLabel } from './RoomCardLabel';

import { Link, useLocation } from 'react-router-dom';
import { ApiRoom } from '../ApiTypes';

type RoomLinkProps = {
    room: ApiRoom;
};
export const RoomLink: React.FunctionComponent<RoomLinkProps> = (props) => (
    <Link to={`/room/${props.room.id}`}>{props.children}</Link>
);

type RoomBoxProps = {
    room: ApiRoom;
    venueTitle?: string;
};

const RoomBox: React.FunctionComponent<RoomBoxProps> = (props) => {
    const { venueTitle, room } = props;
    const { pathname } = useLocation();
    const isRoomUnderEdit = pathname.includes(room.id);

    return (
        <RoomCardLabel
            isRoomUnderEdit={isRoomUnderEdit}
            isActive={room.isActive}
            topRightLine={venueTitle || ''}
            middleRightLine={room.roomName}
            bottomRightLine={room.description}
            topLeftLine={room.isActive ? 'Active' : 'Closed'}
            middleLeftLine={room.isActive ? room?.attendance || '0' : '- -'}
            bottomLeftLine={`of ${room.capacity}`}
        />
    );
};

export default RoomBox;
