import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getApi, deleteApi } from '../utils/api';
import { ApiConnections, ApiUserConnection, ApiOrganization } from '../ApiTypes';
import LoadingComponent from '../components/LoadingComponent';
import { LargeButton, StickyButton } from '../components/Button';
import ConnectionCard from '../components/ConnectionCard';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { Select } from '../components/Select';
import noConnectionsImage from '../images/nwCorp_v035.jpg';
import EmptyListCard from '../components/EmptyListCard';
import FooterHero from '../components/FooterHero';

const sortOptions = [
    { name: 'Sort By: Date Connected', sortBy: 'ts' },
    { name: 'Sort By: Name', sortBy: 'toUser.firstName' },
    { name: 'Sort By: Company', sortBy: 'toUser.company' },
    { name: 'Sort By: Venue Connected', sortBy: 'room.orgId' },
];

// Use lodash's get to access nested property's stored as a string,
const communitySort = (sortByProperty: string) => (a: ApiUserConnection, b: ApiUserConnection) => {
    const valueA = get(a, sortByProperty);
    const valueB = get(b, sortByProperty);

    if (valueA < valueB) {
        return -1;
    }
    if (valueA > valueB) {
        return 1;
    }

    return -1;
};

const Community: React.FunctionComponent = () => {
    const history = useHistory();
    const [connections, setConnections] = useState((undefined as unknown) as ApiConnections);
    const [ownedOrgs, setOwnedOrgs] = useState([] as ApiOrganization[]);

    useEffect(() => {
        getApi('/user/me/connections').then(async (response) => {
            const connections = (await response.json()) as ApiConnections;
            connections.connections.sort(communitySort(sortOptions[0].sortBy));
            setConnections(connections);
        });

        getApi('/user/me/orgs').then(async (response) => {
            const json = await response.json();
            setOwnedOrgs(json.myOwnedOrgs);
        });
    }, []);

    if (!connections) {
        return <LoadingComponent />;
    }

    const removeConnection = (currentConnection: ApiUserConnection) => {
        deleteApi(`/user/${currentConnection.toUser.id}/disconnect`)
            .then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    console.log(json.message || 'Error: remove connection failed');
                }
                setConnections({
                    ...connections,
                    connections: connections.connections.filter(
                        (connection) => connection.toUser.id !== currentConnection.toUser.id,
                    ),
                });
            })
            .catch((e) => console.log(e));
    };

    const sortConnections = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        connections.connections.sort(communitySort(evt.target.value));
        setConnections({ ...connections });
    };

    return (
        <>
            <Container>
                <TitleSection>
                    <div>
                        <h1>My Community</h1>
                        <h5>Your Nowhere rolladex</h5>
                    </div>
                </TitleSection>
                {connections.connections.length === 0 ? (
                    <EmptyListCard background={noConnectionsImage}>
                        <div style={{ maxWidth: '500px' }}>
                            Hmm… No connections yet.
                            <br />
                            Click on someone in a world to grow your Community.
                        </div>
                        <div>
                            <LargeButton onClick={() => history.push('/venues')}>
                                Go to Venues to start meeting people
                            </LargeButton>
                        </div>
                    </EmptyListCard>
                ) : (
                    <>
                        <Select onChange={sortConnections} style={{ marginLeft: '30px' }}>
                            {sortOptions.map((option) => (
                                <option key={option.sortBy} value={option.sortBy}>
                                    {option.name}
                                </option>
                            ))}
                        </Select>
                        <ConnectionCardsSection>
                            {(connections.connections || []).map((connection) =>
                                connection.toUser ? (
                                    <ConnectionCard
                                        key={connection.toUser.id}
                                        connection={connection}
                                        removeConnection={removeConnection}
                                        ownedOrgs={ownedOrgs}
                                    />
                                ) : undefined,
                            )}
                        </ConnectionCardsSection>
                    </>
                )}
            </Container>
            <FooterHero />
            <StickyButton onClick={() => history.push('/profile-edit')} disabled={false}>
                Edit My Information
            </StickyButton>
        </>
    );
};

const Container = styled.div`
    margin: 0px auto;
    justify-content: center;
    padding: 30px 45px;
    @media (min-width: 1280px) {
        width: 1260px;
        padding: 30px 0;
    }
`;

const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    width: 100%;
    justify-content: space-between;
    margin: 0 0 45px 30px;
`;

const ConnectionCardsSection = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    padding: 30px;
    background-color: var(--steel-blue);
    border-radius: 10px;
    width: 600px;
    margin-left: 30px;
    @media (min-width: 1250px) {
        margin: 0px auto;
        width: 1190px;
        grid-template-columns: 1fr 1fr;
    }
`;

export default Community;
