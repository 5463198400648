import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const MobileDropdown = ({
    dropdownOpen,
    setDropdownOpen,
    canBeOwner,
    handleSignOut,
}: {
    dropdownOpen: boolean;
    setDropdownOpen: any;
    canBeOwner: boolean;
    handleSignOut: () => void;
}) => {
    const close = () => setDropdownOpen(false);

    return (
        <>
            <IconContainer onClick={() => setDropdownOpen(!dropdownOpen)}>
                <StyledBurger open={dropdownOpen}>
                    <div />
                    <div />
                    <div />
                </StyledBurger>
            </IconContainer>
            {dropdownOpen && (
                <>
                    <MobileNavContainer>
                        {canBeOwner && (
                            <MobileNavLink to="/owned" onClick={close}>
                                Host
                            </MobileNavLink>
                        )}
                        <MobileNavLink to="/venues" onClick={close}>
                            Venues
                        </MobileNavLink>
                        <MobileNavLink to="/community" onClick={close}>
                            Community
                        </MobileNavLink>
                        <MobileNavLink to="/profile-edit" onClick={close}>
                            My Account
                        </MobileNavLink>
                        <MobileNavAnchor href="mailto:hello@urnowhere.com" onClick={close}>
                            Contact Us
                        </MobileNavAnchor>
                        <MobileNavAnchor onClick={handleSignOut}>Log-Out</MobileNavAnchor>
                        <br />
                        <br />
                        <br />
                        <br />
                    </MobileNavContainer>
                </>
            )}
        </>
    );
};

export const StyledBurger = styled.button<{ open: boolean }>`
    //adapted from https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: transparent;
    width: 25px;
    height: 25px;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
        outline: none;
    }

    div {
        width: 25px;
        height: 2px;
        background: var(--tennis-green);
        border-radius: 2px;
        position: relative;
        transform-origin: 1px;
        transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);

        //these are moving the divs that comprise the lines that form the x and hamburger icon, fancy!

        :first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`;

const MobileNavContainer = styled.div`
    background-color: var(--midnight);
    top: 58px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    > * {
        margin-bottom: 3vh;
    }
`;

const NavLinkStyles = `
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 6.43px;
    color: var(--white);
    cursor: pointer;
    padding: 10px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: var(--tennis-green);
        border-bottom: 3px solid var(--tennis-green);
    }
    &:active {
        text-decoration: none;
        color: var(--tennis-green);
        border-bottom: 3px solid var(--tennis-green);
    }
`;

const MobileNavLink = styled(Link)`
    ${NavLinkStyles}
`;

const MobileNavAnchor = styled.a`
    ${NavLinkStyles}
`;

const IconContainer = styled.div<{ onClick: any }>`
    width: 100%;
    margin-right: 20px;
    padding: 10px;
    border-radius: 2px;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    cursor: pointer;
`;

export default MobileDropdown;
