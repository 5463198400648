import * as React from 'react';
import styled from 'styled-components';
import { uniqueId } from 'lodash';
import checkbox from '../images/checkbox.svg';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FieldError } from 'react-hook-form';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
`;

const Box = styled.span`
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 4px;
    background: #3e3adf;
    border: 3px solid var(--indigo);
    margin-right: 15px;

    &:after {
      position: absolute;
      top: 4px;
      left: 4px;
      content: '';
      width: 9px;
      height: 5px;
      border: 3px solid #fcfff4;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.3;
    }
  }
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    color: var(--battleship-grey);
`;

const Input = styled.input`
    visibility: hidden;

    &:checked + label {
        span:after {
            opacity: 1;
        }
    }
`;

// todo: extract in to validatedFormInput
interface CheckboxProps {
    errors?: DeepMap<Record<string, any>, FieldError>;
    name?: string;
    validation?: any;
    checked: boolean;
    onChange: (e: React.ChangeEvent) => void;
    labelText?: string;
    children?: React.ReactNode;
}

const Checkbox = ({ checked, onChange, labelText, validation, name }: CheckboxProps) => {
    const id = uniqueId('checkbox_');

    return (
        <>
            <Wrapper>
                <Input id={id} name={name} type="checkbox" checked={checked} onChange={onChange} ref={validation} />
                <Label htmlFor={id}>
                    <Box />
                    {labelText}
                </Label>
            </Wrapper>
        </>
    );
};

export const OpenCheckbox: React.FC<CheckboxProps> = ({ checked, onChange, children, validation, name }) => {
    const id = uniqueId('checkbox_');

    return (
        <>
            <Wrapper>
                <Input id={id} name={name} type="checkbox" checked={checked} onChange={onChange} ref={validation} />
                <TransparentBox src={checkbox} alt="" checked={checked} onClick={onChange} />
                <label htmlFor={name}>{children}</label>
            </Wrapper>
        </>
    );
};

const TransparentBox = styled.img<{ checked: boolean; onClick: any }>`
    margin: 0 15px 0 -15px;
    background-color: ${({ checked }) => (checked ? 'var(--tennis-green)' : 'transparent')};
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    cursor: pointer;
    border-radius: 5px;
`;

export default Checkbox;
