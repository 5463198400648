import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import TwoColumnPage, { RoomCount } from '../../components/TwoColumnPage';
import RoomAdminBody from './RoomAdminBody';
import { getApi, postApi } from '../../utils/api';
import { ApiOrgDetails, ApiRoomDetails, ApiOrgInvitation } from '../../ApiTypes';
import { AddItem } from '../../components/RoomCardLabel';
import RoomList from '../venue/RoomList';
import { MediumButton, StickyButton } from '../../components/Button';
import styled from 'styled-components';
import _ from 'lodash';
import LoadingScreen from '../../components/LoadingScreen';
import { RoomInvitedUserList, RoomHostUserList, RoomBlockedUserList } from '../venue/InvitedUserList';
import LoadingComponent from '../../components/LoadingComponent';
import AllRoomToggle from '../../components/AllRoomToggle';
import { RoomDetailContext } from './roomContext';

const RoomAdmin: React.FunctionComponent = () => {
    const { roomId } = useParams() as { roomId: string };
    const [venueDetails, setVenueDetails] = useState(({} as unknown) as ApiOrgDetails);
    const [roomDetails, setRoomDetails] = useState((undefined as unknown) as ApiRoomDetails);
    const [roomDetailsWithStats, setRoomDetailsWithStats] = useState((undefined as unknown) as ApiRoomDetails);
    const [venueDetailsLoading, setVenueDetailsLoading] = useState(true);
    const [roomDetailsLoading, setRoomDetailsLoading] = useState(true);

    useEffect(() => {
        getApi(`/room/${roomId}/org`).then(async (venueDetailsResponse) => {
            if (venueDetailsResponse.ok) {
                setVenueDetails(await venueDetailsResponse.json());
            }
            setVenueDetailsLoading(false);
        });

        getApi(`/room/${roomId}`).then(async (roomDetailsResponse) => {
            if (roomDetailsResponse.ok) {
                setRoomDetails(await roomDetailsResponse.json());
            }
            setRoomDetailsLoading(false);
        });

        getApi(`/room/${roomId}/details`).then(async (roomDetailsResponse) => {
            if (roomDetailsResponse.ok) {
                setRoomDetailsWithStats(await roomDetailsResponse.json());
            }
            setRoomDetailsLoading(false);
        });
    }, [roomId]);

    const org = venueDetails?.org;
    const rooms = venueDetails?.rooms;
    const adminRooms = venueDetails.isOrgOwnerOrAdmin
        ? rooms
        : rooms
        ? rooms.filter((room) => venueDetails.hostedRoomIds.includes(room.id))
        : [];

    const history = useHistory();
    const handleBackToVenue = () => history.push(`/org/${org.id}/admin`);

    // Updating the org details with a new isActive status so that the
    // RoomList in the left column will properly reflect room open/close changes
    const updateListRoomStatus = (isActive: boolean) => {
        setVenueDetails({
            ...venueDetails,
            rooms: venueDetails.rooms.map((room) =>
                room.id !== roomId
                    ? room
                    : {
                          ...roomDetailsWithStats.room,
                          isActive,
                      },
            ),
        });
    };

    const updateRoomActiveStatus = (isActive: boolean) => {
        setRoomDetailsWithStats({
            ...roomDetailsWithStats,
            room: {
                ...roomDetailsWithStats.room,
                isActive,
            },
        });
        updateListRoomStatus(isActive);
    };

    const regenerateInviteCode = (onSuccess: () => void) => {
        postApi(`/room/${roomId}/regenerateInviteHostCode`)
            .then(async (regenerateCodeResponse) => {
                if (regenerateCodeResponse.ok) {
                    const data = await regenerateCodeResponse.json();
                    setRoomDetailsWithStats({
                        ...roomDetailsWithStats,
                        room: {
                            ...roomDetailsWithStats.room,
                            inviteHostCode: data.room.inviteHostCode,
                        },
                    });
                    onSuccess();
                }
            })
            .catch((error) => console.log(error));
    };

    const isRoomHost = (invitation: ApiOrgInvitation) => invitation?.roomHostIds?.includes(roomId);
    const ownerIds: string[] = org && org.ownerIds ? org.ownerIds : [];

    const isVenueOwner = (invitation: ApiOrgInvitation) =>
        org?.creator?.id === invitation?.user?.id || (invitation?.user?.id && ownerIds.includes(invitation.user.id));
    const isBlocked = (invitation: ApiOrgInvitation) => invitation.status === 'BLOCKED';
    const hasVisited = (invitation: ApiOrgInvitation) => (invitation?.stats?.timesEntered || 0) > 0;

    const hostAndOwnerInvitations = roomDetailsWithStats?.invitations.filter(
        (invitation) => isRoomHost(invitation) || isVenueOwner(invitation),
    );
    const blockedInvitations = roomDetailsWithStats?.invitations.filter(
        (invitation) => hasVisited(invitation) && isBlocked(invitation),
    );
    const memberInvitations = roomDetailsWithStats?.invitations.filter(
        (invitation) =>
            hasVisited(invitation) && !isRoomHost(invitation) && !isVenueOwner(invitation) && !isBlocked(invitation),
    );

    if (roomDetailsLoading || venueDetailsLoading || _.isEmpty(org)) return <LoadingScreen />;

    return (
        <>
            <Container>
                <TwoColumnPage
                    title={roomDetails?.room?.roomName}
                    leftColumn={
                        venueDetails && (
                            <div style={{ paddingTop: '15px' }}>
                                {venueDetails.isOrgOwnerOrAdmin && (
                                    <MediumButton style={{ marginBottom: '30px' }} onClick={handleBackToVenue}>
                                        Back to venue
                                    </MediumButton>
                                )}
                                <RoomPanelHeader>
                                    <RoomCount rooms={rooms} />
                                    <AllRoomToggle rooms={rooms} orgId={org.id} />
                                </RoomPanelHeader>
                                <LeftContainer>
                                    <AddItem text="New World" link={`/org/${venueDetails?.org?.id}/createRoom`} />
                                    <RoomList venueTitle={venueDetails?.org.title} rooms={adminRooms} />
                                </LeftContainer>
                            </div>
                        )
                    }
                    rightColumn={
                        <RoomAdminBody
                            setRoomIsActive={updateRoomActiveStatus}
                            roomDetails={roomDetailsWithStats || roomDetails}
                            org={org}
                            regenerateInviteCode={regenerateInviteCode}
                            canDeleteRoom={venueDetails.isOrgOwnerOrAdmin}
                        />
                    }
                />
                <div style={{ padding: '30px 60px' }}>
                    {roomDetailsWithStats ? (
                        <RoomDetailContext.Provider
                            value={{ roomDetails: roomDetailsWithStats, setRoomDetails: setRoomDetailsWithStats }}
                        >
                            <RoomHostUserList invitations={hostAndOwnerInvitations} />
                            <RoomInvitedUserList invitations={memberInvitations} />
                            <RoomBlockedUserList invitations={blockedInvitations} />
                        </RoomDetailContext.Provider>
                    ) : (
                        <LoadingComponent />
                    )}
                </div>
            </Container>
            <StickyButton onClick={() => history.push(`/room/${roomId}/edit`)} disabled={false}>
                Edit World
            </StickyButton>
            <StickyButton onClick={() => history.push(`/room/${roomId}/edit`)} disabled={false}>
                Edit World
            </StickyButton>
        </>
    );
};

const LeftContainer = styled.div`
    border-radius: 20px;
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--midnight);
    margin-top: 30px;
    padding: 15px;
`;

const RoomPanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
    align-items: center;
`;

const Container = styled.div`
    position: relative;
`;

export default RoomAdmin;
