import * as React from 'react';
import styled from 'styled-components';
import { LargeButton, SvgIconButton } from '../../components/Button';
import { Link, useHistory } from 'react-router-dom';
import { ApiRoom } from '../../ApiTypes';
import goto from '../../images/goto.svg';
import close from '../../images/close.svg';
import { RoomUtil } from '../../utils/RoomUtil';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';
import { EditIcon } from '../../images/icons';

interface ExpandedRoomCardProps {
    room: ApiRoom;
    handleClose: () => void;
    isVenueAdmin?: boolean;
    unclosable?: boolean;
}
const FeaturedRoomCard: React.FC<ExpandedRoomCardProps> = ({ room, handleClose, isVenueAdmin, unclosable }) => {
    const history = useHistory();
    const roomName = LineBreaksToBrTags(room.roomName);
    const description = LineBreaksToBrTags(room.description);
    const longDescription = LineBreaksToBrTags(room.longDescription);

    return (
        <Container isActive={room.isActive}>
            <Top className="d--f">
                {!unclosable && (
                    <CloseMe onClick={handleClose}>
                        <img src={close} alt="close" />
                    </CloseMe>
                )}
                <SubContainer isActive={room.isActive}>
                    <RoomImage background={RoomUtil.getImage(room)} />
                    <ActivityContainer>
                        {room.isActive ? (
                            <>
                                <Active>Active</Active> <ActiveCount>{room.attendance || 0}</ActiveCount>{' '}
                                <Capacity>of {room.capacity}</Capacity>
                            </>
                        ) : (
                            <>
                                <Active>Closed</Active> <ActiveCount>- -</ActiveCount>{' '}
                                <Capacity>of {room.capacity}</Capacity>
                            </>
                        )}
                    </ActivityContainer>
                </SubContainer>
                <ButtonContainer>
                    {!room.isActive && (
                        <h2>
                            Dang.
                            <br />
                            This world is closed.
                        </h2>
                    )}
                    {(room.isActive || isVenueAdmin) && (
                        <Link to={`/room/${room.id}/lobby`} target="_blank" style={{ textDecoration: 'none' }}>
                            <LargeButton green={true}>
                                <img
                                    style={{ transform: 'scale(1.3)', margin: '0 13px 2px 0' }}
                                    src={goto}
                                    alt="go to room"
                                />
                                {isVenueAdmin && !room.isActive ? 'Open World' : 'Launch World'}
                            </LargeButton>
                        </Link>
                    )}
                    {isVenueAdmin && (
                        <SvgIconButton
                            iconType="stroke"
                            baseColor="var(--off-white)"
                            activeColor="var(--navy-blue)"
                            onClick={() => history.push(`/room/${room.id}/`)}
                        >
                            <EditIcon />
                            Edit World
                        </SvgIconButton>
                    )}
                </ButtonContainer>
            </Top>
            <BodyContainer>
                <RoomName>{roomName}</RoomName>
                <Subhead>{description}</Subhead>
                <br />
                <Description>{longDescription}</Description>
            </BodyContainer>
        </Container>
    );
};

const Active = styled.h5`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: normal;
    text-align: center;
    color: var(--off-white);
`;
const ActiveCount = styled.h3`
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--off-white);
`;
const Capacity = styled.h6`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--battleship-grey);
`;
const RoomName = styled.h1`
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
`;

const Subhead = styled.h5``;

const Description = styled.p`
    -webkit-text-stroke: 1px #979797;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: var(--white);
    width: 100%;
    max-width: 720px;
`;

const Top = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    @media (min-width: 950px) {
        flex-direction: row;
    }
`;

const ButtonContainer = styled.div`
    padding-top: 60px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 768px) {
        margin: auto;
    }
    > * {
        margin-bottom: 30px;
    }
`;

const ActivityContainer = styled.div`
    display: flex;
    width: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    > * {
        margin-bottom: 20px;
    }
`;

interface StyledContainerProps {
    background: string | undefined;
}

const RoomImage = styled.article<StyledContainerProps>`
    display:flex;
    border-radius: 15px 0 15px 0;
    height: 290px;
    widtH: 90%;
    max-width: 750px;
    background-image: url('${({ background }) => background}');
    background-size: cover;
    background-position: center center;
`;

const BodyContainer = styled.div`
    margin: 15px 30px;
    @media (min-width: 728px) {
        margin: 43px 100px 15px;
    }
`;

const Container = styled.section<{ isActive?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 1 / -1;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    border-radius: 15px;
    box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.35);
    background-color: ${({ isActive }) => (isActive ? 'var(--dark-indigo)' : 'var(--charcoal-grey)')};
    margin: 0 auto;
`;

const SubContainer = styled.div<{ isActive?: boolean }>`
    width: 95%;
    max-width: 825px;
    height: 306px;
    display: flex;
    border-radius: 15px 0 15px 0;
    box-shadow: 1px 1px 30px 0 rgba(0, 0, 0, 0.35);
    background-color: ${({ isActive }) => (isActive ? 'var(--steel-blue)' : 'var(--dark)')};
    @media (min-width: 950px) {
        width: 65%;
    }
`;

const CloseMe = styled.h3<{ onClick: () => void }>`
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    padding: 1px 5px;
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    &:hover {
        background-color: var(--battleship-grey);
    }
`;
export default FeaturedRoomCard;
