import styled from 'styled-components';

export const outlinedInputStyles = `
    box-sizing: border-box;
    border-radius: 10px;
    opacity: 0.85;
    font-weight: bold;
    letter-spacing:1px;
    font-size:16px;
    background: transparent;
    color: white;
    border: solid 1px #f6f6f9;
    width: 100%;
    padding: 10px 15px;
    transition: all 0.3s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    ::placeholder {
        color: #f6f6f9;
    }
    :focus {
        outline: none;
        opacity: 1;
    }
    //keep transparent background from flickering on autofill in chrome with endless transition
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        transition: all 9999s linear;
    }
`;

export const OutlinedInput = styled.input`
    ${outlinedInputStyles};
    height: 40px;
`;

export const OutlinedTextarea = styled.textarea`
    ${outlinedInputStyles};
    height: 150px;
`;
