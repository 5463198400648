import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/selectors';
import { Link } from 'react-router-dom';
import { ProfileNonagon } from '../ProfileCircle';
import close from '../../images/close.svg';
import downArrow from '../../images/down-arrow-big.svg';
import LoadingComponent from '../LoadingComponent';

const NavDropdown = ({
    dropdownOpen,
    setDropdownOpen,
    handleSignOut,
}: {
    dropdownOpen: boolean;
    setDropdownOpen: any;
    handleSignOut: () => void;
}) => {
    const profileLoaded = useSelector(getCurrentUser).isLoaded;
    const { avatarUrl, firstName } = useSelector(getCurrentUser);
    const [hovered, setHovered] = useState(false);

    const toggleDropdownOpen = () => setDropdownOpen(!dropdownOpen);

    if (!profileLoaded) return <LoadingComponent />;
    return (
        <Container>
            <DropdownButton
                onClick={toggleDropdownOpen}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <ProfileNonagon size={35} avatarUrl={avatarUrl}>
                    {firstName?.charAt(0).toUpperCase()}
                </ProfileNonagon>
                <IconBackground hovered={hovered} dropdownOpen={dropdownOpen}>
                    {dropdownOpen ? <Icon src={close} alt="close" /> : <Icon src={downArrow} alt="open" />}
                </IconBackground>
            </DropdownButton>
            <Dropdown dropdownOpen={dropdownOpen}>
                <Link
                    to="/profile-edit"
                    style={{ textDecoration: 'none', color: 'white' }}
                    onClick={() => setDropdownOpen(false)}
                >
                    <DropdownItem>My Account</DropdownItem>
                </Link>
                <a
                    href="mailto:hello@urnowhere.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setDropdownOpen(false)}
                    style={{ textDecoration: 'none', color: 'white' }}
                >
                    <DropdownItem>Contact Us</DropdownItem>
                </a>
                <DropdownItem onClick={handleSignOut}>Log out</DropdownItem>
            </Dropdown>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    margin-right: 15px;
    * {
        transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    }
`;

const DropdownButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 60px;
`;

const IconBackground = styled.div<{ hovered: boolean; dropdownOpen: boolean }>`
    width: 39px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 2px;
    background-color: ${({ hovered, dropdownOpen }) => {
        if (hovered) return 'var(--light-navy)';
        return dropdownOpen ? 'var(--purple-blue)' : 'transparent';
    }};
`;

const Icon = styled.img`
    width: 12px;
    height: 16px;
`;

interface DropdownProps {
    dropdownOpen: boolean;
}

const Dropdown = styled.div<DropdownProps>`
    position: absolute;
    top: 60px;
    right: 0;
    display: ${({ dropdownOpen }) => (dropdownOpen ? 'flex' : 'none')};
    flex-direction: column;
    background: var(--midnight);
`;

const DropdownItem = styled.div`
    cursor: pointer;
    text-transform: uppercase;
    height: 60px;
    width: 180px;
    margin-top: 2px;
    padding-right: 26px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 3px;
    background: var(--steel-blue);
    &:hover {
        text-decoration: none;
        background: var(--purple-blue);
    }
`;

export default NavDropdown;
