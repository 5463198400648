import React, { useState } from 'react';
import styled from 'styled-components';
import { EnvironmentTypes } from '../../../../functions/src/db/DbTypes';
import citadelImage from '../../images/citadel-dropdown.jpg';
import cloudImage from '../../images/cloud-dropdown.jpg';
import forestImage from '../../images/forest-dropdown.jpg';
import customEnvironmentImage from '../../images/custom-environment-image.jpg';

const EnvironmentList: EnvironmentTypes[] = ['citadel', 'cloud', 'forest', 'custom'];

interface Props {
    environmentName?: EnvironmentTypes;
    setEnvironmentName: (environment: EnvironmentTypes) => void;
}
const EnvironmentSelect: React.FC<Props> = ({ environmentName = 'citadel', setEnvironmentName }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    const handleSetEnvironment = (environment: EnvironmentTypes) => {
        toggleOpen();
        setEnvironmentName(environment);
    };

    const nonSelectedEnvironmentNames: EnvironmentTypes[] = EnvironmentList.filter((env) => env !== environmentName);

    return (
        <Container>
            {isOpen ? (
                <DropDownList>
                    <Tile
                        onClick={() => handleSetEnvironment(environmentName)}
                        environmentName={environmentName}
                        isOpen={isOpen}
                    />
                    {nonSelectedEnvironmentNames.map((nonSelectedEnvironmentName) => (
                        <Tile
                            key={nonSelectedEnvironmentName}
                            onClick={() => handleSetEnvironment(nonSelectedEnvironmentName)}
                            environmentName={nonSelectedEnvironmentName}
                            isOpen={isOpen}
                        />
                    ))}
                </DropDownList>
            ) : (
                <DropDownList>
                    <Tile environmentName={environmentName} onClick={toggleOpen} isOpen={isOpen} />
                </DropDownList>
            )}
        </Container>
    );
};

const Tile = ({
    environmentName,
    onClick,
}: {
    environmentName: EnvironmentTypes;
    onClick: () => void;
    isOpen: boolean;
}) => {
    return (
        <EnvironmentCell environmentName={environmentName} onClick={onClick} isOpen>
            <Label>
                {environmentName === 'citadel'
                    ? 'Secret Citadel'
                    : environmentName === 'cloud'
                    ? 'Cloud Conference'
                    : environmentName === 'forest'
                    ? 'Golden Pines'
                    : 'Custom Client Environment. Inquire Nowhere'}
            </Label>
        </EnvironmentCell>
    );
};

const Container = styled('div')`
    width: 510px;
    margin: 0 auto 30px;
    position: relative;
`;

const DropDownList = styled('div')``;

const EnvironmentCell = styled.div<{ environmentName: EnvironmentTypes; isOpen: boolean }>`
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(28, 2, 33, 0.75);
    background-image: ${({ isOpen, environmentName }) => {
        if (environmentName === 'citadel') {
            if (isOpen) {
                return `linear-gradient(to left, rgba(10, 8, 63, 0), var(--steel-blue) 99%), url('${citadelImage}')`;
            }
        }
        if (environmentName === 'cloud') {
            if (isOpen) {
                return `linear-gradient(to left, rgba(10, 8, 63, 0), var(--steel-blue) 99%), url('${cloudImage}')`;
            }
        }
        if (environmentName === 'forest') {
            if (isOpen) {
                return `linear-gradient(to left, rgba(10, 8, 63, 0), var(--steel-blue) 99%), url('${forestImage}')`;
            }
        }
        if (environmentName === 'custom') {
            if (isOpen) {
                return `linear-gradient(to left, rgba(10, 8, 63, 0), var(--steel-blue) 99%), url('${customEnvironmentImage}')`;
            }
        }
    }};
    &:hover {
        > label {
            color: var(--tennis-green);
        }
    }
`;

const Label = styled.label`
    padding-left: 36px;
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.35);
    cursor: pointer;
`;
export default EnvironmentSelect;
