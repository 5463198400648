import styled from 'styled-components';
import default_image from '../images/default-profile-moose.jpg';

type ProfileCircleProps = {
    avatarUrl?: string;
    size?: number;
};

const ProfileCircle = styled.div<ProfileCircleProps>`
    background-image: url('${({ avatarUrl }) => avatarUrl || default_image}');
    ${({ avatarUrl }) => (avatarUrl ? `color: transparent` : 'color: purple')};
    background-size: cover;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    border-radius: ${({ size }) => size && size / 2}px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    text-align: center;
`;

export const ProfileNonagon = styled(ProfileCircle)`
    clip-path: var(--nonagon);
`;

ProfileCircle.defaultProps = {
    size: 30,
};

export default ProfileCircle;
