import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import firebaseConfig from '../firebase-config';

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}
export const firebaseApp = firebase;
export const db = firebase.firestore();
export const auth: firebase.auth.Auth = firebaseApp.auth();

export const doSendEmailVerificationToCurrentUser = () => {
    return doSendEmailVerification(auth.currentUser);
};

export const doSendEmailVerification = (user: firebase.User | null) => {
    const url = `${window.location.protocol}//${window.location.host}`;
    console.log('sending email to', url);
    console.log(auth.currentUser);
    return user?.sendEmailVerification({ url: url });
};
