import * as React from 'react';
import { useEffect, useState } from 'react';
import TwoColumnPage, { RoomCount } from '../../components/TwoColumnPage';
import RoomList from './RoomList';
import { useHistory, useParams } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import styled from 'styled-components';
import { SmallButton } from '../../components/Button';
import FourOhFour from '../404';
import { AddItem } from '../../components/RoomCardLabel';
import InviteToVenue from './InviteToVenue';
import { getApi, postApi } from '../../utils/api';
import { ApiOrgDetails } from '../../ApiTypes';
import eye from '../../images/eye.svg';
import edit from '../../images/edit-icon.svg';
import { VenueInvitedUserList, VenueBlockedUserList, OwnerUserList } from './InvitedUserList';
import { OrgDetailContext } from './orgContext';
import { ThinTitle } from '../../components/Typography';
import { VenueUtil } from '../../utils/VenueUtil';
import AdminPanel from '../../components/AdminPanel';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';
import AllRoomToggle from '../../components/AllRoomToggle';

const VenueAdmin: React.FunctionComponent = (_props) => {
    const [loading, setLoading] = useState(true);
    const [venueDetails, setVenueDetails] = useState(({} as unknown) as ApiOrgDetails);
    const [venueDetailsWithStats, setVenueDetailsWithStats] = useState((undefined as unknown) as ApiOrgDetails);

    const { orgId } = useParams() as any;

    useEffect(() => {
        getApi(`/org/${orgId}`).then(async (venueDetailsResponse) => {
            if (venueDetailsResponse.ok) {
                const r1 = await venueDetailsResponse.json();
                setVenueDetails(r1);
                console.log('got short response', new Date());
                console.log(r1);
            }
            setLoading(false);
        });
        getApi(`/org/${orgId}/details`).then(async (venueDetailsResponse) => {
            if (venueDetailsResponse.ok) {
                setVenueDetailsWithStats(await venueDetailsResponse.json());
                console.log('got long response', new Date());
            }
            setLoading(false);
        });
    }, [orgId]);

    const regenerateInviteCode = (isOwnerCode: boolean) => {
        const endpoint = isOwnerCode ? 'regenerateInviteOwnerCode' : 'regenerateOrgInviteCode';
        const key = isOwnerCode ? 'inviteOwnerCode' : 'inviteCode';

        postApi(`/org/${orgId}/${endpoint}`)
            .then(async (regenerateCodeResponse) => {
                if (regenerateCodeResponse.ok) {
                    const data = await regenerateCodeResponse.json();
                    setVenueDetailsWithStats({
                        ...venueDetailsWithStats,
                        org: {
                            ...venueDetailsWithStats.org,
                            [key]: data.org[key],
                        },
                    });
                }
            })
            .catch((error) => console.log(error));
    };

    const { org, rooms, invitations, owners, isOrgOwnerOrAdmin } = venueDetailsWithStats || venueDetails;
    if (loading) return <LoadingScreen />;
    if (!venueDetails) return <FourOhFour />;
    if (!isOrgOwnerOrAdmin) return <FourOhFour />;

    return (
        <OrgDetailContext.Provider
            value={{ venueDetails: venueDetailsWithStats, setVenueDetails: setVenueDetailsWithStats }}
        >
            <TwoColumnPage
                title={org?.name || ''}
                leftColumn={
                    <>
                        <RoomPanelHeader>
                            <RoomCount rooms={rooms} />
                            <AllRoomToggle rooms={rooms} orgId={orgId} />
                        </RoomPanelHeader>
                        <LeftContainer>
                            <AddItem text="New World" link={`/org/${orgId}/createRoom`} />
                            <RoomList rooms={rooms} />
                        </LeftContainer>
                    </>
                }
                rightColumn={
                    <Body
                        venueDetails={venueDetailsWithStats || venueDetails}
                        regenerateInviteCode={regenerateInviteCode}
                    />
                }
            />

            <UserLists>
                <OwnerUserList invitations={owners} owners={owners || []} />
                <VenueInvitedUserList invitations={invitations} owners={owners || []} />
                <VenueBlockedUserList invitations={invitations} owners={owners || []} />
            </UserLists>
        </OrgDetailContext.Provider>
    );
};

const RoomPanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
    align-items: center;
`;

const LeftContainer = styled.div`
    border-radius: 20px;
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--midnight);
`;

const UserLists = styled.div`
    padding: 60px 60px 120px 30px;
`;
interface BodyParams {
    venueDetails: ApiOrgDetails;
    regenerateInviteCode: (owner: boolean) => void;
}

const Body: React.FC<BodyParams> = ({ venueDetails, regenerateInviteCode }) => {
    const { org } = venueDetails;
    const { orgId } = useParams() as any;
    const title = LineBreaksToBrTags(org?.title);
    const description = LineBreaksToBrTags(org?.introTextBody);

    const history = useHistory();
    return (
        <>
            <ResponsiveWrapper>
                <TopContainer>
                    <Image src={VenueUtil.getImage(org)} />
                    <ActionsContainer>
                        <SmallButton onClick={() => history.push(`/org/${orgId}`)} icon={<img src={eye} alt="view" />}>
                            Preview
                        </SmallButton>
                        <SmallButton
                            onClick={() => history.push(`/org/${orgId}/edit`)}
                            icon={<img src={edit} alt="edit" />}
                        >
                            Edit
                        </SmallButton>
                    </ActionsContainer>
                </TopContainer>
                <div>
                    <ThinTitle>{title}</ThinTitle>
                    <h4 style={{ marginBottom: '30px' }}>Host: {org?.name}</h4>
                    <h5 style={{ maxWidth: '626px', marginBottom: '30px' }}>{description}</h5>
                </div>
            </ResponsiveWrapper>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <InviteToVenue inviteCode={org.inviteCode!} inviteOwnerCode={org.inviteOwnerCode!} />
            </div>

            <AdminPanel>
                <ul>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://console.firebase.google.com/u/0/project/nowhere-bb566/firestore/data~2Forganizations~2F${orgId}`}
                        >
                            Link to Firebase Record
                        </a>
                    </li>
                    <li>
                        <SmallButton onClick={() => regenerateInviteCode(false)}>Regenerate Invite Code</SmallButton>
                    </li>
                    <li>
                        <SmallButton onClick={() => regenerateInviteCode(true)}>
                            Regenerate Owner Invite Code
                        </SmallButton>
                    </li>
                </ul>
            </AdminPanel>
        </>
    );
};

const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 768px) {
        flex-direction: column;
    }
`;

const ActionsContainer = styled.section`
    width: 100%;
    min-width: 150px;
    display: flex;
    flex-direction: row;
    margin: 15px 30px 0 0;

    @media (min-width: 768px) {
        width: auto;
    }
    @media (min-width: 1024px) {
        flex-direction: column;
        align-items: flex-end;
        margin: 0 60px;
    }
    > button {
        margin: 0px 15px 15px 0;
    }
`;

const TopContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: flex-start;
    }
    justify-content: space-between;
    margin: 60px 0;
`;

const Image = styled.img`
    border-radius: 15px;
    width: 100%;
    height: 100%;
    max-height: 240px;
    object-fit: cover;
    max-width: 540px;
`;

export default VenueAdmin;
