import * as React from 'react';
import { useState } from 'react';
import { Prompt } from 'react-router';
import { MediumButton } from '../../components/Button';
import isHostHealthy from '../../utils/isHostHealthy';
import { postApi } from '../../utils/api';
import Screenshare from '../../utils/screenshare';
import screenshareToTwilio from '../../utils/screenshare-to-twilio';
import { Modal } from '../../components/Modal';
import { LoadingImage } from '../../components/LoadingComponent';

const MaxTries = 50;

const ScreenshareButton: React.FC<{ roomId: string; streamName: string; size: 'small' | 'large' }> = ({
    roomId,
    streamName,
    children,
    size,
}) => {
    const [error, setError] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [tries, setTries] = useState(0);
    const [wasCreated, setWasCreated] = useState(false);
    const [sharing, setSharing] = useState(false);

    const useTwilio = false;

    const doScreenshare = async (size: 'small' | 'large') => {
        if (useTwilio) {
            if (!sharing) {
                screenshareToTwilio(roomId, streamName, size, setSharing)
                    .then(() => setSharing(true))
                    .catch(() => setError(true));
            }
        } else {
            setWaiting(true);
            const response = await postApi(`/room/${roomId}/getOrCreateServer`).catch(() => setError(true));
            if (!response || !response.ok) {
                setError(true);
                return;
            }

            const { hostname, created } = await response.json();
            setWasCreated(created);

            isHostHealthy(hostname, MaxTries, (tries: number) => {
                console.log('tries,', tries);
                setTries(tries);
            })
                .then(() => {
                    new Screenshare({
                        hostname,
                        streamName,
                        roomId,
                    })
                        .startScreenshare(() => {
                            setSharing(false);
                        })
                        .then((worked: boolean) => {
                            setSharing(worked);
                        })
                        .finally(() => {
                            setWaiting(false);
                        });
                })
                .catch(() => setError(true));
        }
    };

    return (
        <>
            <Prompt
                when={sharing}
                message="You are currently sharing your screen!
                Are you sure you want to leave? This will cancel your screenshare."
            />

            <MediumButton disabled={sharing} onClick={() => doScreenshare(size)}>
                {sharing ? `Sharing to ${streamName}` : children}
            </MediumButton>
            <Modal isOpen={waiting}>
                <div>
                    Waiting for room server to be healthy <br />
                    <LoadingImage /> <br />
                    {wasCreated && 'This will take about a minute'} <br />
                    {tries}/{MaxTries} pings <br />
                    {error && 'Something went wrong, sorry :-/'} <br />
                </div>
            </Modal>
        </>
    );
};

export default ScreenshareButton;
