import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { SmallGreenButton, SvgIconButton } from '../../components/Button';
import { ApiRoom } from '../../ApiTypes';
import { RoomUtil } from '../../utils/RoomUtil';
import goto from '../../images/goto.svg';
import { RoomCardLabel } from '../../components/RoomCardLabel';
import { Link, useHistory } from 'react-router-dom';
import { GreyishBodyText } from '../../components/Typography';
import { EditIcon } from '../../images/icons';
import LinesEllipsis from 'react-lines-ellipsis';

type RoomCardProps = {
    room: ApiRoom;
    venueTitle?: string;
    handleSetFeatured?: () => void;
    isVenueAdmin?: boolean;
};
const RoomCard: React.FC<RoomCardProps> = ({ venueTitle, isVenueAdmin, room, handleSetFeatured }) => {
    const [hovered, setHovered] = useState(false);

    return (
        <Container>
            <RoomContainer
                background={RoomUtil.getImage(room)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={handleSetFeatured}
            >
                <WhiteFadeOverlay isWhite={!room.isActive} hovered={hovered} />
                <div
                    onMouseEnter={() => setHovered(true)}
                    style={{ position: 'absolute', bottom: '15px', right: '15px', cursor: 'pointer' }}
                >
                    <RoomCardLabel
                        isActive={room.isActive}
                        hide={hovered}
                        topRightLine={venueTitle}
                        middleRightLine={room.roomName}
                        bottomRightLine={room.description}
                        topLeftLine={room.isActive ? 'Active' : 'Closed'}
                        middleLeftLine={room.isActive ? room?.attendance || '0' : '- -'}
                        bottomLeftLine={`of ${room.capacity}`}
                    />
                </div>
                <RoomDetailOverlay venueTitle={venueTitle} isVenueAdmin={isVenueAdmin} room={room} hovered={hovered} />
            </RoomContainer>
        </Container>
    );
};

const Container = styled.div<{ onClick?: () => void }>`
    position: relative;
    width: 100%;
    height: 300px;
`;

const WhiteFadeOverlay = styled.section<{ isWhite: boolean; hovered: boolean }>`
    position: absolute;
    width: 100%;
    height: 300px;
    top: 0;
    border-radius: 20px;
    ${({ hovered }) => hovered && 'cursor: pointer'};
    background-color: ${({ isWhite, hovered }) =>
        isWhite && !hovered ? 'rgba(108, 108, 137, 0.5)' : 'rgba(0, 0, 0, 0)'};
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
`;

interface RoomDetailOverlayProps {
    room: ApiRoom;
    hovered: boolean;
    venueTitle?: string;
    isVenueAdmin?: boolean;
}

const RoomDetailOverlay: React.FC<RoomDetailOverlayProps> = ({ venueTitle, room, hovered, isVenueAdmin }) => {
    const history = useHistory();
    const handleEditRoom = () => history.push(`/room/${room.id}/`);
    const userCannotLaunchRoom = !isVenueAdmin && !room.isActive;

    return (
        <OverlayContainer hovered={hovered} isActive={room.isActive}>
            <h5>{venueTitle}</h5>
            <h3>
                <LinesEllipsis text={room.roomName} basedOn="letters" />
            </h3>
            <h4 style={{ marginBottom: '10px' }}>{room?.description}</h4>
            <GreyishBodyText>
                <LinesEllipsis text={room?.longDescription} maxLine="4" trimRight />
            </GreyishBodyText>
            <br />
            <ButtonContainer>
                <Link
                    to={`/room/${room.id}/lobby`}
                    target="_blank"
                    style={{
                        textDecoration: 'none',
                        pointerEvents: userCannotLaunchRoom ? 'none' : 'auto',
                        cursor: 'not-allowed',
                    }}
                >
                    <SmallGreenButton disabled={userCannotLaunchRoom} style={{ marginBottom: '15px' }}>
                        <img style={{ marginRight: '10px' }} src={goto} alt="go to room" />
                        {room.isActive ? 'Launch World' : isVenueAdmin ? 'Open World' : 'Closed'}
                    </SmallGreenButton>
                </Link>
                {isVenueAdmin && (
                    <SvgIconButton
                        iconType="stroke"
                        baseColor="var(--off-white)"
                        activeColor="var(--navy-blue)"
                        onClick={handleEditRoom}
                    >
                        <EditIcon />
                        Edit
                    </SvgIconButton>
                )}
            </ButtonContainer>
        </OverlayContainer>
    );
};

const OverlayContainer = styled.section<{ hovered: boolean; isActive?: boolean }>`
    width: 100%;
    height: 300px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    background-color: rgba(47, 45, 134, 0);
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    opacity: 0;
    padding: 30px 60px;
    ${({ hovered, isActive }) =>
        hovered &&
        `opacity: 1; background-color: ${isActive ? 'var(--dark-indigo);' : 'var(--charcoal-grey);'} cursor: pointer`};
`;

const ButtonContainer = styled.div`
    z-index: 100;
    display: flex;
    justify-content: space-between;
    width: 100%;
    > * {
        margin-right: 15px;
    }
`;

interface StyledContainerProps {
    background: string;
}

const RoomContainer = styled.article<StyledContainerProps>`
    border-radius: 15px;
    height: 100%;
    width: 100%;
    background-image: url('${({ background }) => background}');
    background-size: cover;
    background-position: center center;
    position: relative;
`;

export default RoomCard;
