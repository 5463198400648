import { BigGreenNumbers, SmallLabelText } from './Typography';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
    items: any[];
    label: string;
}

const CountWidget: React.FC<Props> = ({ items, label }) => {
    if (!items) return <></>;
    return (
        <OuterContainer>
            <Container>
                <SmallLabelText>{label}</SmallLabelText>
                <BigGreenNumbers style={{ marginTop: '-5px' }}>{items.length}</BigGreenNumbers>
            </Container>
        </OuterContainer>
    );
};

const OuterContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

const Container = styled.div`
    position: absolute;
    top: -75px;
    right: -30px;
    width: 236px;
    height: 214px;
    border-radius: 10px;
    background-color: var(--steel-blue);
    z-index: -1;
    > div {
        position: absolute;
        top: -70px;
        left: 15px;
    }
`;

export default CountWidget;
