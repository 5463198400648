import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { GreyishBodyText } from './Typography';
import ValidatedFormInput from './ValidatedFormInput';
import { TinyButton } from './Button';
import styled from 'styled-components';
import FormErrorMessage from './FormErrorMessage';
import { useParams } from 'react-router-dom';
import { inviteUserToOrg } from '../firebase/OrgInvitationService';

interface Props {
    header: string;
    icon: string;
    placeholder: string;
    tooltip: string;
}
const InviteByEmailForm: React.FC<Props> = ({ header, tooltip, icon, placeholder }) => {
    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });

    const [userEmail, setUserEmail] = useState('');
    const [sending, setSending] = useState(false);
    const [apiError, setApiError] = useState('');
    const [success, setSuccess] = useState('');

    const { orgId } = useParams() as any;
    const sendInvite = (email: string) => {
        setApiError('');
        setSuccess('');
        setSending(true);
        inviteUserToOrg({ orgId, userEmail: email }).then((res) => {
            setSending(false);
            res.ok ? setSuccess(email) : res.json().then((res) => setApiError(res.message));
        });
    };

    return (
        <Container>
            <TopSection>
                <img src={icon} alt="link icon" style={{ marginRight: '15px' }} />
                <h3>{header}</h3>
            </TopSection>
            <GreyishBodyText style={{ marginBottom: '30px' }}>{tooltip}</GreyishBodyText>
            <FormSection onSubmit={handleSubmit(() => sendInvite(userEmail))}>
                <label htmlFor="userEmail" style={{ display: 'none' }}>
                    Email
                </label>
                <ValidatedFormInput
                    type="email"
                    className="w-input"
                    maxLength={256}
                    name="userEmail"
                    errors={errors}
                    placeholder={placeholder}
                    validation={register({
                        required: 'Email is required',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                        },
                    })}
                    value={userEmail}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserEmail(e.target.value)}
                    style={{ margin: '0', width: '300px' }}
                />
                <TinyButton disabled={sending} type="submit">
                    {sending ? 'Sending...' : 'Add'}
                </TinyButton>
            </FormSection>
            {apiError && <FormErrorMessage error={apiError} />}
            {success && <label style={{ margin: '0 0 0 15px' }}>Invite sent to {success}</label>}
        </Container>
    );
};

const Container = styled.div`
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const TopSection = styled.div`
    display: flex;
    margin-bottom: 15px;
`;

const FormSection = styled.form`
    width: 450px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

export default InviteByEmailForm;
