import { RootState } from './reducers';
import { AccessType } from '../DbTypes';

export const getAuthState = (state: RootState) => state.firebase.auth;

export const getAuthError = (state: RootState) => state.firebase.authError;

export const getCurrentUserName = (state: RootState) =>
    [state.firebase?.profile?.firstName, state.firebase?.profile?.lastName].filter((s) => Boolean(s)).join(' ');

export const getCurrentUserAccess = (state: RootState): AccessType => state.firebase?.profile?.access as AccessType;

export const getCurrentUserEmail = (state: RootState): string => state.firebase?.profile?.email;

export const getCurrentUserId = (state: RootState): string => state.firebase.auth.uid;

export const getCurrentUser = (state: RootState) => state.firebase?.profile;

export const getCurrentUserIsAdmin = (state: RootState): boolean => {
    return ['Admin'].includes(getCurrentUserAccess(state));
};

export const getCurrentUserIsHost = (state: RootState): boolean => ['Host'].includes(getCurrentUserAccess(state));

export const getCurrentUserHasModifiedProfile = (state: RootState): boolean => {
    const profile = getCurrentUser(state) as any;
    const { badgeSubtitle, company, displayEmail, social, title, url } = profile;

    return !!(badgeSubtitle || company || displayEmail || social || title || url);
};

export const getCurrentUserCanCreateRooms = (store: RootState): boolean => {
    const isAdmin = getCurrentUserIsAdmin(store);
    const isHost = getCurrentUserIsHost(store);
    return isAdmin || isHost;
};

export const getAgreedToTerms = (state: RootState) => state.system.agreedToTerms;
