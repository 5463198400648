import * as React from 'react';
import GoogleAuthButton from '../components/GoogleAuthButton';
import styled from 'styled-components';
import SignUpByEmailForm from '../components/SignUpByEmailForm';
import CenteredSingleColumnLayout from '../components/CenteredSingleColumnLayout';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { getAuthState } from '../store/selectors';
import { useHistory } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import { doSendEmailVerification } from '../firebase/Connection';

const SignUp = () => {
    const auth = useSelector(getAuthState);
    const location = useLocation() as any;
    const referrer = location?.state?.from?.pathname;
    const redirectLink = referrer || '/venues';
    const history = useHistory();
    const firebase = useFirebase();

    const onSubmit = async (userProfile: Record<string, any>, password: string) => {
        const sendEmailPromise = new Promise((resolve, reject) => {
            const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    console.log('sending email after auth changed');
                    doSendEmailVerification(user);
                    unsubscribe();
                    resolve();
                } else {
                    console.log('rejecting because ...?');
                    reject();
                }
            });
        });

        const userInfo = await firebase.createUser({ email: userProfile.email, password }, userProfile);
        await sendEmailPromise.catch((e) => {
            console.error('failed to send email', e);
        });
        // TODO(blackmad): hit the server here to notify that we have a new account
        console.log(userInfo);
        // await onAccountCreate(userInfo).catch((e) => { console.error('failed to on account create', e)});
        history.push('/verify-email');
    };

    if (!isEmpty(auth)) {
        return <Redirect to={{ pathname: redirectLink }} />;
    }

    if (!referrer) return <Redirect to="log-in" />;
    return (
        <>
            <CenteredSingleColumnLayout
                title="Accept Organization Invite"
                subtitle="You’ve been chosen to join the NWR movement, enter your info below to get started."
            >
                <GoogleAuthButton>Sign up with Google</GoogleAuthButton>
                <DividingLine />
                <SignUpByEmailForm onSubmit={onSubmit} />
                <Link to={redirectLink} style={{ display: 'inline-block', width: '85vw', margin: '10px 10px 10px 0' }}>
                    <h3>
                        Already have an account? <u>Log-in</u>
                    </h3>
                </Link>
            </CenteredSingleColumnLayout>
        </>
    );
};

export const DividingLine = () => {
    return (
        <LineWrapper>
            <Line />
            <b>OR</b>
            <Line />
        </LineWrapper>
    );
};

const LineWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 800px;
    align-items: center;
    margin: 15px 0;
    > * {
        margin-right: 7px;
    }
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    border-top: solid 1px white;
    margin: 10px 0;
`;

export default SignUp;
