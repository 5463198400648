// src/store/system/reducers.ts

import { SystemState, SystemActionTypes, AGREE_TO_TERMS } from './types';

const initialState: SystemState = {
    agreedToTerms: false,
};

export function systemReducer(state = initialState, action: SystemActionTypes): SystemState {
    switch (action.type) {
        case AGREE_TO_TERMS: {
            return {
                ...state,
                agreedToTerms: true,
            };
        }
        default:
            return state;
    }
}
