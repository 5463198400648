import * as React from 'react';
import { useEffect } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { AcceptApiInvite, ApiOrganization } from '../ApiTypes';
import LoadingComponent, { LoadingImage } from '../components/LoadingComponent';
import Message from '../components/Message';
import { postApi } from '../utils/api';
import { LargeButton } from '../components/Button';
import { useSelector } from 'react-redux';
import { getAuthState, getCurrentUser } from '../store/selectors';
import { LoginBackground, LogInModal } from './LogIn';
import { isEmpty } from 'react-redux-firebase';

const AcceptOwnerInvite = () => {
    const history = useHistory();
    const { inviteOwnerCode } = useParams() as any;
    const [error, setError] = React.useState('');
    const [waiting, setWaiting] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const [org, setOrg] = React.useState({} as ApiOrganization);

    const currentUser = useSelector(getCurrentUser);
    const { pathname } = useLocation() as any;
    const auth = useSelector(getAuthState);

    const orgAdminLink = (org: ApiOrganization) => (org ? `/org/${org.id}/admin` : '/venues');

    useEffect(() => {
        postApi('/org/acceptOwnerInvite', {
            inviteOwnerCode,
        })
            .then(async (response) => {
                console.log('response', response);
                if (response.ok) {
                    const { org } = (await response.json()) as AcceptApiInvite;
                    setOrg(org!);
                } else {
                    setError((await response.json()).message);
                }
                setSuccess(response.ok);
                setWaiting(false);
            })
            .catch((e) => {
                console.error(e);
                setWaiting(false);
                setSuccess(false);
            });
    }, [inviteOwnerCode, currentUser]);

    if (!currentUser.isLoaded) {
        return <LoadingComponent />;
    }

    if (isEmpty(auth)) {
        return (
            <LogInModal redirect={pathname}>
                <LoginBackground />
            </LogInModal>
        );
    }

    if (waiting) {
        return (
            <Message title="Opening a portal ...">
                <LoadingImage />
            </Message>
        );
    }

    if (success) {
        return (
            <Message
                title="Opening a portal ..."
                body={
                    <>
                        You&apos;re in! You are now an owner of <Link to={orgAdminLink(org)}>{org.name}</Link>
                    </>
                }
            >
                <LargeButton onClick={() => history.push(orgAdminLink(org))}>Go to room admin page</LargeButton>
            </Message>
        );
    }

    if (error) return <Message title="Opening a portal ..." body={`${error}`} />;

    return <></>;
};

export default AcceptOwnerInvite;
