import * as React from 'react';
import { useState } from 'react';
import ValidatedFormInput, { TwoInputsOneLine } from '../components/ValidatedFormInput';
import { useForm } from 'react-hook-form';
import { StickyButton, MediumButton, StickyFormStatusContainer } from '../components/Button';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../store/selectors';
import UploadProfilePic, { doImageUpload } from '../components/UploadProfilePic';
import { postApi } from '../utils/api';
import { ApiUser } from '../ApiTypes';
import { GreyishBodyText, Title, WhiteLinkText } from '../components/Typography';
import styled from 'styled-components';
import { useFirebase } from 'react-redux-firebase';
import FormErrorMessage from '../components/FormErrorMessage';
import SocialCardFormContents from '../components/SocialCardFormContents';
import Cookies from 'js-cookie';

const ProfileEdit = () => {
    const useFormObject: any = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: true,
    });
    const { register, handleSubmit, errors } = useFormObject;

    const currentUser = useSelector(getCurrentUser);
    const [userProfile, setUserProfile] = useState<ApiUser>(({} as unknown) as ApiUser);
    const [userProfileChanged, setUserProfileChanged] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [apiError, setApiError] = useState('');

    React.useEffect(() => {
        setUserProfile(currentUser);
    }, [currentUser]);

    const updateUserProfile = (value: any) => {
        setUserProfile(value);
        setUserProfileChanged(true);
    };

    const onImageChanged = async (url: string) => {
        await doImageUpload(url)
            .then((newAvatarUrl) => {
                return postApi('/user/me/update', {
                    // Todo: figure out why createUser's update branch
                    // is overwriting any fields not specified.
                    // The below is just what we send to update the user
                    // profile avatar and keep everything else the same.
                    avatarUrl: newAvatarUrl || userProfile.avatarUrl,
                    access: userProfile.access,
                    badgeSubtitle: userProfile.badgeSubtitle,
                    company: userProfile.company,
                    displayName: userProfile.displayName,
                    social: userProfile.social,
                    title: userProfile.title,
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName,
                    url: userProfile.url,
                });
            })
            .then(async (r) => {
                if (!r.ok) {
                    const response = await r.json();
                    if (response.apiError?.isJoi) {
                        console.log(response.apiError.details.map((d: any) => d.message).join(', '));
                        setApiError(response.apiError.details.map((d: any) => d.message).join(', '));
                    } else {
                        setApiError('💣 Something went wrong with uploading your image 🙁');
                    }
                } else {
                    setUpdated(true);
                    setTimeout(() => {
                        setUpdated(false);
                    }, 30000);
                }
            });
    };

    const updateProfile = async () => {
        setUpdating(true);
        setUpdated(false);
        setApiError('');

        postApi('/user/me/update', {
            access: userProfile.access,
            avatarUrl: userProfile.avatarUrl,
            badgeSubtitle: userProfile.badgeSubtitle,
            company: userProfile.company,
            displayName: userProfile.displayName,
            social: userProfile.social,
            title: userProfile.title,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            url: userProfile.url,
            phone: userProfile.phone,
            displayEmail: userProfile.displayEmail,
        })
            .then(async (r) => {
                if (!r.ok) {
                    const response = await r.json();
                    if (response.error?.isJoi) {
                        console.log(response.error.details.map((d: any) => d.message).join(', '));
                        setApiError(response.error.details.map((d: any) => d.message).join(', '));
                    } else {
                        setApiError('Something went wrong');
                    }
                } else {
                    setUpdated(true);
                    Cookies.set('CredentialModalOptOut', 'perm');
                    setTimeout(() => {
                        setUpdated(false);
                    }, 30000);
                }
                setUpdating(false);
            })
            .catch((err) => {
                setApiError(err.message);
            });
    };

    const firebase = useFirebase();
    const [resetDone, setResetDone] = useState(false);
    const handleResetPassword = () => {
        const { email } = userProfile;
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                setResetDone(true);
            })
            .catch(function (apiError) {
                if (apiError.code === 'auth/user-not-found') {
                    setApiError('This email address is not currently registered. Perhaps you need to sign up?');
                } else {
                    setApiError('Oops! Something went wrong while resetting your password. Please try again later.');
                }
            });
    };

    return (
        <Container>
            <BodyContainer>
                <Title>My Account</Title>
                <UploadProfilePic horizontal currentImage={userProfile.avatarUrl} onImageChanged={onImageChanged} />
                {updated && <h3>Update Successful!</h3>}
                <form onSubmit={handleSubmit(updateProfile)}>
                    <div style={{ marginBottom: '60px', width: '100%' }}>
                        <h3>Account Info</h3>
                        <GreyishBodyText>Private, for internal use only.</GreyishBodyText>
                        <br />
                        <TwoInputsOneLine>
                            <ValidatedFormInput
                                labelText="First Name"
                                type="text"
                                errors={errors}
                                validation={register({
                                    required: 'First name is required',
                                })}
                                maxLength={50}
                                name="firstName"
                                value={userProfile.firstName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        firstName: e.target.value,
                                    })
                                }
                            />
                            <ValidatedFormInput
                                labelText="Last Name"
                                type="text"
                                errors={errors}
                                validation={register({
                                    required: 'Last name is required',
                                })}
                                maxLength={50}
                                name="lastName"
                                value={userProfile.lastName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    updateUserProfile({
                                        ...userProfile,
                                        lastName: e.target.value,
                                    })
                                }
                            />
                        </TwoInputsOneLine>
                        <label htmlFor="email">Email</label>
                        <h5 style={{ marginBottom: '30px' }}>
                            <b>{userProfile.email}</b>
                        </h5>

                        {resetDone ? (
                            <h4>Check your email for password reset instructions</h4>
                        ) : (
                            <MediumButton onClick={handleResetPassword}>Change Password</MediumButton>
                        )}
                    </div>

                    <SocialCardFormContents
                        useForm={useFormObject}
                        userProfile={userProfile}
                        updateUserProfile={updateUserProfile}
                    />
                </form>
                <StickyButton onClick={handleSubmit(updateProfile)} disabled={updating || !userProfileChanged}>
                    {updating ? 'Updating...' : updated ? 'Updated!' : 'Submit updates'}
                </StickyButton>
                <StickyFormStatusContainer>
                    {apiError && <FormErrorMessage>Error: {apiError}</FormErrorMessage>}
                    {updated && <h3>Update Successful!</h3>}
                </StickyFormStatusContainer>
                <a
                    href="https://urnowhere.com/privacy-policy"
                    style={{ textDecoration: 'underline' }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <PrivacyLink>Privacy Policy</PrivacyLink>
                </a>
            </BodyContainer>
            <RightContainer>
                <h3 style={{ marginBottom: '30px' }}>Membership</h3>
                <h4 style={{ textTransform: 'uppercase' }}>Type</h4>
                <h3>{userProfile.access}</h3>
            </RightContainer>
        </Container>
    );
};

const BodyContainer = styled.section`
    margin: 30px 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    @media (min-width: 768px) {
        width: 100%;
    }
    max-width: 600px;
    > * > div {
        margin: 20px 0;
        > h3 {
            margin: 5px 0;
        }
    }
    @media (min-width: 768px) {
        margin: 40px 120px 100px 10vw;
        align-items: flex-start;
    }
`;

const RightContainer = styled.div`
    width: 300px;
    padding: 30px 60px;
    display: inline-block;
    border-radius: 0 0 0 15px;
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--midnight);
    height: 200px;
    > * {
        margin-bottom: 15px;
    }
    margin-bottom: 150px;
    @media (min-width: 768px) {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

const Container = styled.div`
    display: flex;
    position: relative;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const PrivacyLink = styled(WhiteLinkText)`
    bottom: 15px;
    left: 120px;
    position: absolute;
    &:hover {
        color: white;
        text-decoration: none;
    }
`;

export default ProfileEdit;
