import * as React from 'react';
import styled from 'styled-components';

type Props = {
    title: string;
    subtitle?: string;
    children?: any;
};

const CenteredSingleColumnLayout: React.FC<Props> = ({ title, subtitle, children }) => {
    return (
        <Container>
            <h1 style={{ fontSize: '3rem', padding: '75px 15px 15px' }}>{title}</h1>
            <Subtitle style={{ color: '#6c6c89', marginBottom: '30px' }}>{subtitle}</Subtitle>
            {children}
        </Container>
    );
};

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 95vh;
    padding: 0 60px;
`;

const Subtitle = styled.p`
    font-family: all-round-gothic, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.27px;
    text-align: center;
    color: #6c6c89;
`;

export default CenteredSingleColumnLayout;
