import * as React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { getAuthState } from '../store/selectors';
import LoadingScreen from '../components/LoadingScreen';

interface Props {
    children: React.ComponentElement<any, any>;
}

const AuthIsLoaded: React.FC<Props> = ({ children }) => {
    const auth = useSelector(getAuthState);
    if (!isLoaded(auth)) return <LoadingScreen />;
    return children;
};

export default AuthIsLoaded;
