import * as React from 'react';
import styled from 'styled-components';
import { MediumButton } from './Button';
import Cookies from 'js-cookie';
import LowerModal from './LowerModal';
import cookieImage from '../images/cookies.svg';

const AcceptCookiesModal: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState(false);

    React.useEffect(() => {
        const cookiesAgreed = Cookies.get('agreedToCookies');
        setModalOpen(cookiesAgreed !== 'true');
    }, []);

    const agreeToCookies = () => {
        const theFuture = 365 * 100;
        Cookies.set('agreedToCookies', 'true', { expires: theFuture });
        setModalOpen(false);
    };

    return (
        <LowerModal isOpen={modalOpen}>
            <InnerContainer>
                <TopContainer>
                    <img height="55px" style={{ marginRight: '10px' }} src={cookieImage} alt="Cookie" />
                    <h2>This website uses cookies</h2>
                </TopContainer>
                <div>
                    At Nowhere we believe in the healthy use and privacy of our user’s data. We use cookies to analyse
                    our traffic and streamline user experience, we do not sell that information or use it for
                    advertising.
                </div>
                <MediumButton onClick={agreeToCookies}>Allow Cookies</MediumButton>
            </InnerContainer>
        </LowerModal>
    );
};

const TopContainer = styled.div`
    display: flex;
    align-items: center;
`;

const InnerContainer = styled.div`
    max-width: 600px;
    > * {
        margin: 20px 0;
    }
`;

export default AcceptCookiesModal;
