import * as React from 'react';
import { useEffect, useState } from 'react';
import { getApi } from '../utils/api';
import { ApiMyOrgs, ApiOrganization } from '../ApiTypes';
import LoadingScreen from '../components/LoadingScreen';
import VenueTiles from './venue/VenueTiles';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { getCurrentUserId, getCurrentUserIsAdmin } from '../store/selectors';

const Venues = () => {
    const [venues, setVenues] = useState(([] as unknown) as ApiOrganization[]);
    const [loading, setLoading] = useState(true);
    const currentUserId = useSelector(getCurrentUserId);
    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const [hostedRoomIds, setHostedRoomIds] = useState(([] as unknown) as Record<string, string[]>);

    useEffect(() => {
        getApi(`/user/me/orgs`).then(async (response) => {
            const res = (await response.json()) as ApiMyOrgs;
            const allVenues = [...res.myOwnedOrgs, ...res.myInvitedOrgs];
            const dedupedVenues = uniqBy(allVenues, 'id');

            setVenues(dedupedVenues);
            setHostedRoomIds(res.myHostedRooms);
            setLoading(false);
        });
    }, [isAdmin, currentUserId]);

    if (loading) return <LoadingScreen />;
    return (
        <VenueTiles
            venues={venues}
            hostedRoomIds={hostedRoomIds}
            title="My Venues"
            subtitle="Explore venues. Launch worlds. Meet friends."
            counterText="Venues Available"
        />
    );
};

export default Venues;
