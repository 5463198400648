import * as React from 'react';
import { useState } from 'react';
import { getApi } from '../utils/api';
import { ApiRoom } from '../ApiTypes';
import { RoomLink } from '../components/RoomBox';

type AdminInstanceEntry = {
    name: string;
    link: string;
    room?: ApiRoom;
};

const AdminInstanceEntry: React.FC<{ instance: AdminInstanceEntry }> = ({ instance }) => {
    const { room } = instance;
    return (
        <div
            style={{
                padding: '10px',
                border: '5px dotted rgba(164,164,164,0.42)',
            }}
        >
            <h3>
                <a href={instance.link}>{instance.name}</a>
            </h3>
            <br />
            Room: {room ? <RoomLink room={room}>{room.roomName}</RoomLink> : 'Not associated with a room'}
        </div>
    );
};

const AdminInstances: React.FC = () => {
    const [instances, setInstances] = useState([] as AdminInstanceEntry[]);

    React.useEffect(() => {
        getApi('/admin/instances').then(async (r) => setInstances(await r.json()));
    }, []);

    console.log(instances);

    return (
        <div>
            {instances
                .filter((i) => i.name[0] === 'x')
                .map((i) => (
                    <AdminInstanceEntry instance={i} key={i.name} />
                ))}
        </div>
    );
};

export default AdminInstances;
