import * as React from 'react';
import CallToActionFullWidth from './CallToActionFullWidth';
import citadel from '../images/secretCitadel_2.effectsResult_faces-p-1080.jpeg';

const FooterHero = () => {
    return (
        <CallToActionFullWidth
            image={citadel}
            link="https://www.urnowhere.com/become-a-host"
            text="Meet new humans. Build your community. "
            buttonText="Become A Host"
        />
    );
};

export default FooterHero;
