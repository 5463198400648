import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { auth } from '../firebase/Connection';
import { getApi, postApi } from '../utils/api';
import { ApiRoom } from '../ApiTypes';
import { Helmet } from 'react-helmet';
import VimeoBackground from '../components/VimeoBackground';
import isHostHealthy from '../utils/isHostHealthy';
import {
    ErrorModal,
    NotAuthorizedModal,
    RoomClosedModal,
    AtCapacityModal,
    ActiveConfirmModal,
} from '../components/LobbyModals';
import { verticalCentering } from '../mixins/positioning';

const TakeOver = styled.iframe`
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    border: unset;
`;

const RoomLobby = () => {
    const { roomId, subRoom } = useParams() as any;

    const [needsActiveConfirm, setNeedsActiveConfirm] = useState(false);
    const [hostname, setHostname] = useState('');
    const [gaveUp, setGaveUp] = useState(false);
    const [error, setError] = useState(false);
    const [healthy, setHealthy] = useState(false);
    const [idToken, setIdToken] = useState('');
    const [notAuthorizedReason, setNotAuthorizedReason] = useState('' as string);
    const [notAuthorizedMessage, setNotAuthorizedMessage] = useState('' as string);
    const [room, setRoom] = useState<ApiRoom | undefined>(undefined);

    // true if the backend needs to be spun up and we know it'll take ~1 min
    const [created, setCreated] = useState(false);
    const [recentlyCreated, setRecentlyCreated] = useState(false);

    useEffect(() => {
        const loadPage = async () => {
            console.log('loading ....');

            const idToken = await auth.currentUser?.getIdToken(/* forceRefresh */ true);
            if (!idToken) {
                setGaveUp(true);
                return;
            }
            setIdToken(idToken);

            const { allowed, message, reason, room } = await (await getApi(`/room/${roomId}/allowed`)).json();
            setRoom(room);

            if (!allowed) {
                // If the room is full but the person has access privileges, we still
                // want to show the room name as part of the error modal, so we need to
                // fetch the basic room data
                console.log(reason);
                if (reason === 'AT_CAPACITY') {
                    const roomData = await (await getApi(`/room/${roomId}`)).json();
                    setRoom(roomData.room);
                }
                setNotAuthorizedReason(reason!);
                setNotAuthorizedMessage(message!);
                return;
            }

            // we know that this person is an admin of the room b/c otherwise
            // they wouldn't be allowed when it's closed
            if (!room?.isActive) {
                setNeedsActiveConfirm(true);
            }

            // first call the room info thingy
            const response = await postApi(`/room/${roomId}/getOrCreateServer`).catch(() => setError(true));
            if (!response || !response.ok) {
                setError(true);
                return;
            }

            const { hostname, created } = await response.json();

            console.log({ hostname, created });
            setHostname(hostname);
            setCreated(created);

            if (
                room.hostStartTime?.seconds > new Date().getTime() / 1000 - 60 &&
                !hostname?.startsWith('tyf') &&
                !created
            ) {
                setRecentlyCreated(true);
            }

            if (room?.hostname?.startsWith('tyf')) {
                setHealthy(true);
            } else {
                // now try in a loop to see if it's up
                // https://x8fef998f-c6da-46ac-aaa8-e5047fa9c42f.urnowhere.com/health
                const MaxTries = 50;

                isHostHealthy(hostname, MaxTries)
                    .then(() => setHealthy(true))
                    .catch((e) => {
                        console.log(e);
                        setGaveUp(true);
                    });
            }
        };
        loadPage();
    }, [roomId]);

    if (error) return <ErrorModal />;
    if (notAuthorizedReason || notAuthorizedMessage) {
        if (notAuthorizedReason === 'AT_CAPACITY') {
            return <AtCapacityModal room={room} />;
        } else if (notAuthorizedReason === 'NOT_ACTIVE') {
            return <RoomClosedModal />;
        } else {
            return <NotAuthorizedModal notAuthorizedMessage={notAuthorizedMessage} />;
        }
    }
    if (needsActiveConfirm)
        return <ActiveConfirmModal roomId={roomId} enterRoom={() => setNeedsActiveConfirm(false)} />;

    if (healthy && idToken && !needsActiveConfirm) {
        let url = `https://${hostname}/`;
        if (subRoom) {
            url += subRoom;
        }

        url += `?idToken=${idToken}&roomId=${roomId}`;

        const params = new URLSearchParams(window.location.search);
        if (params.get('open')) (window.location as any) = url;
        return <TakeOver allow="camera;microphone;fullscreen;gyroscope;" src={url} />;
    }

    return (
        <>
            <Helmet>
                <title>{`NOWHERE | ${room?.roomName || ''} | Lobby`}</title>
            </Helmet>

            <VimeoBackground vimeoId="438728376" />
            <VideoOverlay>
                <MessageDiv>
                    <h3>{gaveUp ? 'World Failed to Load. Try again later.' : 'Loading World'}</h3>

                    {created && <h3>Hold tight. This will take a minute or two.</h3>}
                    {recentlyCreated && <h3>Hold tight. This might take a minute or two.</h3>}
                </MessageDiv>
            </VideoOverlay>
        </>
    );
};

const MessageDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const VideoOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${verticalCentering}

    h3 {
        text-shadow: 3px 3px 18px rgba(0, 0, 0, 0.9);
    }
`;

export default RoomLobby;
