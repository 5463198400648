import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import VenueForm from './venue/VenueForm';
import CenteredSingleColumnLayout from '../components/CenteredSingleColumnLayout';
import { ApiOrganization, ApiOrganizationEditableMetadata } from '../ApiTypes';
import { getApi, postApi } from '../utils/api';
import { LoadingImage } from '../components/LoadingComponent';
import { useState } from 'react';
import LoadingScreen from '../components/LoadingScreen';

const EditOrg: React.FC = () => {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [error, setError] = React.useState('');
    const { orgId } = useParams() as any;
    const [org, setOrg] = React.useState((undefined as unknown) as ApiOrganization);
    React.useEffect(() => {
        getApi(`/org/${orgId}`).then(async (response) => {
            setOrg((await response.json()).org);
            setLoading(false);
        });
    }, [orgId]);

    const updateOrg = (orgUpdate: ApiOrganizationEditableMetadata) => {
        postApi(`/org/${orgId}/update`, orgUpdate)
            .then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    setError(json.msessage || 'Something went wrong');
                }
                history.push(`/org/${orgId}/admin`);
            })
            .catch(() => setError('Something went very wrong'));
    };

    if (loading) return <LoadingScreen />;
    return (
        <>
            <CenteredSingleColumnLayout title="Update your Venue" subtitle="Let people know what you're all about">
                {!org && <LoadingImage />}
                {org && <VenueForm onSubmit={updateOrg} initialOrgData={org} edit={true} />}
                {error && <h2>Error</h2>}
            </CenteredSingleColumnLayout>
        </>
    );
};

export default EditOrg;
