import * as React from 'react';
import styled from 'styled-components';
import RoomBox from '../../components/RoomBox';
import { Link } from 'react-router-dom';
import { ApiRoom } from '../../ApiTypes';

const Container = styled.div`
    width: 300px;
    border-radius: 20px;
    box-shadow: inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: #030217;
`;

interface RoomListProps {
    rooms: ApiRoom[];
    venueTitle?: string;
}

// Sort rooms into open/closed first, and then
// alphabetically
const sortRooms = (a: ApiRoom, b: ApiRoom) => {
    if (a.isActive !== b.isActive) {
        return a.isActive ? -1 : 1;
    }

    if (a.roomName.toLowerCase() > b.roomName.toLowerCase()) {
        return 1;
    }
    if (a.roomName.toLowerCase() < b.roomName.toLowerCase()) {
        return -1;
    }

    return 0;
};

const RoomList: React.FunctionComponent<RoomListProps> = ({ venueTitle, rooms }) => {
    const roomsSorted = React.useMemo(() => [...rooms].sort(sortRooms), [rooms]);

    return (
        <Container>
            {roomsSorted &&
                roomsSorted.map((room) => (
                    <NoUnderlineLink key={room.id} to={`/room/${room.id}`}>
                        <RoomBox venueTitle={venueTitle} room={room} key={room.id} />
                    </NoUnderlineLink>
                ))}
        </Container>
    );
};

const NoUnderlineLink = styled(Link)`
    &:hover {
        text-decoration: none;
    }
`;

export default RoomList;
