import { combineReducers } from 'redux';
import { FirebaseReducer, firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { UserProfile } from '../DbTypes';
import { systemReducer } from './system/reducers';
import { SystemState } from './system/types';

export interface RootState {
    firebase: FirebaseReducer.Reducer<UserProfile, UserProfile>;
    firestore: any;
    system: SystemState;
}
const reducers = combineReducers<RootState>({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    system: systemReducer,
});

export default reducers;
