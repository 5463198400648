import * as React from 'react';
import { useState } from 'react';
import { ApiOrganization } from '../../../../functions/src/ApiTypes';
import { GreenDivider } from '../../components/Divider';
import { useHistory, useLocation } from 'react-router-dom';
import CountWidget from '../../components/CountWidget';
import noVenuesImage from '../../images/nwCorp_v035.jpg';
import { LargeButton, StickyButton } from '../../components/Button';
import EmptyListCard from '../../components/EmptyListCard';
import VenueCard from './VenueCard';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getCurrentUserIsAdmin, getCurrentUserIsHost } from '../../store/selectors';
import FooterHero from '../../components/FooterHero';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';
import { Select } from '../../components/Select';
import _ from 'lodash';

interface VenueTilesProps {
    venues: ApiOrganization[];
    title?: string;
    subtitle?: string;
    counterText?: string;
    hostedRoomIds: Record<string, string[]>;
}

const VenueTiles: React.FC<VenueTilesProps> = ({ venues, title, subtitle, hostedRoomIds, counterText = 'Venues' }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const isHost = useSelector(getCurrentUserIsHost);
    const canCreateVenues = isAdmin || isHost;
    return (
        <>
            {title && (
                <>
                    <TitleContainer>
                        <Title>{title}</Title>
                        <div
                            style={{
                                fontSize: '16px',
                                color: 'var(--off-white)',
                                marginBottom: '15px',
                                paddingLeft: '2px',
                            }}
                        >
                            {LineBreaksToBrTags(subtitle)}
                        </div>
                        <XlGreenDivider />
                        <br />
                        <br />
                        {canCreateVenues && pathname.startsWith('/owned') && (
                            <StickyButton onClick={() => history.push('/org/create')} disabled={false}>
                                Create a new venue
                            </StickyButton>
                        )}
                    </TitleContainer>
                </>
            )}
            <VenueList counterText={counterText} venues={venues} hostedRoomIds={hostedRoomIds} />
            <FooterHero />
        </>
    );
};

interface VenueListProps {
    venues: ApiOrganization[];
    admin?: boolean;
    counterText: string;
    hostedRoomIds: Record<string, string[]>;
}

const VenueList: React.FC<VenueListProps> = ({ venues, counterText, hostedRoomIds }) => {
    const sortOptions = [
        { name: 'Sort By: Open Worlds', sortBy: 'numActiveRooms' },
        { name: 'Sort By: Venue Name', sortBy: 'title' },
        { name: 'Sort By: Host Name', sortBy: 'name' },
        { name: 'Sort By: Active Users', sortBy: 'orgAttendance' },
    ];
    const capitalize = (string?: string) => (!string ? '' : string.charAt(0).toUpperCase() + string.slice(1));
    const capitalizedVenues = venues.map((venue) => ({
        ...venue,
        title: capitalize(venue?.title),
        name: capitalize(venue?.name),
    }));
    const sorts = {
        title: _.orderBy(capitalizedVenues, ['title'], ['asc']),
        name: _.orderBy(capitalizedVenues, ['name'], ['asc']),
        numActiveRooms: _.orderBy(venues, ['numActiveRooms', 'title'], ['desc', 'asc']),
        orgAttendance: _.orderBy(venues, ['orgAttendance', 'title'], ['desc', 'asc']),
    };
    const [sort, setSort] = useState('numActiveRooms');
    const sortedVenues = _.get(sorts, sort);

    return (
        <VenueListContainer>
            <Select style={{ marginLeft: '30px' }} value={sort} onChange={(e) => setSort(e.target.value)}>
                {sortOptions.map((option) => (
                    <option key={option.sortBy} value={option.sortBy}>
                        {option.name}
                    </option>
                ))}
            </Select>
            <CountWidget items={venues} label={counterText} />
            {sortedVenues?.length === 0 ? (
                <EmptyListCard background={noVenuesImage}>
                    Were you supposed to be part of an event? <br />
                    Try clicking the invite link again or contact our support team at support@urnowhere.com
                    <a href="mailto:support@urnowhere.com">
                        <LargeButton onClick={() => null}>Contact Support</LargeButton>
                    </a>
                </EmptyListCard>
            ) : (
                <CardContainer fullWidth={venues.length === 1}>
                    {sortedVenues?.map((venue: any) => (
                        <VenueCard venue={venue} key={venue.id} hostedRoomIds={hostedRoomIds[venue.id]} />
                    ))}
                </CardContainer>
            )}
        </VenueListContainer>
    );
};

const CardContainer = styled.section<{ fullWidth: boolean }>`
    width: 100%;
    max-width: 1200px;
    margin: 20px auto 120px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
    row-gap: 30px;
    @media (min-width: 1150px) {
        ${({ fullWidth }) => (fullWidth ? '' : 'grid-template-columns: 1fr 1fr')};
    }
`;

const TitleContainer = styled.div`
    display: inline-block;
    margin: 30px 0 120px 60px;
    @media (min-width: 768px) {
        margin: 60px 0 20px 60px;
    }
`;

const Title = styled.h1`
    padding: 15px 0;
    font-size: 60px;
`;

const VenueListContainer = styled.div`
    position: relative;
    width: 85%;
    max-width: 1200px;
    margin: 0 auto;
`;

const XlGreenDivider = styled(GreenDivider)`
    width: 120%;
    margin-left: -60px;
`;
export default VenueTiles;
