import * as React from 'react';
import ReactModal from 'react-modal';

type Props = {
    isOpen: boolean;
};

export const LowerModal: React.FC<Props> = ({ children, isOpen }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            ariaHideApp={false}
            style={{
                overlay: { backgroundColor: 'rgba(0, 0, 0, .5)', zIndex: 50 },
                content: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    background: 'var(--dark-indigo)',
                    position: 'absolute',
                    top: 'auto',
                    right: 0,
                    bottom: 0,
                    left: 0,
                    height: '300px',
                    width: '100%',
                    boxShadow: '1px 1px 15px 0 rgba(0, 0, 0, 0.5)',
                },
            }}
            shouldCloseOnEsc={false}
        >
            {children}
        </ReactModal>
    );
};

export default LowerModal;
