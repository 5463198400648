// cribed entirely from https://github.com/daily-co/mediasoup-sandbox/blob/master/single-page/client.js
import * as mediasoup from 'mediasoup-client';
import debugModule from 'debug';
import firebase from 'firebase';
// import { Transport } from 'mediasoup-client/lib/types';

const log = debugModule('demo-app');
// const warn = debugModule('demo-app:WARN');
const err = debugModule('demo-app:ERROR');

// const ROOM_ID = 'bar-lobby';

//
// simple uuid helper function
//

function uuidv4() {
    return '111-111-1111'.replace(/[018]/g, () => (crypto.getRandomValues(new Uint8Array(1))[0] & 15).toString(16));
}

export default class Screenshare {
    myPeerId: string = uuidv4();
    joined = false;
    device: mediasoup.Device | undefined;
    pollingInterval = 0;
    sendTransport: mediasoup.types.Transport | undefined;
    recvTransport: mediasoup.types.Transport | undefined;
    localScreen: MediaStream | undefined;
    screenVideoProducer: mediasoup.types.Producer | undefined;
    screenAudioProducer: mediasoup.types.Producer | undefined;
    hostname: string;
    streamName: string;
    roomId: string;

    constructor({ hostname, streamName, roomId }: { hostname: string; streamName: string; roomId: string }) {
        this.hostname = hostname;
        this.streamName = streamName;
        this.roomId = roomId;

        console.log(`starting up ... my peerId is ${this.myPeerId}`);
        try {
            this.device = new mediasoup.Device();
        } catch (e) {
            if (e.name === 'UnsupportedError') {
                console.error('browser not supported for video calls');
                return;
            } else {
                console.error(e);
            }
        }

        // use sendBeacon to tell the server we're disconnecting when
        // the page unloads
        // window.addEventListener('unload', () => this.sig('leave', {}));
    }

    //
    // our "signaling" function -- just an http fetch
    //

    async sigHelper(endpoint: string, data?: any) {
        const idToken = await firebase.auth().currentUser?.getIdToken();

        const url = `https://${this.hostname}:4443${endpoint}?roomId=${this.roomId}`;
        // const url = `https://localhost.urnowhere.com:4443${endpoint}?roomId=${this.roomId}`;

        try {
            const headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${idToken}`,
                },
                body = JSON.stringify({ ...data, roomId: this.roomId, peerId: this.myPeerId });

            // if (beacon) {
            //     navigator.sendBeacon('/signaling/' + endpoint, body);
            //     return null;
            // }

            const response = await fetch(url, { method: 'POST', body, headers });
            return await response.json();
        } catch (e) {
            console.error(e);
            return { error: e };
        }
    }

    async sig(endpoint: string, data?: any) {
        return this.sigHelper(`/rooms/${this.roomId}/broadcasters/${this.myPeerId}${endpoint}`, {
            ...data,
            roomId: this.roomId,
        });
    }

    emit(s: string) {
        console.log('emitting', s);
    }

    async joinRoom() {
        if (this.joined) {
            return;
        }

        log('join room');
        // $('#join-control').style.display = 'none';

        try {
            // signal that we're a new peer and initialize our
            // mediasoup-client device, if this is our first time connecting
            const routerRtpCapabilities = await this.sigHelper(`/rooms/${this.roomId}/rtpCapabilities`, {});
            if (!this.device?.loaded) {
                await this.device!.load({ routerRtpCapabilities });
            }
            this.joined = true;
            this.emit('joined');
        } catch (e) {
            console.error(e);
            return;
        }

        // super-simple signaling: let's poll at 1-second intervals
        // this.pollingInterval = setInterval(async () => {
        // let { error } = await pollAndUpdate();
        // if (error) {
        //     clearInterval(pollingInterval);
        //     err(error);
        // }
        // }, 1000);
    }

    async leaveRoom() {
        if (!this.joined) {
            return;
        }

        log('leave room');

        // stop polling
        clearInterval(this.pollingInterval);

        // close everything on the server-side (transports, producers, consumers)
        const { error } = await this.sig('leave');
        if (error) {
            err(error);
        }

        // closing the transports closes all producers and consumers. we
        // don't need to do anything beyond closing the transports, except
        // to set all our local variables to their initial states
        try {
            this.recvTransport && (await this.recvTransport.close());
            this.sendTransport && (await this.sendTransport.close());
        } catch (e) {
            console.error(e);
        }
        // this.recvTransport = null;
        // this.sendTransport = null;
        // this.camVideoProducer = null;
        // camAudioProducer = null;
        // screenVideoProducer = null;
        // screenAudioProducer = null;
        // localCam = null;
        // localScreen = null;
        // lastPollSyncData = {};
        // consumers = [];
        // joined = false;
    }

    // utility function to create a transport and hook up signaling logic
    // appropriate to the transport's direction
    //
    async createTransport(direction: string) {
        log(`create ${direction} transport`);

        // ask the server to create a server-side transport object and send
        // us back the info we need to create a client-side transport
        let transport: mediasoup.types.Transport;
        const transportOptions = await this.sig('/transports', {
            type: 'webrtc',
            comedia: true,
            rtcpMux: false,
        });
        console.log('transport options', transportOptions);

        if (direction === 'recv') {
            transport = await this.device!.createRecvTransport(transportOptions);
        } else if (direction === 'send') {
            transport = await this.device!.createSendTransport(transportOptions);
        } else {
            throw new Error(`bad transport 'direction': ${direction}`);
        }

        // mediasoup-client will emit a connect event when media needs to
        // start flowing for the first time. send dtlsParameters to the
        // server, then call callback() on success or errback() on failure.
        transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
            log('transport connect event', direction);
            try {
                const resp = await this.sig(`/transports/${transportOptions.id}/connect`, {
                    dtlsParameters,
                });
                console.log(resp);
            } catch (error) {
                err('error connecting transport', direction, error);
                errback();
                return;
            }
            callback();
        });

        if (direction === 'send') {
            // sending transports will emit a produce event when a new track
            // needs to be set up to start sending. the producer's appData is
            // passed as a parameter
            transport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
                log('transport produce event', appData.mediaTag);
                // we may want to start out paused (if the checkboxes in the ui
                // aren't checked, for each media type. not very clean code, here
                // but, you know, this isn't a real application.)
                // let paused = false;
                // if (appData.mediaTag === 'cam-video') {
                //   paused = getCamPausedState();
                // } else if (appData.mediaTag === 'cam-audio') {
                //   paused = getMicPausedState();
                // }
                // tell the server what it needs to know from us in order to set
                // up a server-side producer object, and get back a
                // producer.id. call callback() on success or errback() on
                // failure.
                const { error, id } = await this.sig(`/transports/${transportOptions.id}/producers`, {
                    kind,
                    rtpParameters,
                    paused: false,
                    appData,
                });
                if (error) {
                    err('error setting up server-side producer', error);
                    errback();
                    return;
                }
                callback({ id });
            });
        }

        // for this simple demo, any time a transport transitions to closed,
        // failed, or disconnected, leave the room and reset
        //
        transport.on('connectionstatechange', async (state) => {
            log(`transport ${transport.id} connectionstatechange ${state}`);
            // for this simple sample code, assume that transports being
            // closed is an error (we never close these transports except when
            // we leave the room)
            if (state === 'closed' || state === 'failed' || state === 'disconnected') {
                log('transport closed ... leaving the room and resetting');
                this.leaveRoom();
            }
        });

        return transport;
    }

    async startScreenshare(onStop: () => void): Promise<boolean> {
        console.log('start screen share, creating broadcaster');

        await this.sigHelper(`/rooms`, {
            roomId: this.roomId,
        });

        await this.sigHelper(`/rooms/${this.roomId}/broadcasters`, {
            id: this.myPeerId,
            displayName: this.streamName,
            device: { name: 'web-screenshare' },
        });

        // make sure we've joined the room and that we have a sending
        // transport
        await this.joinRoom();
        if (!this.sendTransport) {
            this.sendTransport = await this.createTransport('send');
        }

        // get a screen share track
        this.localScreen = (await (navigator.mediaDevices as any)
            .getDisplayMedia({
                video: true,
                audio: true,
            })
            .catch((e: Error) => {
                console.error(e);
                if (e.message.includes('Permission denied by system')) {
                    window.alert(
                        `We got a permision denied error. Sorry. 
                
If you are on a mac and tried to share fullscreen, please go to 

   System Preferences -> Security and Privacy -> Privacy -> Screen Recording, 

and make sure **Google Chrome** is checked`,
                    );
                } else {
                    window.alert(`Got an error from screensharing, sorry, it's not going to work\n\n` + e);
                }
            })) as MediaStream;

        if (!this.localScreen) {
            return false;
        }

        // create a producer for video
        this.screenVideoProducer = await this.sendTransport.produce({
            track: this.localScreen.getVideoTracks()[0],
            encodings: undefined, // this.screenshareEncodings(),
            appData: { mediaTag: 'screen-video' },
        });

        // create a producer for audio, if we have it
        if (this.localScreen.getAudioTracks().length) {
            this.screenAudioProducer = await this.sendTransport.produce({
                track: this.localScreen.getAudioTracks()[0],
                appData: { mediaTag: 'screen-audio' },
            });
        }

        if (!this.screenVideoProducer) {
            return false;
        }
        // handler for screen share stopped event (triggered by the
        // browser's built-in screen sharing ui)

        const stopSharingHandler = async () => {
            onStop();
            log('screen share stopped');
            console.log('closing send transport');
            this.sendTransport?.close();
            const error = '';
            try {
                await this.screenVideoProducer!.pause();
                // const { error } = await this.sig('/close-producer', { producerId: this.screenVideoProducer!.id });
                this.sig('/delete');
                await this.screenVideoProducer!.close();
                this.screenVideoProducer = undefined;
                if (this.screenAudioProducer) {
                    this.sig('/delete');
                    // { error } = await this.sig('/close-producer', { producerId: this.screenAudioProducer.id });
                    await this.screenAudioProducer.close();
                    this.screenAudioProducer = undefined;
                }
                if (error) {
                    err(error);
                }
            } catch (e) {
                console.error(e);
            }
        };

        window.addEventListener('beforeunload', stopSharingHandler);
        this.screenVideoProducer!.track!.onended = stopSharingHandler;
        return true;
    }
}
