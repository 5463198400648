import * as React from 'react';
import styled from 'styled-components';

const Toggle: React.FC<{
    value: boolean;
    onChange: (v: boolean) => void;
}> = ({ value, onChange }) => {
    return (
        <ToggleContainer onClick={() => onChange(!value)} $on={value}>
            <Oval $on={value} />
        </ToggleContainer>
    );
};

export default Toggle;

const ToggleContainer = styled.div<{ $on: boolean }>`
    width: 60px;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    background-color: ${({ $on }) => ($on ? '#3e3adf' : '#9a0a0a')};
`;

const Oval = styled.div<{ $on: boolean }>`
    width: 22px;
    height: 22px;
    background-color: white;
    border-radius: 20px;
    transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.35);
    margin: ${({ $on }) => (!$on ? '4px 0 0 7px' : '4px 0 0 31px')};
`;
