import styled from 'styled-components';

const Divider = styled.div`
    width: 100%;
    height: 1px;
    border-top: solid 2px white;
    margin: 15px 0;
`;

export const HalfDivider = styled(Divider)`
  width: 50%;
  margin: 15px 8px;
`;

export const GreenDivider = styled(Divider)`
    border: solid 1px #bee639;
`;

export default Divider;
