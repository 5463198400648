import * as React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUserEmail } from '../store/selectors';
import { LargeButton } from '../components/Button';
import { doSendEmailVerificationToCurrentUser } from '../firebase/Connection';
import Message from '../components/Message';
import { useState } from 'react';

const VerifyEmail = () => {
    const email = useSelector(getCurrentUserEmail);
    const [emailSent, setEmailSent] = useState(false);

    async function sendEmail() {
        doSendEmailVerificationToCurrentUser();
        setEmailSent(true);
    }

    return (
        <Message
            title="One last step"
            body={`Please click the verification link in your email at ${email} to sign in!`}
        >
            {emailSent ? (
                <LargeButton disabled>Email sent!</LargeButton>
            ) : (
                <LargeButton onClick={sendEmail}>Resend Verification</LargeButton>
            )}
        </Message>
    );
};

export default VerifyEmail;
