import * as React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUserIsAdmin } from '../store/selectors';
import FourOhFour from './404';

interface Props {
    children: React.ComponentElement<any, any>;
    path: string;
    exact?: boolean;
}

// This is a normal route, except it bounces you to the home path if you're not logged in

const AdminAuthRoute: React.FC<Props> = ({ children }) => {
    const isAdmin = useSelector(getCurrentUserIsAdmin);

    if (isAdmin) {
        return children;
    } else {
        return <FourOhFour />;
    }
};

export default AdminAuthRoute;
