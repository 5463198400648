import * as React from 'react';
import MediaQuery from 'react-responsive';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import nowhere from '../../images/NOWHERE.svg';
import { isEmpty, useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import NavDropdown from './Dropdown';
import { getCurrentUserIsAdmin, getCurrentUserIsHost } from '../../store/selectors';
import { useEffect, useState } from 'react';
import { getApi } from '../../utils/api';
import { ApiMyOrgs } from '../../ApiTypes';
import MobileDropdown from './MobileDropdown';
import ReactGA from 'react-ga';

const Header = () => {
    const auth = useSelector(({ firebase: { auth } }: RootState) => auth);
    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const isHost = useSelector(getCurrentUserIsHost);
    const [isVenueOwner, setIsVenueOwner] = useState(false);
    const { pathname } = useLocation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hasHostedRooms, setHasHostedRooms] = useState(false);

    const history = useHistory();
    const firebase = useFirebase();
    const handleSignOut = async () => {
        await firebase.logout().then(() => {
            history.push('/');
        });
    };

    useEffect(() => {
        if (isEmpty(auth)) return;
        getApi(`/user/me/orgs`).then(async (response) => {
            const res = (await response.json()) as ApiMyOrgs;
            res.myOwnedOrgs.length > 0 ? setIsVenueOwner(true) : setIsVenueOwner(false);
            if (Object.keys(res.myHostedRooms).length > 0) setHasHostedRooms(true);
        });
    }, [pathname, auth]);

    React.useEffect(() => {
        ReactGA.set({ page: pathname });
        ReactGA.pageview(pathname);
    }, [pathname]);

    const canBeOwner = isAdmin || isHost || isVenueOwner || hasHostedRooms;

    const isOwnerPage =
        pathname.startsWith('/owned') ||
        pathname.startsWith('/room') ||
        pathname.endsWith('admin') ||
        pathname.endsWith('create');
    const isVenuePage = !isOwnerPage && (pathname.startsWith('/venues') || pathname.startsWith('/org'));
    const isCommunityPage = pathname.startsWith('/community');

    if (
        pathname.startsWith('/log-in') ||
        pathname.startsWith('/invite') ||
        pathname.startsWith('/ownerInvite') ||
        pathname.startsWith('/sign-up')
    )
        return (
            <a href="https://www.urnowhere.com/">
                <img
                    src={nowhere}
                    alt="Nowhere Logo"
                    style={{ height: '40px', top: '30px', left: '60px', position: 'absolute', zIndex: 99 }}
                />
            </a>
        );
    return (
        <>
            <Container>
                <NavLinkContainer>
                    <a href="https://www.urnowhere.com/" className="nav-logo-link">
                        <img src={nowhere} alt="Nowhere Logo" style={{ height: '100%' }} />
                    </a>
                    <MediaQuery query="(min-width: 768px)">
                        {!isEmpty(auth) && (
                            <>
                                {canBeOwner && (
                                    <HeaderLink to={'/owned'} $highlighted={isOwnerPage}>
                                        Host
                                    </HeaderLink>
                                )}
                                <HeaderLink to="/venues" $highlighted={isVenuePage}>
                                    Venues
                                </HeaderLink>
                                <HeaderLink to="/community" $highlighted={isCommunityPage}>
                                    Community
                                </HeaderLink>
                            </>
                        )}
                    </MediaQuery>
                </NavLinkContainer>
                <NavLinkContainer>
                    {isEmpty(auth) ? (
                        <>
                            <HeaderLink style={{ marginRight: '40px' }} to="/log-in">
                                Log in
                            </HeaderLink>
                        </>
                    ) : (
                        <>
                            <MediaQuery query="(min-width: 768px)">
                                <NavDropdown
                                    dropdownOpen={dropdownOpen}
                                    setDropdownOpen={setDropdownOpen}
                                    handleSignOut={handleSignOut}
                                />
                            </MediaQuery>
                            <MediaQuery query="(max-width: 768px)">
                                <MobileDropdown
                                    canBeOwner={canBeOwner}
                                    dropdownOpen={dropdownOpen}
                                    setDropdownOpen={setDropdownOpen}
                                    handleSignOut={handleSignOut}
                                />
                            </MediaQuery>
                        </>
                    )}
                </NavLinkContainer>
            </Container>
            <SpaceKeeper />
        </>
    );
};

const Container = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 60;
    width: 100vw;
    margin-bottom: -60px;
    padding: 20px;
    background-color: var(--midnight);
    height: 58px;
`;

const SpaceKeeper = styled.div`
    height: 58px;
`;

export const HeaderLink = styled(Link)<{ $highlighted?: boolean }>`
    // note that prefixing a styled component attribute with a $ dollar sign
    // prevents that prop from being passed to untyped children
    // and avoids weird errors; for instance, here we are keeping the
    // anchor tag child of the Link component from getting a boolean attribute

    // see https://styled-components.com/docs/api#transient-props
    height: 58px;
    margin: 0 0 0 60px;
    font-family: all-round-gothic, sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    display: flex;
    align-items: center;
    background-color: var(--midnight);
    border-top: 3px solid var(--midnight);
    color: ${({ $highlighted }) => ($highlighted ? 'var(--tennis-green) !important' : 'var(--white)')};
    border-bottom: 3px solid ${({ $highlighted }) => ($highlighted ? 'var(--tennis-green)' : 'var(--midnight)')};
    &:hover {
        text-decoration: none;
        border-bottom: 3px solid var(--tennis-green);
    }
`;

const NavLinkContainer = styled.div`
    display: flex;
    align-items: center;
`;

export default Header;
