import * as React from 'react';
import nonaLarge from '../../images/nona-large_1.svg';
import styled from 'styled-components';
import { GreenDivider } from '../../components/Divider';
import { ThinTitle } from '../../components/Typography';
import { Helmet } from 'react-helmet';
import { ApiOrganization } from '../../ApiTypes';
import { VenueUtil } from '../../utils/VenueUtil';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';

type OrgPageHeaderProps = { organization: ApiOrganization };

const VenuePageHeader: React.FC<OrgPageHeaderProps> = ({ organization }) => {
    const title = LineBreaksToBrTags(organization?.title);

    return (
        <>
            <Helmet>
                <title>{`NOWHERE | ${organization.name}`}</title>
            </Helmet>
            <TopBlock>
                <TitleBlock>
                    <Nonagon src={nonaLarge} />
                    <div style={{ padding: '60px' }}>
                        <ThinTitle>{title}</ThinTitle>
                        <SubtitleContainer>
                            <GreenDivider />
                            <span style={{ marginLeft: '60px' }}>Host: {organization.name}</span>
                        </SubtitleContainer>
                    </div>
                </TitleBlock>
                <Hero src={VenueUtil.getImage(organization)} alt="" />
            </TopBlock>
        </>
    );
};

const Nonagon = styled.img`
    stroke: greenyellow;
    position: absolute;
    top: 0;
    left: 0;
`;

const Hero = styled.img`
    width: 100%;
    max-width: 840px;
    min-width: 50%;
    height: 70vh;
    max-height: 600px;
    object-fit: cover;
    flex: 6;
    border-radius: 0 0 30px 0;
`;

const TitleBlock = styled.section`
    flex: 4;
    position: relative;
    margin: 30px 0 30px 60px;
`;

const SubtitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 0;
    font-family: all-round-gothic, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.17px;
`;
const TopBlock = styled.section`
    display: flex;
    flex-direction: column;
    margin: 0;
    @media (min-width: 900px) {
        flex-direction: row-reverse;
    }
`;
export default VenuePageHeader;
