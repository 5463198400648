import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import VenueForm from './venue/VenueForm';
import CenteredSingleColumnLayout from '../components/CenteredSingleColumnLayout';
import { postApi } from '../utils/api';
import { ApiOrganizationEditableMetadata } from '../ApiTypes';
import { getCurrentUserCanCreateRooms } from '../store/selectors';
import { useSelector } from 'react-redux';
import Message from '../components/Message';
import { MediumButton } from '../components/Button';

const CreateOrg: React.FC = () => {
    const history = useHistory();
    const [error, setError] = React.useState('');

    const handleCreateOrganization = (organizationData: ApiOrganizationEditableMetadata) => {
        console.log('organizationData', organizationData);
        postApi('/org/create', organizationData)
            .then(async (r) => {
                const json = await r.json();
                if (!r.ok) {
                    setError(json.message || 'Something went wrong');
                }
                console.log(json);
                if (json.org?.id) {
                    history.push(`/org/${json.org?.id}/admin`);
                }
            })
            .catch(() => setError('Something went very wrong'));
    };
    const currentUserCanCreate = useSelector(getCurrentUserCanCreateRooms);

    if (!currentUserCanCreate)
        return (
            <Message title="Hmm, doesn’t look like you have permission to create a venue">
                <Link to="/venues" style={{ marginRight: '30px' }}>
                    <MediumButton>Back to Venues</MediumButton>
                </Link>
                <a href="https://www.urnowhere.com/become-a-host" target="_blank" rel="noopener noreferrer">
                    <MediumButton>Request Access</MediumButton>
                </a>
            </Message>
        );

    return (
        <>
            <CenteredSingleColumnLayout
                title="Create Your Venue"
                subtitle="The landing page for every World you create. Let your participants know a little about you."
            >
                <VenueForm onSubmit={handleCreateOrganization} />
                {error && <h2>{error}</h2>}
            </CenteredSingleColumnLayout>
        </>
    );
};

export default CreateOrg;
