import * as React from 'react';
import styled from 'styled-components';
import InviteByEmailForm from '../../components/InviteByEmailForm';
import EmailIcon from '../../images/circle-avatar-info.svg';
import LinkIcon from '../../images/room-link-icon.svg';
import HostLinkIcon from '../../images/host-link-icon.svg';
import InviteByLinkForm from '../../components/InviteByLinkForm';

interface Props {
    inviteCode: string;
    inviteOwnerCode: string;
}

const InviteToVenue: React.FC<Props> = ({ inviteCode, inviteOwnerCode }) => {
    const inviteUserLink = `${window.location.protocol}//${window.location.host}/invite/${inviteCode}`;
    const inviteOwnerLink = `${window.location.protocol}//${window.location.host}/ownerInvite/${inviteOwnerCode}`;

    return (
        <Container>
            <InviteByLinkForm
                icon={LinkIcon}
                link={inviteUserLink}
                header="Invite Link"
                tooltip="Anyone with link can view venue page and join any world"
            />
            <InviteByLinkForm
                link={inviteOwnerLink}
                icon={HostLinkIcon}
                header="Invite Owner"
                tooltip="Owners can control any world in your venue. Only share this link with people you want to control this venue."
            />
            <InviteByEmailForm
                header="Invite guests by email"
                icon={EmailIcon}
                placeholder="Enter email address to invite"
                tooltip="Clicking add will send and invitation to access this world"
            />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    margin-right: 30px;
    max-width: 700px;
`;

export default InviteToVenue;
