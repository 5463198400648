import styled from 'styled-components';

const InputLabel = styled.label`
    font-family: all-round-gothic, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.17px;
    margin-bottom: 11px;
    text-transform: uppercase;
`;

export default InputLabel;
