import * as React from 'react';
import Message from '../components/Message';
import FullScreenContainer from '../components/FullScreenContainer';
import ResetPasswordConfirm from '../components/ResetPasswordConfirm';
import VerifyEmailConfirm from '../components/VerifyEmailConfirm';
import SignInFromEmail from '../components/SignInFromEmail';

const FirebaseEmailHandler = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('oobCode') as string;
    const mode = urlParams.get('mode');
    const continueUrl = urlParams.get('continueUrl');

    if (mode === 'resetPassword') {
        return <ResetPasswordConfirm code={code} />;
    }

    if (mode === 'verifyEmail') {
        return <VerifyEmailConfirm code={code} />;
    }

    if (mode === 'signIn') {
        return <SignInFromEmail continueUrl={continueUrl || '/venues'} />;
    }

    return (
        <FullScreenContainer>
            <Message
                title="Whoops! Something went wrong"
                body="If you're seeing this message by mistake, please contact a Nowhere admin and let them know"
            />
        </FullScreenContainer>
    );
};

export default FirebaseEmailHandler;
