import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { Title } from '../../components/Typography';
import { useHistory } from 'react-router-dom';
import { postApi } from '../../utils/api';
import Toggle from '../../components/Toggle';
import { ApiOrganization, ApiRoomDetails } from '../../ApiTypes';
import TimeUtils from '../../utils/TimeUtils';
import { SmallButton } from '../../components/Button';
import eye from '../../images/eye.svg';
import edit from '../../images/edit-icon.svg';
import { Modal } from '../../components/Modal';
import Message from '../../components/Message';
import LineBreaksToBrTags from '../../utils/LineBreaksToBrTags';

type RoomAdminHeaderProps = {
    roomDetails: ApiRoomDetails;
    setRoomIsActive: (isActive: boolean) => void;
    setIsEditingSchedule: any;
    org: ApiOrganization;
};

const RoomAdminHeader: React.FC<RoomAdminHeaderProps> = ({ setRoomIsActive, roomDetails, org }) => {
    const history = useHistory();

    const roomId = roomDetails.room.id;

    const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);

    const updateRoomActiveStatus = (newIsActive: boolean, kickUsers?: boolean) => {
        if (newIsActive === false) {
            postApi(`/room/${roomId}/close`, { kickUsers });
        } else {
            postApi(`/room/${roomId}/update`, {
                isActive: newIsActive,
            });
        }
        setRoomIsActive(newIsActive);
    };
    const toggleRoomActive = (newIsActive: boolean) => {
        if (newIsActive) {
            updateRoomActiveStatus(true);
        } else {
            setShowConfirmCloseModal(true);
        }
    };

    const { roomName, description, attendance, environmentName, capacity, isActive, isOpen, id } = roomDetails.room;
    const roomNameWithLineBreaks = LineBreaksToBrTags(roomName);

    const status = isOpen ? 'Live' : 'Closed';

    const totalTime = _.sum(roomDetails.invitations.map((i) => i.stats?.totalTimeSpentSecs || 0));
    const entries = _.sum(roomDetails.invitations.map((i) => i.stats?.timesEntered || 0));
    const averageSession = entries ? TimeUtils.formatDuration(totalTime / entries) : '--';

    const closeRoom = (kickUsers: boolean) => {
        updateRoomActiveStatus(false, kickUsers);
        setShowConfirmCloseModal(false);
    };
    const cancelCloseRoom = () => {
        setShowConfirmCloseModal(false);
    };

    return (
        <>
            <Modal handleClose={() => setShowConfirmCloseModal(false)} isOpen={showConfirmCloseModal}>
                <Message
                    title={`LAST CALL! Are you sure you want to close ${
                        typeof roomName === 'string' ? `the world "${roomName}"` : 'this world'
                    }?`}
                    body="Any attendees currently in the world will be booted."
                    onCancel={() => cancelCloseRoom()}
                    onConfirm={() => closeRoom(true)}
                    onSecondConfirm={() => closeRoom(false)}
                    confirmButtonText="Close World and Kick Guests Out"
                    secondConfirmButtonText="Close World but Guests Can Stay"
                />
            </Modal>
            <Container>
                <TopContainer>
                    <TitleContainer>
                        <div>
                            <Title>{roomNameWithLineBreaks}</Title>
                            <h5>{description}</h5>
                        </div>
                        <ActionsContainer>
                            <SmallButton
                                onClick={() => history.push(`/org/${org.id}`)}
                                icon={<img src={eye} alt="view" />}
                            >
                                Preview
                            </SmallButton>
                            <SmallButton
                                onClick={() => history.push(`/room/${id}/edit`)}
                                icon={<img src={edit} alt="edit" />}
                            >
                                Edit
                            </SmallButton>
                        </ActionsContainer>
                    </TitleContainer>
                    <div className="d--f jc--fs ai-fs" style={{ width: '70%', marginTop: '16px' }}>
                        <RoomInfoItem>
                            <RoomInfoField>Environment:</RoomInfoField>
                            <SubTitle>{environmentName}</SubTitle>
                        </RoomInfoItem>
                        <RoomInfoItem>
                            <RoomInfoField>Capacity:</RoomInfoField>
                            <SubTitle>{capacity.toFixed(0)}</SubTitle>
                        </RoomInfoItem>
                    </div>
                </TopContainer>
                <StatsContainer>
                    <div className="d--f fd--c">
                        <div className="d--f jc--fs">
                            <div className="d--f ai--b" style={{ marginRight: '60px' }}>
                                <RoomInfoField>Status:</RoomInfoField>
                                <BodyText>{status}</BodyText>
                            </div>
                            {/*<div className="d--f ai--b" style={{ marginRight: '16px' }}>*/}
                            {/*    <RoomInfoField>Last Active:</RoomInfoField>*/}
                            {/*    <BodyText>{formatFirebaseDate({ seconds: new Date().getTime() / 1000 })}</BodyText>*/}
                            {/*</div>*/}
                            {/*<div className="d--f ai--b">*/}
                            {/*    <RoomInfoField>Creator:</RoomInfoField>*/}
                            {/*    <BodyText>{creator?.firstName}</BodyText>*/}
                            {/*</div>*/}
                        </div>
                        <div className="d--f jc--fs">
                            <div className="d--f ai--b" style={{ marginRight: '16px' }}>
                                <RoomInfoField>Active:</RoomInfoField>
                                <GreenBodyText>{attendance}</GreenBodyText>
                            </div>
                            <div className="d--f ai--b">
                                <RoomInfoField>Avg Session:</RoomInfoField>
                                <BodyText>{averageSession}</BodyText>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <div className="d--f jc--fe" style={{ marginBottom: '15px' }}>
                            <RoomInfoField style={{ paddingRight: '16px' }}>
                                {isActive ? 'World Active' : 'World Dormant'}
                            </RoomInfoField>
                            <Toggle onChange={toggleRoomActive} value={isActive || false} />
                        </div>
                        {/*<SmallButton onClick={setIsEditingSchedule}>Add Schedule</SmallButton>*/}
                    </div>
                </StatsContainer>
            </Container>
        </>
    );
};

const RoomInfoItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 30px;
`;

const RoomInfoField = styled.h4`
    font-size: 14px;
    font-weight: bold;
    line-height: 2.5;
    letter-spacing: 0.23px;
    margin: 0 15px 0 0;
    text-transform: uppercase;
`;

const SubTitle = styled.h3`
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    margin: 0;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: uppercase;
`;

const BodyText = styled.p`
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: #ffffff;
`;

const GreenBodyText = styled(BodyText)`
    font-weight: bold;
    color: var(--tennis-green);
`;

const Container = styled.section`
    width: 100%;
    background-color: var(--dark-indigo);
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 20px;
`;

const ActionsContainer = styled.section`
    width: 100%;
    min-width: 150px;
    display: flex;
    flex-direction: row;
    @media (min-width: 768px) {
        width: auto;
    }
    @media (min-width: 1024px) {
        flex-direction: column;
        align-items: flex-end;
        margin: 10px;
    }
    > button {
        margin-bottom: 15px;
    }
`;

const TopContainer = styled.div`
    padding: 60px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const StatsContainer = styled.div`
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--steel-blue);
    padding: 30px 60px;
    border-bottom-left-radius: 20px;
`;

export default RoomAdminHeader;
