import * as React from 'react';
import { GreyishBodyText } from './Typography';
import ValidatedFormInput, { TwoInputsOneLine } from './ValidatedFormInput';
import Divider from './Divider';
import { Link } from 'react-router-dom';
import ConnectionCard from './ConnectionCard';
import styled from 'styled-components';

interface SocialCardFormContentsProps {
    useForm: any;
    updateUserProfile: (userProfile: any) => void;
    userProfile: any;
}
const SocialCardFormContents: React.FC<SocialCardFormContentsProps> = ({ useForm, updateUserProfile, userProfile }) => {
    const { register, errors } = useForm;
    const now = new Date();

    return (
        <>
            <div style={{ marginBottom: '30px', width: '100%' }}>
                <h3>Name Badge</h3>
                <GreyishBodyText>Your public info. Visible to anyone in a world with you.</GreyishBodyText>
                <br />
                <ValidatedFormInput
                    labelText="Display Name*"
                    type="text"
                    errors={errors}
                    validation={register({
                        required: 'Display name is required',
                    })}
                    maxLength={45}
                    name="DisplayName"
                    value={userProfile.displayName || `${userProfile.firstName} ${userProfile.lastName}`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateUserProfile({
                            ...userProfile,
                            displayName: e.target.value,
                        })
                    }
                />

                <ValidatedFormInput
                    labelText="Display Subtitle"
                    subtitleText="Professional preview, 5 words to describe you, hometown… What do you want to share with the world?"
                    type="text"
                    errors={errors}
                    validation={register({})}
                    maxLength={35}
                    name="BadgeSubtitle"
                    value={userProfile.badgeSubtitle}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateUserProfile({
                            ...userProfile,
                            badgeSubtitle: e.target.value,
                        })
                    }
                />
            </div>
            <Divider />
            <CommunityContainer>
                <h3>Community Card</h3>
                <GreyishBodyText>
                    Fill out this card, then connect with others in a world to exchange information. You can find the
                    cards collected on your&nbsp;
                    <Link to="/community">
                        <GreyLink>Community Page</GreyLink>.
                    </Link>
                </GreyishBodyText>
                <br />

                <div style={{ marginBottom: '40px' }}>
                    <ConnectionCard
                        connection={{
                            toUser: userProfile,
                            ts: now.toISOString(),
                        }}
                    />
                </div>

                <TwoInputsOneLine>
                    <ValidatedFormInput
                        labelText="Title"
                        type="text"
                        errors={errors}
                        validation={register({})}
                        maxLength={200}
                        name="JobTitle"
                        value={userProfile.title}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateUserProfile({
                                ...userProfile,
                                title: e.target.value,
                            })
                        }
                    />

                    <ValidatedFormInput
                        labelText="Company"
                        type="text"
                        errors={errors}
                        validation={register({})}
                        maxLength={200}
                        name="company"
                        value={userProfile.company}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateUserProfile({
                                ...userProfile,
                                company: e.target.value,
                            })
                        }
                    />
                </TwoInputsOneLine>

                <TwoInputsOneLine>
                    <ValidatedFormInput
                        labelText="Display Email"
                        type="text"
                        errors={errors}
                        validation={register({
                            pattern: {
                                value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i,
                                message: 'Please import a valid email',
                            },
                        })}
                        maxLength={200}
                        name="displayEmail"
                        value={userProfile.displayEmail}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateUserProfile({
                                ...userProfile,
                                displayEmail: e.target.value,
                            })
                        }
                    />

                    <ValidatedFormInput
                        labelText="Phone"
                        type="text"
                        errors={errors}
                        validation={register({})}
                        maxLength={200}
                        name="phone"
                        value={userProfile.phone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateUserProfile({
                                ...userProfile,
                                phone: e.target.value,
                            })
                        }
                    />
                </TwoInputsOneLine>

                <TwoInputsOneLine>
                    <ValidatedFormInput
                        labelText="Website"
                        type="text"
                        errors={errors}
                        validation={register({
                            pattern: {
                                value: /^(?:https?:\/\/)?(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9./%&=?_:;-]+$/,
                                message: 'Please input a valid url',
                            },
                        })}
                        maxLength={200}
                        name="url"
                        value={userProfile.url}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateUserProfile({
                                ...userProfile,
                                url: e.target.value,
                            })
                        }
                    />

                    <ValidatedFormInput
                        labelText="Linked-in URL"
                        type="text"
                        errors={errors}
                        validation={register({
                            validate: (value: string) =>
                                !value || value.match(/^(?:https?:\/\/)?(www\.)?linkedin.com\/in\/.+/)
                                    ? true
                                    : 'Please enter a valid Linked-in URL',
                        })}
                        maxLength={200}
                        name="linkedin"
                        value={userProfile.social?.linkedIn}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateUserProfile({
                                ...userProfile,
                                social: {
                                    ...userProfile.social,
                                    linkedIn: e.target.value,
                                },
                            })
                        }
                    />
                </TwoInputsOneLine>

                <TwoInputsOneLine>
                    <ValidatedFormInput
                        labelText="Twitter Handle"
                        type="text"
                        errors={errors}
                        validation={register({
                            pattern: {
                                value: /^[a-zA-Z0-9_]{1,15}$/,
                                message: 'Please input just your twitter username (no @)',
                            },
                        })}
                        maxLength={200}
                        name="twitter"
                        value={userProfile.social?.twitter}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateUserProfile({
                                ...userProfile,
                                social: {
                                    ...userProfile.social,
                                    twitter: e.target.value,
                                },
                            })
                        }
                    />

                    <ValidatedFormInput
                        labelText="Instagram Handle"
                        type="text"
                        errors={errors}
                        validation={register({
                            pattern: {
                                value: /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/,
                                message: 'Please input just your instagram handle (no @)',
                            },
                        })}
                        maxLength={200}
                        name="instagram"
                        value={userProfile.social?.instagram}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            updateUserProfile({
                                ...userProfile,
                                social: {
                                    ...userProfile.social,
                                    instagram: e.target.value,
                                },
                            })
                        }
                    />
                </TwoInputsOneLine>
            </CommunityContainer>
        </>
    );
};

const CommunityContainer = styled.div`
    a {
        color: var(--battleship-grey);
        transition: all 0.5s cubic-bezier(0.673, 0.003, 0.509, 0.997);
        &:hover {
            color: var(--off-white);
            text-decoration: none;
        }
    }
`;

const GreyLink = styled.span`
    font-weight: bold;
    text-decoration: underline;
`;

export default SocialCardFormContents;
