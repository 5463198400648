import * as React from 'react';
import { ApiOrgInvitation, ApiUser } from '../../ApiTypes';
import Table from '../../components/Table';
import { ProfileNonagon } from '../../components/ProfileCircle';
import styled from 'styled-components';
import { SvgIconButton } from '../../components/Button';
import { HostIcon, BlockedIcon, CheckIcon, DeleteIcon } from '../../images/icons';
import moment from 'moment';
import { OrgDetailContext } from './orgContext';
import { RoomDetailContext } from '../room/roomContext';
import { postApi, deleteApi } from '../../utils/api';
import { LoadingImage } from '../../components/LoadingComponent';
import TimeUtils from '../../utils/TimeUtils';
import VerticalDotMenu from '../../components/VerticalDotMenu';
import { getCurrentUser } from '../../store/selectors';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../components/Modal';

const getNameFromUser = (user: ApiUser) => {
    return [user.firstName, user.lastName].join(' ').trim() || 'Anonymous';
};

const getStatusFromInvitation = (invitation: ApiOrgInvitation) => {
    // if (ownerEmails.includes(invitation.user.email)) {
    //     return (
    //         <span style={{ stroke: '#3e3adf' }}>
    //             <HostIcon /> Host
    //         </span>
    //     );

    switch (invitation.status) {
        case 'ACCEPTED':
            return 'Accepted';
        case 'ACCOUNT_PENDING':
            return 'Pending';
        case 'BLOCKED':
            return (
                <span style={{ fill: '#9a0a0a' }}>
                    <BlockedIcon />
                    <span style={{ marginLeft: '-10px' }}>Blocked</span>
                </span>
            );
        default:
            return 'Unknown';
    }
};

const PresenceColumn = {
    accessor: 'isOnline',
    Cell: function buildProfileImg({ value }: { value: boolean }) {
        return (
            <ProfileDisplay>
                {value && (
                    <DotContainer>
                        <Dot />
                    </DotContainer>
                )}
            </ProfileDisplay>
        );
    },
    width: 25,
    disableSortBy: true,
};

const AvatarColumn = {
    accessor: 'user.avatarUrl',
    Cell: function buildProfileImg({ value }: { value: string }) {
        return (
            <ProfileDisplay>
                <ProfileNonagon avatarUrl={value} size={50} />
            </ProfileDisplay>
        );
    },
    width: 80,
    disableSortBy: true,
};

const DotContainer = styled.div`
    line-height: 2px;
`;

const Dot = styled.div`
    height: 15px;
    width: 15px;
    background-color: limegreen;
    border-radius: 50%;
    display: inline-block;
`;

const NameColumn = {
    Header: 'NAME',
    accessor: 'user.firstName',
    Cell: function buildName({ row: { original: invitation } }: { row: { original: ApiOrgInvitation } }) {
        return <NameDisplay>{getNameFromUser(invitation.user)}</NameDisplay>;
    },
    width: 200,
};

const EmailColumn = {
    Header: 'EMAIL',
    accessor: 'user.email',
    width: 250,
};

const VisitsColumn = {
    Header: '# OF VISITS',
    accessor: 'stats.timesEntered',
    width: 100,
    Cell: ({ value }: { value?: number }) => value || 0,
};

const DurationColumn = {
    Header: 'TOTAL DURATION',
    accessor: 'stats.totalTimeSpentSecs',
    Cell: ({ value }: { value?: number }) => (value ? TimeUtils.formatDuration(value) : '0min'),
};

const LastActiveColumn = {
    Header: 'LAST ACTIVE',
    accessor: 'stats.lastTimeSeen',
    Cell: ({ value }: { value?: string }) => (value ? formatInvitationDate(value) : '-'),
};

const formatInvitationDate = (date: string) => moment(date).format('MMM D | h:mm A');

const FullInvitedUserList = ({
    invitations,
    title,
    subtitle,
    buttonComponent: ButtonComponent,
}: {
    invitations: ApiOrgInvitation[];
    title?: string;
    subtitle?: string;
    buttonComponent: React.ComponentType<InvitationButtonProps>;
}) => {
    const invitationData = React.useMemo(() => invitations, [invitations]);

    const columns = React.useMemo(
        () => [
            PresenceColumn,
            AvatarColumn,
            NameColumn,
            EmailColumn,
            VisitsColumn,
            DurationColumn,
            {
                Header: 'STATUS',
                accessor: 'status',
                width: 100,
                Cell: ({ row: { original: invitation } }: { row: { original: ApiOrgInvitation } }) =>
                    getStatusFromInvitation(invitation),
            },
            {
                Header: 'DATE ADDED',
                accessor: 'invitedOn',
                Cell: ({ value }: { value: string }) => formatInvitationDate(value),
            },
            LastActiveColumn,

            {
                id: 'dots',
                Cell: function buildDots({ row: { original: invitation } }: { row: { original: ApiOrgInvitation } }) {
                    return <ButtonComponent invitation={invitation} isOwner={false} />;
                },
                width: 30,
                disableSortBy: true,
            },
        ],
        [],
    );

    return (
        <Table
            columns={columns}
            data={invitationData}
            defaultSort={[{ id: 'user.firstName', desc: false }]}
            title={title}
            subtitle={subtitle}
        />
    );
};

export const SimpleInvitedUserList = ({
    invitations,
    isOwner,
    subtitle,
    title,
    buttonComponent: ButtonComponent,
}: {
    invitations?: ApiOrgInvitation[];
    isOwner?: boolean;
    subtitle: string;
    title: string;
    buttonComponent: React.ComponentType<InvitationButtonProps>;
}) => {
    const invitationData = React.useMemo(() => invitations, [invitations]);
    const columns = React.useMemo(
        () => [
            AvatarColumn,
            NameColumn,
            EmailColumn,
            VisitsColumn,
            DurationColumn,
            LastActiveColumn,

            {
                id: 'dots',
                Cell: function buildDots({ row: { original: invitation } }: { row: { original: ApiOrgInvitation } }) {
                    return <ButtonComponent invitation={invitation} isOwner={isOwner || false} />;
                },
                width: 30,
                disableSortBy: true,
            },
        ],
        [isOwner],
    );

    return (
        <Table
            columns={columns}
            data={invitationData || []}
            defaultSort={[{ id: 'user.firstName', desc: false }]}
            subtitle={subtitle}
            title={title}
        />
    );
};
export const OwnerUserList = ({
    invitations,
    owners,
}: {
    owners: ApiOrgInvitation[];
    invitations?: ApiOrgInvitation[];
}) => {
    const ownerEmails = owners.map((owner) => owner.user.email);
    const ownerInvitations = invitations
        ? invitations.filter(
              (invitation) => invitation.status !== 'ACCOUNT_PENDING' && ownerEmails.includes(invitation.user.email),
          )
        : [];

    return (
        <SimpleInvitedUserList
            invitations={ownerInvitations}
            subtitle="People with owner access to venue"
            title="Owner List"
            buttonComponent={VenueInvitationButtons}
            isOwner={true}
        />
    );
};

export const VenueInvitedUserList = ({
    invitations,
    owners,
}: {
    invitations?: ApiOrgInvitation[];
    owners?: ApiOrgInvitation[];
}) => {
    if (invitations === undefined || owners === undefined) {
        return <LoadingImage />;
    }

    const ownerIds = owners.map((o) => o.user.id);
    const filteredInvitations = invitations?.filter((i) => !ownerIds.includes(i.user.id) && i.status !== 'BLOCKED');

    return (
        <FullInvitedUserList
            invitations={filteredInvitations || []}
            title="Attendee List"
            subtitle="People with access to venue"
            buttonComponent={VenueInvitationButtons}
        />
    );
};

export const VenueBlockedUserList = ({
    invitations,
    owners,
}: {
    invitations?: ApiOrgInvitation[];
    owners?: ApiOrgInvitation[];
}) => {
    if (invitations === undefined || owners === undefined) {
        return <LoadingImage />;
    }

    const filteredInvitations = invitations?.filter((i) => i.status === 'BLOCKED');
    if (filteredInvitations.length === 0) {
        return <>No blocks</>;
    }

    return (
        <FullInvitedUserList
            invitations={filteredInvitations || []}
            title="Block List"
            subtitle="People forbidden from the world"
            buttonComponent={VenueInvitationButtons}
        />
    );
};

export const RoomHostUserList = ({ invitations }: { invitations: ApiOrgInvitation[] }) => {
    return (
        <SimpleInvitedUserList
            invitations={invitations}
            subtitle="People with host access to world"
            title="Host List"
            buttonComponent={RoomInvitationButtons}
            isOwner={true}
        />
    );
};

export const RoomBlockedUserList = ({ invitations }: { invitations: ApiOrgInvitation[] }) => {
    return (
        <FullInvitedUserList
            invitations={invitations}
            title="Block List"
            subtitle="People forbidden from the world"
            buttonComponent={RoomInvitationButtons}
        />
    );
};

export const RoomInvitedUserList = ({ invitations }: { invitations?: ApiOrgInvitation[] }) => {
    if (invitations === undefined) {
        return <LoadingImage />;
    }

    return (
        <FullInvitedUserList
            invitations={invitations || []}
            title="Attendee List"
            subtitle="People with access to world"
            buttonComponent={RoomInvitationButtons}
        />
    );
};

const NameDisplay = styled.span`
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: #030217;
    padding-right: 20px;
`;

const ProfileDisplay = styled.div`
    padding: 0 15px;
`;

const Divider = styled.div`
    height: 30px;
    width: 1px;
    opacity: 0.9;
    border: 1px solid #fff;
    margin: 0 5px;
`;

type InvitationButtonProps = {
    invitation: ApiOrgInvitation;
    isOwner: boolean;
};

const VenueInvitationButtons = ({ invitation, isOwner }: InvitationButtonProps) => {
    const [showUnownWarning, setShowUnownWarning] = React.useState(false);
    const { email } = useSelector(getCurrentUser);

    return (
        <>
            {isOwner && invitation.user.email === email && (
                <UnownSelfModal
                    invitation={invitation}
                    hideModal={() => setShowUnownWarning(false)}
                    isOpen={showUnownWarning}
                />
            )}
            <MenuWrapper>
                <VerticalDotMenu>
                    {isOwner ? (
                        invitation.user.email === email ? (
                            <UnownSelfButton openWarningModal={() => setShowUnownWarning(true)} />
                        ) : (
                            <UnownButton invitation={invitation} />
                        )
                    ) : (
                        <OwnButton invitation={invitation} />
                    )}
                    <Divider />
                    {invitation.status !== 'BLOCKED' && (
                        <RemoveButton invitation={invitation} disabled={invitation.user.email === email} />
                    )}
                    {invitation.status !== 'BLOCKED' && !isOwner && <BlockButton invitation={invitation} />}
                    {invitation.status === 'BLOCKED' && <UnblockButton invitation={invitation} />}
                </VerticalDotMenu>
            </MenuWrapper>
        </>
    );
};

const RoomInvitationButtons = ({ invitation, isOwner }: InvitationButtonProps) => {
    const { email } = useSelector(getCurrentUser);

    return (
        <MenuWrapper>
            <VerticalDotMenu>
                {invitation.status !== 'BLOCKED' && (
                    <RoomRemoveButton invitation={invitation} disabled={invitation.user.email === email} />
                )}
                {invitation.status !== 'BLOCKED' && !isOwner && <RoomBlockButton invitation={invitation} />}
                {invitation.status === 'BLOCKED' && <RoomUnblockButton invitation={invitation} />}
            </VerticalDotMenu>
        </MenuWrapper>
    );
};

const MenuWrapper = styled.div`
    position: relative;
    width: 30px;
    height: 60px;
`;

const RoomBlockButton = ({ invitation }: { invitation: ApiOrgInvitation }) => (
    <RoomDetailContext.Consumer>
        {({ roomDetails, setRoomDetails }) => {
            const handleBlockUser = () => {
                postApi(`/org/${roomDetails.room.orgId}/block`, { userEmail: invitation.user.email })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: block failed');
                        }
                        setRoomDetails({
                            ...roomDetails,
                            invitations: roomDetails.invitations
                                ? roomDetails.invitations.map((invite) =>
                                      invite.id === invitation.id ? { ...invitation, status: 'BLOCKED' } : invite,
                                  )
                                : [],
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleBlockUser()}
                    activeColor="var(--burnt-red)"
                    iconType="fill"
                    baseColor="var(--off-white)"
                >
                    <BlockedIcon /> Block
                </SvgIconButton>
            );
        }}
    </RoomDetailContext.Consumer>
);

const BlockButton = ({ invitation }: { invitation: ApiOrgInvitation }) => (
    <OrgDetailContext.Consumer>
        {({ venueDetails, setVenueDetails }) => {
            const handleBlockUser = () => {
                postApi(`/org/${venueDetails.org.id}/block`, { userEmail: invitation.user.email })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: block failed');
                        }
                        setVenueDetails({
                            ...venueDetails,
                            invitations: venueDetails.invitations
                                ? venueDetails.invitations.map((invite) =>
                                      invite.id === invitation.id ? { ...invitation, status: 'BLOCKED' } : invite,
                                  )
                                : [],
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleBlockUser()}
                    activeColor="var(--burnt-red)"
                    iconType="fill"
                    baseColor="var(--off-white)"
                >
                    <BlockedIcon /> Block
                </SvgIconButton>
            );
        }}
    </OrgDetailContext.Consumer>
);

const RoomUnblockButton = ({ invitation }: { invitation: ApiOrgInvitation }) => (
    <RoomDetailContext.Consumer>
        {({ roomDetails, setRoomDetails }) => {
            const handleUnblockUser = () => {
                postApi(`/org/${roomDetails.room.orgId}/unblock`, { userEmail: invitation.user.email })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: unblock failed');
                        }
                        setRoomDetails({
                            ...roomDetails,
                            invitations: roomDetails.invitations
                                ? roomDetails.invitations.map((invite) =>
                                      invite.id === invitation.id ? { ...invitation, status: 'ACCEPTED' } : invite,
                                  )
                                : [],
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleUnblockUser()}
                    activeColor="var(--soft-green)"
                    iconType="fill"
                    baseColor="var(--off-white)"
                >
                    <CheckIcon /> Unblock
                </SvgIconButton>
            );
        }}
    </RoomDetailContext.Consumer>
);

const UnblockButton = ({ invitation }: { invitation: ApiOrgInvitation }) => (
    <OrgDetailContext.Consumer>
        {({ venueDetails, setVenueDetails }) => {
            const handleUnblockUser = () => {
                postApi(`/org/${venueDetails.org.id}/unblock`, { userEmail: invitation.user.email })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: unblock failed');
                        }
                        setVenueDetails({
                            ...venueDetails,
                            invitations: venueDetails.invitations
                                ? venueDetails.invitations.map((invite) =>
                                      invite.id === invitation.id ? { ...invitation, status: 'ACCEPTED' } : invite,
                                  )
                                : [],
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleUnblockUser()}
                    activeColor="var(--soft-green)"
                    iconType="fill"
                    baseColor="var(--off-white)"
                >
                    <CheckIcon /> Unblock
                </SvgIconButton>
            );
        }}
    </OrgDetailContext.Consumer>
);

const RemoveButton = ({ invitation, disabled }: { invitation: ApiOrgInvitation; disabled: boolean }) => (
    <OrgDetailContext.Consumer>
        {({ venueDetails, setVenueDetails }) => {
            const handleRemoveUser = () => {
                deleteApi(`/org/${venueDetails.org.id}/invite/${invitation.id}`)
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: remove failed');
                        }
                        setVenueDetails({
                            ...venueDetails,
                            invitations: venueDetails.invitations
                                ? venueDetails.invitations.filter((invite) => invite.id !== invitation.id)
                                : [],
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleRemoveUser()}
                    activeColor="var(--burnt-red)"
                    baseColor="var(--off-white)"
                    iconType="stroke"
                    disabled={disabled}
                >
                    <DeleteIcon /> Remove
                </SvgIconButton>
            );
        }}
    </OrgDetailContext.Consumer>
);

const RoomRemoveButton = ({ invitation, disabled }: { invitation: ApiOrgInvitation; disabled: boolean }) => (
    <RoomDetailContext.Consumer>
        {({ roomDetails, setRoomDetails }) => {
            const handleRemoveUser = () => {
                deleteApi(`/org/${roomDetails.room.orgId}/invite/${invitation.id}`)
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: remove failed');
                        }
                        setRoomDetails({
                            ...roomDetails,
                            invitations: roomDetails.invitations
                                ? roomDetails.invitations.filter((invite) => invite.id !== invitation.id)
                                : [],
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleRemoveUser()}
                    activeColor="var(--burnt-red)"
                    baseColor="var(--off-white)"
                    iconType="stroke"
                    disabled={disabled}
                >
                    <DeleteIcon /> Remove
                </SvgIconButton>
            );
        }}
    </RoomDetailContext.Consumer>
);

const OwnButton = ({ invitation }: { invitation: ApiOrgInvitation }) => (
    <OrgDetailContext.Consumer>
        {({ venueDetails, setVenueDetails }) => {
            const handleOwnUser = () => {
                postApi(`/org/${venueDetails.org.id}/owners/add`, { userId: invitation.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: own venue failed');
                        }
                        setVenueDetails({
                            ...venueDetails,
                            owners: venueDetails.owners ? [...venueDetails.owners, invitation] : [],
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    disabled={['BLOCKED', 'ACCOUNT_PENDING'].includes(invitation.status)}
                    onClick={() => handleOwnUser()}
                    activeColor="var(--purple-blue)"
                    iconType="stroke"
                    baseColor="var(--off-white)"
                >
                    <HostIcon /> Make Owner
                </SvgIconButton>
            );
        }}
    </OrgDetailContext.Consumer>
);

const UnownButton = ({ invitation }: { invitation: ApiOrgInvitation }) => (
    <OrgDetailContext.Consumer>
        {({ venueDetails, setVenueDetails }) => {
            const handleUnownUser = () => {
                postApi(`/org/${venueDetails.org.id}/owners/remove`, { userId: invitation.user.id })
                    .then(async (r) => {
                        const json = await r.json();
                        if (!r.ok) {
                            console.log(json.message || 'Error: own venue failed');
                        }
                        setVenueDetails({
                            ...venueDetails,
                            owners: venueDetails.owners
                                ? venueDetails.owners.filter((owner) => owner.user.id !== invitation.user.id)
                                : [],
                        });
                    })
                    .catch((e) => console.log(e));
            };

            return (
                <SvgIconButton
                    onClick={() => handleUnownUser()}
                    activeColor="var(--burnt-red)"
                    iconType="stroke"
                    baseColor="var(--off-white)"
                >
                    <HostIcon /> Remove As Owner
                </SvgIconButton>
            );
        }}
    </OrgDetailContext.Consumer>
);

const UnownSelfModal = ({
    invitation,
    hideModal,
    isOpen,
}: {
    invitation: ApiOrgInvitation;
    hideModal: () => void;
    isOpen: boolean;
}) => {
    const history = useHistory();

    return (
        <OrgDetailContext.Consumer>
            {({ venueDetails }) => {
                const handleUnownUser = () => {
                    postApi(`/org/${venueDetails.org.id}/owners/remove`, { userId: invitation.user.id })
                        .then(async (r) => {
                            const json = await r.json();
                            if (!r.ok) {
                                console.log(json.message || 'Error: own venue failed');
                            }
                            history.push(`/org/${venueDetails.org.id}`);
                        })
                        .catch((e) => console.log(e));
                };

                return (
                    <Modal handleClose={hideModal} isOpen={isOpen}>
                        <ModalHeader>Are you sure you want to remove yourself as a venue owner?</ModalHeader>
                        <ModalText>You will lose all owner privileges at this venue.</ModalText>
                        <ModalButtons>
                            <CancelButton onClick={hideModal}>Cancel</CancelButton>
                            <SvgIconButton
                                onClick={() => handleUnownUser()}
                                activeColor="var(--burnt-red)"
                                iconType="stroke"
                                baseColor="var(--off-white)"
                            >
                                <HostIcon /> Yes, Remove Me As Owner
                            </SvgIconButton>
                        </ModalButtons>
                    </Modal>
                );
            }}
        </OrgDetailContext.Consumer>
    );
};

const ModalHeader = styled.h1`
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.5px;
    color: var(--white);
    margin-bottom: 30px;
`;

const ModalText = styled.div`
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
    color: var(--white);
    margin-bottom: 50px;
    width: 100%;
    text-align: left;
`;

const ModalButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-align: center;
    width: 100%;
`;

const CancelButton = styled.button`
    appearance: none;
    color: var(--burnt-red);
    font-size: 16px;
    font-weight: bold;
    background: none;
    border: none;
    margin-right: 20px;
`;

const UnownSelfButton = ({ openWarningModal }: { openWarningModal: () => void }) => (
    <SvgIconButton
        onClick={() => openWarningModal()}
        activeColor="var(--burnt-red)"
        iconType="stroke"
        baseColor="var(--off-white)"
    >
        <HostIcon /> Remove As Owner
    </SvgIconButton>
);
