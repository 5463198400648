import * as React from 'react';
import { useFirebase } from 'react-redux-firebase';
import GoogleIcon from '../images/Google__G__Logo.svg';
import styled from 'styled-components';
import { AuthButton } from './Button';
import { getApi } from '../utils/api';

interface Props {
    children: React.ReactElement<any, any> | string;
}

const GoogleAuthButton: React.FC<Props> = ({ children }) => {
    const firebase = useFirebase();

    const logInOrSignUpWithGoogle = async () => {
        firebase
            .login({ type: 'popup', provider: 'google' })
            .then(async ({ user }) => {
                const userProfile = await getApi('/user/me');
                return { googleUser: user, currentUser: await userProfile.json() };
            })
            .then(({ googleUser, currentUser }) => {
                if (!currentUser?.user) return;
                const { user } = currentUser;

                if (!user?.lastName) {
                    firebase.updateProfile({ firstName: googleUser?.displayName?.split(' ')[0] });
                }
                if (!user?.firstName) {
                    firebase.updateProfile({ lastName: googleUser?.displayName?.split(' ')[1] });
                }
                if (!user?.avatarUrl) {
                    firebase.updateProfile({ avatarUrl: googleUser?.photoURL });
                }
                if (!user?.email) {
                    firebase.updateProfile({ email: googleUser?.email });
                }
                if (!user?.access) {
                    firebase.updateProfile({ access: 'Free' });
                }
            })
            .catch(console.error);
    };

    return (
        <TwoElementButton onClick={logInOrSignUpWithGoogle}>
            <Icon src={GoogleIcon} />
            <Text>{children}</Text>
        </TwoElementButton>
    );
};

const TwoElementButton = styled(AuthButton)`
    display: flex;
    padding: 0 15px 0;
    font-size: 20px;
    align-items: center;
    &:hover {
        background: transparent;
        color: var(--off-white);
    }
`;

const Icon = styled.img`
    height: 100%;
    padding: 5px 5px 5px 2px;
    margin: 5px 0;
`;

const Text = styled.span`
    padding: 5px 6px 5px 4px;
    margin: 0;
`;
export default GoogleAuthButton;
