import styled from 'styled-components';

export const SelectStyle = `font-size: 12px;
padding: 2px 10px;
border-radius: 15px;
width: 300px;
height: 30px;
color: black;
appearance: menulist;
margin-bottom: 30px;`;

export const Select = styled.select`
    ${SelectStyle}
`;
