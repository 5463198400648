import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { EnvironmentTypes, Room } from '../../DbTypes';
import { ApiRoomEditableMetadata } from '../../ApiTypes';
import { AuthButton } from '../../components/Button';
import UploadImage from '../../components/UploadImage';
import ValidatedFormInput from '../../components/ValidatedFormInput';
import { WhiteLinkText } from '../../components/Typography';
import { useHistory } from 'react-router-dom';
import EnvironmentSelect from './EnvironmentSelect';

const RoomForm = ({
    onSubmit,
    room,
}: {
    onSubmit: (roomData: ApiRoomEditableMetadata) => void;
    room?: Room;
    title: string;
}) => {
    const [capacity, setCapacity] = useState(25);
    const [description, setDescription] = useState('');
    const [environmentName, setEnvironmentName] = useState<EnvironmentTypes>('citadel');
    const [longDescription, setLongDescription] = useState('');
    const [roomImageUrl, setRoomImageUrl] = useState('');
    const [roomName, setRoomName] = useState('');
    const [uploading, setUploading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const history = useHistory();
    const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit', shouldFocusError: true });

    const handleCapacityChange = (e: any) => {
        const val = e.target.value;
        if (val < 0 || val > 25) return;
        setCapacity(val);
    };

    useEffect(() => {
        if (room?.roomName) setRoomName(room.roomName);
        if (room?.environmentName) setEnvironmentName(room.environmentName);
        if (room?.capacity) setCapacity(room.capacity);
        if (room?.roomImageUrl) setRoomImageUrl(room.roomImageUrl);
        if (room?.description) setDescription(room.description);
        if (room?.longDescription) setLongDescription(room.longDescription);
    }, [room]);

    const submitWithRoomData = () => {
        setSubmitting(true);
        const roomData: ApiRoomEditableMetadata = {
            capacity,
            description,
            longDescription,
            environmentName,
            isPublic: false,
            roomName,
            roomImageUrl,
        };
        onSubmit(roomData);
    };

    return (
        <Form
            id="signup-Form"
            name="wf-form-signup-Form"
            data-name="signup Form"
            className="form-2"
            onSubmit={handleSubmit(submitWithRoomData)}
        >
            <FormColumns>
                <LeftColumn>
                    <UploadImage
                        onUpload={(u: string) => setRoomImageUrl(u)}
                        existingImage={room?.roomImageUrl}
                        uploading={uploading}
                        setUploading={setUploading}
                    />
                    <CapacityInputContainer>
                        <ValidatedFormInput
                            labelText="Set Capacity"
                            className="w-input"
                            errors={errors}
                            id="capacity"
                            name="roomCapacity"
                            onChange={handleCapacityChange}
                            type="number"
                            validation={register({
                                validate: {
                                    range: (num) => (num > 0 && num < 26) || 'Capacity must be between 1 and 25',
                                },
                            })}
                            value={capacity}
                            style={{ width: '78px' }}
                        />
                    </CapacityInputContainer>
                </LeftColumn>

                <RightColumn>
                    <ValidatedFormInput
                        labelText="World Name*"
                        errors={errors}
                        maxLength={35}
                        name="roomName"
                        onChange={(e: any) => setRoomName(e.target.value)}
                        type="text"
                        validation={register({ required: 'Room name is required' })}
                        value={roomName}
                    />

                    <label htmlFor="Environment">Choose Environment</label>
                    <EnvironmentSelect environmentName={environmentName} setEnvironmentName={setEnvironmentName} />

                    <ValidatedFormInput
                        labelText="Short Description* (35 characters)"
                        errors={errors}
                        maxLength={35}
                        name="description"
                        onChange={(e: any) => setDescription(e.target.value)}
                        type="text"
                        validation={register({ required: 'World description is required' })}
                        value={description}
                    />

                    <ValidatedFormInput
                        labelText="Full Description"
                        errors={errors}
                        maxLength={1250}
                        name="longDescription"
                        onChange={(e: any) => setLongDescription(e.target.value)}
                        type="textarea"
                        style={{ height: '200px' }}
                        validation={register({})}
                        value={longDescription}
                    />

                    <ButtonContainer>
                        <WhiteLinkText onClick={() => history.goBack()}>Cancel</WhiteLinkText>
                        <AuthButton id="signupButton" disabled={uploading || submitting}>
                            {uploading ? 'Uploading...' : room ? 'Update World' : 'Create World'}
                        </AuthButton>
                    </ButtonContainer>
                </RightColumn>
            </FormColumns>
        </Form>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-top: 30px;
    > * {
        margin-right: 60px;
    }
`;

const Form = styled.form`
    margin: 0 auto;
    display: flex;
    justify-content: center;
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const FormColumns = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 60px;
    > * {
        margin-bottom: 80px;
    }
    @media (min-width: 1024px) {
        flex-direction: row;
        > * {
            &:not(:last-child) {
                margin-right: 60px;
            }
        }
    }
`;

// const PublicRoomToggleContainer = styled.div`
//     display: flex;
//     align-items: center;
//     margin: 15px 0;
//     > * {
//         &:first-child {
//             margin-right: 33px;
//         }
//     }
// `;

const CapacityInputContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin: 75px 0 0;
    > * {
        &:first-child {
            margin-right: 15px;
        }
    }
`;
export default RoomForm;
