import * as React from 'react';
import RoomForm from './room/RoomForm';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Room } from '../DbTypes';
import { ApiRoomEditableMetadata } from '../ApiTypes';
import CenteredSingleColumnLayout from '../components/CenteredSingleColumnLayout';
import LoadingScreen from '../components/LoadingScreen';
import { getApi, postApi } from '../utils/api';

const EditRoom: React.FC = () => {
    const history = useHistory();
    const { roomId } = useParams() as any;
    // const currentUserCanCreate = useSelector(getCurrentUserCanCreateRooms);
    const [loading, setLoading] = useState(true);
    const [error, setError] = React.useState('');

    const [room, setRoom] = useState({} as Room);

    useEffect(() => {
        getApi(`/room/${roomId}`).then(async (r) => {
            const json = await r.json();
            if (!r.ok) {
                console.log(json.message || 'Could Not get room data');
            }

            if (json.room) {
                setRoom(json.room);
                setLoading(false);
            } else {
                console.log('Room data missing from response');
            }
        });
    }, [roomId]);

    const handleUpdateRoom = (roomData: ApiRoomEditableMetadata) => {
        postApi(`/room/${roomId}/update`, roomData)
            .then(async (r) => {
                const json = await r.json();
                const parsedErrors = json?.error?.details?.map((err: any) => err?.message).join(' ');
                if (!r.ok) {
                    setError(parsedErrors || 'Update failed');
                }
                if (json.id) {
                    history.push(`/room/${json.id}`);
                }
            })
            .catch((err) => console.log('error: ', err));
    };

    // if (!currentUserCanCreate) return <h2>Unauthorized :(</h2>;
    if (loading) return <LoadingScreen />;
    return (
        <CenteredSingleColumnLayout title="Update Your World" subtitle="">
            <RoomForm
                title="Edit your room"
                room={room}
                onSubmit={(roomData: ApiRoomEditableMetadata) => handleUpdateRoom(roomData)}
            />
            ;{error && <h2>{error}</h2>}
        </CenteredSingleColumnLayout>
    );
};

export default EditRoom;
