import fetchWithTimeout from './fetchWithTimeout';

export default async function isHostHealthy(hostname: string, maxTries: number, tryCb?: (triesSoFar: number) => void) {
    return new Promise((resolve, reject) => {
        const fetchHealthHelper = (triesLeft: number) => {
            if (triesLeft === 0) {
                reject();
            } else {
                fetchWithTimeout(`https://${hostname}/health`)
                    .then(() => {
                        resolve();
                    })
                    .catch((e: Error) => {
                        console.error(e);
                        if (e.message.includes('404')) {
                            // temporary hack until /health endpoint goes live
                            resolve();
                        } else {
                            if (tryCb) {
                                tryCb(maxTries - triesLeft + 1);
                            }
                            setTimeout(() => fetchHealthHelper(triesLeft - 1), 7500);
                        }
                    });
            }
        };

        fetchHealthHelper(maxTries);
    });
}
