import * as React from 'react';
import { Modal } from '../components/Modal';
import { useHistory } from 'react-router-dom';
import { ApiRoom } from '../ApiTypes';
import Message, { MessageButtonContainer } from '../components/Message';
import { SmallButton } from '../components/Button';
import { postApi } from '../utils/api';

export const ErrorModal = () => {
    const history = useHistory();
    const goToHome = () => history.push('/');

    return (
        <Modal isOpen={true} handleClose={goToHome}>
            <Message
                title="Sorry, something went wrong"
                body="I blame gremlins. Definitely gremlins. With their weird night time talk shows and their unpredictable behavior veering between campy horror and slapstick comedy. Gremlins!"
                onConfirm={goToHome}
                confirmButtonText="Go home"
            >
                <br />
                Something wrong? We are here to help.
                <a href="mailto:support@urnowhere.com">support@urnowhere.com</a>
            </Message>
        </Modal>
    );
};

export const NotAuthorizedModal = ({ notAuthorizedMessage }: { notAuthorizedMessage: string }) => {
    const history = useHistory();
    const goToVenues = () => history.push('/venues');

    return (
        <Modal isOpen={true} handleClose={goToVenues}>
            <Message
                title="Hmm, doesn’t look like you have access to this room."
                body={notAuthorizedMessage}
                onConfirm={() => history.push(`/venues`)}
                confirmButtonText="Back to Venues"
            />
        </Modal>
    );
};

export const RoomClosedModal = () => {
    const history = useHistory();
    const goToVenues = () => history.push('/venues');

    return (
        <Modal isOpen={true} handleClose={goToVenues}>
            <Message
                title="back it up now..."
                body="This world is closed right now"
                onConfirm={goToVenues}
                confirmButtonText="Back to Venues"
            />
        </Modal>
    );
};

export const AtCapacityModal = ({ room }: { room?: ApiRoom }) => {
    const history = useHistory();
    const goToVenues = () => history.push(`/org/${room?.orgId}`);
    console.log(room);

    return (
        <Modal isOpen={true} handleClose={goToVenues}>
            <Message title={`Sorry. ${room?.roomName} is at capacity`} body="Do you wanna...">
                <MessageButtonContainer>
                    <SmallButton onClick={() => window.location.reload()}>Try Again</SmallButton>
                    <SmallButton onClick={goToVenues}>Find Another World</SmallButton>
                </MessageButtonContainer>
            </Message>
        </Modal>
    );
};

export const ActiveConfirmModal: React.FC<{ roomId: string; enterRoom: () => void }> = ({ roomId, enterRoom }) => {
    const history = useHistory();
    function enterAndOpen() {
        postApi(`/room/${roomId}/update`, {
            isActive: true,
        });
        enterRoom();
    }

    const backToRoom = () => history.push(`/room/${roomId}`);

    return (
        <Modal isOpen={true} handleClose={backToRoom}>
            <Message
                title="This room is closed right now..."
                body="But you are an admin. Do you want to:"
                onConfirm={enterAndOpen}
                confirmButtonText="Enter and open the world to the public"
                onSecondConfirm={enterRoom}
                secondConfirmButtonText="Enter without opening"
                onCancel={backToRoom}
            />
        </Modal>
    );
};
