import * as React from 'react';
import styled from 'styled-components';
import VerticalDots from './VerticalDots';
import { verticalCentering } from '../mixins/positioning';

type VerticalDotMenuProps = {
    verticalButtons?: boolean;
    fullHeight?: boolean;
};

const VerticalDotMenu: React.FC<VerticalDotMenuProps> = ({ children, verticalButtons, fullHeight }) => {
    const [showButtons, setShowButtons] = React.useState(false);

    return (
        <ButtonWrapper className={showButtons ? 'open' : ''} verticalButtons={verticalButtons} fullHeight={fullHeight}>
            {showButtons && children}
            <MenuButton onClick={() => setShowButtons(!showButtons)} className={showButtons ? 'open' : ''}>
                <VerticalDots />
            </MenuButton>
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.div<VerticalDotMenuProps>`
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: ${({ fullHeight }) => (fullHeight ? '100%' : '60px')};
    z-index: 10;
    border-radius: 15px;
    background-color: transparent;
    padding: 15px 35px 15px 20px;
    ${verticalCentering}
    display: flex;
    flex-direction: ${({ verticalButtons }) => (verticalButtons ? 'column' : 'row')};
    align-items: ${({ verticalButtons }) => (verticalButtons ? 'flex-end' : 'center')};
    justify-content: space-between;

    &.open {
        width: auto;
        background-color: #6c6c89;
    }
`;

const MenuButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 30px;
    height: 60px;
    border-radius: 15px;
    cursor: pointer;
    color: #6c6c89;
    ${verticalCentering};
    background-color: var(--off-white);

    &:hover {
        background-color: #e3e3e9;
    }

    &.open {
        background-color: #6c6c89;
        color: #bee639;

        &:hover {
            background-color: #5e5e70;
        }
    }
`;

export default VerticalDotMenu;
