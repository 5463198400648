import '../react-rrule-generator/src/lib/styles/index.css';

import * as React from 'react';
import RRuleGenerator from '../react-rrule-generator/src/lib/index.js';
import TimePicker from 'react-time-picker';
import TimezonePicker from './TimezonePicker';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';
import { SelectStyle } from './Select';
import Checkbox from './Checkbox';
import { verticalCentering } from '../mixins/positioning';
import moment from 'moment';
import { StyledLargeButton } from './Button';

const Container = styled.section`
    display: flex;
    flex-direction: column;

    .px-0 {
        input {
            color: black !important;
        }

        select {
            color: black !important;
            ${SelectStyle}
        }
    }
`;

const convertToDateTime = (date: Date, time: string) => {
    const datetime = new Date(date);
    const [hour, minute] = time.split(':');
    datetime.setHours(+hour);
    datetime.setMinutes(+minute);
    datetime.setSeconds(0);

    return datetime;
};

const EditSchedule = ({ title }: { title: string }) => {
    const [isRecurring, setIsRecurring] = React.useState(false);
    const [startTime, setStartTime] = React.useState('12:00');
    const [startDate, setStartDate] = React.useState(new Date() as Date | Date[]);
    const [endTime, setEndTime] = React.useState('12:00');
    const [endDate, setEndDate] = React.useState(new Date() as Date | Date[]);
    const [rrule, setRrule] = React.useState('');

    const start = convertToDateTime(startDate as Date, startTime);
    const end = convertToDateTime(endDate as Date, endTime);

    const handleSubmit = () => {
        const scheduleData = {
            start,
            end,
            rrule: isRecurring ? rrule : null,
        };

        console.log(scheduleData);
    };

    return (
        <Container>
            <ScheduleHeader>{title}</ScheduleHeader>
            <DateTimePickerRow>
                <DateTimePicker
                    header="open"
                    selectedTime={startTime}
                    selectedDate={startDate}
                    onTimeChange={setStartTime}
                    onDateChange={setStartDate}
                />
                to
                <DateTimePicker
                    header="close"
                    selectedTime={endTime}
                    selectedDate={endDate}
                    onTimeChange={setEndTime}
                    onDateChange={setEndDate}
                />
            </DateTimePickerRow>
            <DateDifference start={start} end={end} />
            <TimezonePicker onChange={console.log} />
            <Checkbox
                checked={isRecurring}
                onChange={() => setIsRecurring(!isRecurring)}
                labelText="Recurring Event?"
            />
            {isRecurring && <RRuleGenerator onChange={setRrule} />}
            <SubmitWrapper>
                <StyledLargeButton onClick={handleSubmit}>CONFIRM</StyledLargeButton>
            </SubmitWrapper>
        </Container>
    );
};

const SubmitWrapper = styled.div`
    margin-tope: 15px;
    display: flex;
    justify-content: flex-end;
`;

const ScheduleHeader = styled.h1`
    font-size: 30px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.5px;
    color: var(--white);
    margin-bottom: 52px;
    text-align: center;
`;

const DateTimePickerRow = styled.div`
    ${verticalCentering}
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.27px;
    color: var(--white);
`;

const DateTimePicker = ({
    header,
    selectedDate,
    selectedTime,
    onDateChange,
    onTimeChange,
}: {
    header: string;
    selectedDate: Date | Date[];
    selectedTime: string;
    onDateChange: (value: Date | Date[]) => void;
    onTimeChange: (value: string) => void;
}) => {
    return (
        <DateTimeWrapper>
            <DateTimeHeader>{header}</DateTimeHeader>
            <Divider />
            <TimePicker disableClock={true} clearIcon={null} value={selectedTime} onChange={onTimeChange} />
            <DatePicker
                clearIcon={null}
                calendarClassName="scheduleCal"
                calendarIcon={null}
                value={selectedDate}
                onChange={onDateChange}
            />
        </DateTimeWrapper>
    );
};

const DateTimeWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.35), inset 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--midnight);
    color: var(--white);
    flex-direction: column;
    width: auto;
    padding: 10px 30px;
    margin: 36px 36px 0;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    .scheduleCal {
        border-radius: 10px;
        box-shadow: 1px 1px 15px 0 rgba(28, 2, 33, 0.75);
        background-color: var(--steel-blue);
        border: 0px var(--white) solid;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__tile:enabled:hover {
        background-color: var(--purple-blue);
    }

    .react-time-picker__wrapper {
        border: none;
        font-size: 24px;
        font-weight: normal;
        letter-spacing: 0.6px;
        color: var(--white);
        margin-bottom: 13px;

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            option {
                padding: 0;
            }
        }
    }

    .react-date-picker__wrapper {
        border: none;

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        font-size: 12px;
        font-weight: bold;
        color: #c5b9c5;
    }

    .react-date-picker__inputGroup {
        min-width: 0;
    }

    .react-calendar__tile--now {
        background-color: var(--light-navy);
    }
`;

const Divider = styled.hr`
    margin: 8px 0px;
    width: 140px;
    color: var(--darkest-purple);
`;

const DateTimeHeader = styled.h2`
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    color: #c5b9c5;
    text-transform: uppercase;
    margin: none;
`;

const DateDifference = ({ start, end }: { start: Date; end: Date }) => {
    if (start > end) {
        return <DateDifferenceText className="error">Open must be before Close</DateDifferenceText>;
    }

    const diff = moment.duration(moment(end).diff(moment(start)));
    const minutes = diff.minutes();
    const hours = diff.hours();
    const days = Math.floor(diff.asDays());

    return (
        <DateDifferenceText>
            Duration: {days ? `${days}D` : ''} {hours ? `${hours}H` : ''} {minutes}M
        </DateDifferenceText>
    );
};

const DateDifferenceText = styled.div`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.17px;
    color: var(--battleship-grey);
    text-align: right;
    text-transform: uppercase;
    margin-top: 5px;

    &.error {
        color: var(--burnt-red);
    }
`;

export default EditSchedule;
