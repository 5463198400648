import * as React from 'react';
import { ThinSubtitle } from './Typography';
import styled from 'styled-components';

type Props = {
    title?: string;
    subtitle?: string;
};

const OneColumnBackgroundPage: React.FC<Props> = ({ title, subtitle, children }) => {
    return (
        <>
            <ForegroundContainer>
                <Container>
                    <TitleContainer>
                        <h1 className="h1">{title}</h1>
                        <ThinSubtitle>{subtitle}</ThinSubtitle>
                    </TitleContainer>
                    <div>{children}</div>
                </Container>
            </ForegroundContainer>
        </>
    );
};

const ForegroundContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.35);
    position: absolute;
    top: 0;
    height: 100vh;
    border-radius: 0 0 0 60px;
    right: 0;
    z-index: 3;
    max-width: 768px;
    width: 50%;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const Container = styled.div`
    background-color: var(--navy-blue);
    padding: 75px 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 0 0 0 60px;
    height: 100%;
    min-height: 700px;
    //box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.15);
    z-index: 10;
`;

const TitleContainer = styled.div`
    margin: 0 30px 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > * {
        margin-bottom: 15px;
    }
`;

export default OneColumnBackgroundPage;
