import { ApiRoom } from '../ApiTypes';
import CitadelImage from '../images/citadel_default.jpg';
import CloudsImage from '../images/clouds_default.jpg';
import ForestImage from '../images/forest_default.jpg';
import CustomImage from '../images/6thboro_default.jpg';

export class RoomUtil {
    static getImage(room: ApiRoom) {
        if (room.roomImageUrl) return room.roomImageUrl;
        switch(room.environmentName) {
          case 'cloud':
            return CloudsImage;
          case 'forest':
            return ForestImage;
          case 'custom':
            return CustomImage;
          default:
            return CitadelImage;
        }
    }
}
