export interface SystemState {
    agreedToTerms: boolean;
}

export const AGREE_TO_TERMS = 'AGREE_TO_TERMS';

interface AgreeToTermsAction {
    type: typeof AGREE_TO_TERMS;
}

export type SystemActionTypes = AgreeToTermsAction;
