import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 60px;
    font-weight: bold;
    line-height: 65px;
    color: var(--off-white);
    text-transform: capitalize;
`;

export const ThinTitle = styled.h1`
    font-size: 60px;
    @media (min-width: 1024px) {
        font-size: 80px;
    }
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--off-white);
    text-transform: capitalize;
    @media (min-width: 1024px) {
        font-size: 74px;
    }
`;

export const ThinSubtitle = styled.h3`
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.5px;
    color: var(--white);
`;
export const BigGreenNumbers = styled.div`
    text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.35);
    font-family: all-round-gothic, sans-serif;
    font-size: 120px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--tennis-green);
`;

export const GreyishBodyText = styled.div`
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: var(--battleship-grey);
`;

export const RedLinkText = styled.p<{ onClick?: () => void; disabled?: boolean }>`
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.14px;
    color: var(--burnt-red);
    text-decoration: underline;
    cursor: pointer;
    transition: all 750ms cubic-bezier(0.673, 0.003, 0.509, 0.997);
    &:hover {
        color: var(--off-white);
    }
    &:active {
        text-decoration: none;
        color: var(--burnt-red);
    }
    ${({ disabled }) => disabled && 'color: var(--battleship-grey)'}
`;

export const WhiteLinkText = styled(RedLinkText)`
    color: var(--off-white);

    &:hover {
        color: var(--burnt-red);
    }
    &:active {
        text-decoration: none;
        color: var(--off-white);
    }
`;

export const SmallLabelText = styled.div`
    text-transform: uppercase;
    text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.35);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: var(--white);
`;
