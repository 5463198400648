import * as React from 'react';

interface modalInterface {
    setModalActive: (bool: boolean) => void;
}
const ConfidentialityModal = (props: modalInterface) => {
    return (
        <div style={{ display: 'flex', filter: 'blur(0px)', opacity: 1 }} className="accept-terms-section">
            <div>
                <div className="container leftt-align w-container">
                    <h2 className="h2">
                        <strong>WELCOME TO OUR CLOSED ALPHA</strong>
                        <br />
                    </h2>
                    <div className="para-space">
                        <p className="paragraph">
                            By clicking I&nbsp;ACCEPT below you agree to keep this experience and it’s contents
                            confidential and will not share this with your friends until a TBD date in which this
                            experience is made public.&nbsp;&nbsp;What happens in NWR stays in NWR. <br />
                            <br />I have read, understand, and agree to be bound by, the terms and conditions of this
                            Confidentiality Agreement. <br />
                        </p>
                    </div>
                    <button
                        data-w-id="8cf877ef-2f39-59d5-5b61-adcd883e888e"
                        className="button w-button"
                        onClick={() => props.setModalActive(false)}
                    >
                        I accept
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfidentialityModal;
