import styled from 'styled-components';
import { verticalCentering } from '../mixins/positioning';

const FullScreenContainer = styled.div`
    ${verticalCentering}
    width: 100vw;
    height: 100vh;
    flex-direction: column;
`;

export default FullScreenContainer;
