const firebaseConfig = {
    apiKey: 'AIzaSyAzhSAWYjZT1wt1BvezmR-2YL3pz-gsyQQ',
    authDomain: 'nowhere-bb566.firebaseapp.com',
    databaseURL: 'https://nowhere-bb566.firebaseio.com',
    projectId: 'nowhere-bb566',
    storageBucket: 'nowhere-bb566.appspot.com',
    messagingSenderId: '646715474026',
    appId: '1:646715474026:web:0964402902d67fffa58a13',
    measurementId: 'G-9JDG4E50ET',
};

export default firebaseConfig;
